import { Badge, BadgeVariant } from '@rea-group/construct-kit-core';

export interface StatusProps {
  status: string;
}

export enum BookingTrackerStatus {
  Cancelled = 'Cancelled',
  Ended = 'Ended',
  Paused = 'Paused',
}

const getVariant = (status: string): BadgeVariant => {
  switch (status) {
    case 'Done':
    case 'Sent':
    case 'Active':
      return 'positive';
    case 'Failed':
    case 'Error':
    case 'No prop details':
    case 'Missing data':
    case 'Missing':
    case 'Ended':
      return 'negative';
    case 'To be sent':
    case 'Processing':
    case 'Waiting':
    case 'Pending':
      return 'information';
    case 'Skipped':
    case 'Queued':
    case 'Cancelled':
      return 'warning';
    case 'Opt out':
    case 'Paused':
      return 'neutral';
    default:
      return status.startsWith('Queued') ? 'warning' : 'neutral';
  }
};

const Status = ({ status }: StatusProps): React.JSX.Element => {
  return <Badge variant={getVariant(status)}>{status}</Badge>;
};

export default Status;
