import { Link } from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import { formatDateTime } from '../../utils/formatDate';
import ErrorAlert from '../ErrorAlert';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { isPresent } from '../../utils/helpers';
import { PageLoadingSpinner } from '../LoadingSpinner';

interface OptimiseActionsResponse {
  results: NimbusOptimiseActionsFindManyByParamResponse[];
}

interface NimbusOptimiseActionsFindManyByParamResponse {
  id: number;
  bookingId: string;
  listingId: number | null;
  system: string;
  action: string;
  status: string;
  note: string | null;
  updatedAt: string | null;
}

const getOptimizationLog = async (
  action: string,
  system: string,
  status: string,
  date?: string,
): Promise<NimbusOptimiseActionsFindManyByParamResponse[]> => {
  const dateParam = isPresent(date) ? `&date=${date}` : '';
  const { results } = await fetchJson<OptimiseActionsResponse>(
    `/optimise/logs?action=${action}&system=${system}&status=${status}${dateParam}`,
  );
  return results;
};

const Container = styled.div`
  min-width: 50rem;
`;

export interface OptimizationLogModalProps {
  action: string;
  system: string;
  status: string;
  date?: string;
}

const OptimizationLogModal = ({
  action,
  system,
  status,
  date,
}: OptimizationLogModalProps): React.JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data: optimizationLogModalData,
  } = useQuery({
    queryKey: ['OptimizationLogList', action, system, status, date],
    queryFn: () => getOptimizationLog(action, system, status, date),
  });

  return (
    <Container>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {optimizationLogModalData && (
        <Table>
          <TableHeader>
            <TableHeaderColumn>Booking ID</TableHeaderColumn>
            <TableHeaderColumn>Listing ID</TableHeaderColumn>
            <TableHeaderColumn>System</TableHeaderColumn>
            <TableHeaderColumn>Action</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Updated</TableHeaderColumn>
            <TableHeaderColumn>Note</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {optimizationLogModalData.length === 0 ? (
              <EmptyTableBody />
            ) : (
              optimizationLogModalData.map(
                ({
                  id,
                  bookingId,
                  listingId,
                  system,
                  action,
                  status,
                  note,
                  updatedAt,
                }) => (
                  <TableRow key={id}>
                    <TableContentColumn>
                      <Link
                        href={`/booking-data?id=${bookingId}`}
                        variant="primary"
                        target="_blank"
                      >
                        {bookingId}
                      </Link>
                    </TableContentColumn>
                    <TableContentColumn>{listingId}</TableContentColumn>
                    <TableContentColumn>{system}</TableContentColumn>
                    <TableContentColumn>{action}</TableContentColumn>
                    <TableContentColumn>
                      <Status status={status} />
                    </TableContentColumn>
                    <TableContentColumn>
                      {formatDateTime(updatedAt)}
                    </TableContentColumn>
                    <TableContentColumn noWrap={false}>
                      {note}
                    </TableContentColumn>
                  </TableRow>
                ),
              )
            )}
          </TableBody>
        </Table>
      )}
    </Container>
  );
};
export default OptimizationLogModal;
