import { H1, Inline, Stack } from '@rea-group/construct-kit-core';
import EmailsPanel from '../components/HomePage/EmailsPanel';
import OptimizationPanel, {
  OptimizationData,
} from '../components/HomePage/OptimizationPanel';
import FbPageAdLimitsPanel from '../components/HomePage/FbPageAdLimitsPanel';
import ReportingTasksPanel from '../components/HomePage/ReportingPanel';
import DeletedTasksPanel, {
  DeletedTasksData,
} from '../components/HomePage/DeletedTasksPanel';
import LiveEmailsModal from '../components/HomePage/LiveEmailsModal';
import ChartPanel from '../components/HomePage/ChartPanel';
import BookingCompleteEmailsModal from '../components/HomePage/BookingCompleteEmailsModal';
import BoostBookingsModal from '../components/HomePage/BoostBookingsModal';
import PerformanceReportPanel from '../components/HomePage/PerformanceReportPanel';
import ErrorAlert from '../components/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import { fetchJson } from '../API/fetch';
import BookingCampaignPanel from '../components/HomePage/BookingCampaignPanel';
import CampaignUploadStatusAlert from '../components/HomePage/CampaignUploadStatusAlert';
import { PageLoadingSpinner } from '../components/LoadingSpinner';

export enum JobProgress {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Processed = 'Processed',
  Error = 'Error',
  Paused = 'Paused',
}

interface BookingCampaignUploadResponse {
  jobProgress: JobProgress;
}

type StringOrNumber = string | number;

interface ChartPlatformData {
  impressions: StringOrNumber[];
  clicks: StringOrNumber[];
  count: number[];
}

interface ChartResponse {
  lastUpdated?: string;
  labels: string[];
  facebook: ChartPlatformData;
  yahoo: ChartPlatformData;
  adform: ChartPlatformData;
  tradedesk: ChartPlatformData;
  yahooNative: ChartPlatformData;
}

interface BookingsEmailGroupByStatusByDateResponse {
  count: number;
  mostRecent: string | null;
  status: string;
}

interface BookingsCompleteEmailGroupByStatusByDateResponse {
  count: number;
  mostRecent: string | null;
  status: string;
}

interface GoLiveEmailsResponse {
  emails: BookingsEmailGroupByStatusByDateResponse[];
}

interface BookingCompleteEmailsResponse {
  completeEmails: BookingsCompleteEmailGroupByStatusByDateResponse[];
}

interface NimbusOptimiseActionsGroupByActionResponse {
  action: string;
  status: string;
  system: string;
  count: number;
  mostRecent: string | null;
}

interface OptimizationResponse {
  actions: NimbusOptimiseActionsGroupByActionResponse[];
}

interface BoostBookingsGroupByStatusByDateResponse {
  status: string;
  count: number;
  mostRecent: string | null;
}

export interface FacebookPageResponse {
  pageId: string;
  pageName: string;
  adCount: number;
  adLimit: number;
  remaining: number;
  isInUse: boolean;
}

interface ReportingTask {
  task:
    | 'fbReport'
    | 'yhReport'
    | 'afReport'
    | 'yhnReport'
    | 'ttdReport'
    | 'cleanData';
  status: string;
}

export interface ReportingTasksResponse {
  tasks: ReportingTask[];
  lastUpdated: string | null;
}

interface DeletedTask {
  status: string | null;
  lastUpdated: string | null;
}

interface DeletedTasksResponse {
  finishedTasks: DeletedTask[];
  invalidTasks: DeletedTask[];
}

export interface HomepageResponse {
  bookingCampaignUpload: BookingCampaignUploadResponse;
  chart: ChartResponse;
  goLiveEmails: GoLiveEmailsResponse;
  bookingCompleteEmails: BookingCompleteEmailsResponse;
  optimization: OptimizationResponse;
  inspectionBoostBookings: BoostBookingsGroupByStatusByDateResponse[];
  soldBoostBookings: BoostBookingsGroupByStatusByDateResponse[];
  facebookPagesAdLimits: FacebookPageResponse[];
  reportingTasks: ReportingTasksResponse;
  deletedTasks: DeletedTasksResponse;
}

export interface HomepageResponseData {
  bookingCampaignUpload: BookingCampaignUploadResponse;
  chartData: ChartResponse;
  liveEmailsData: BookingsEmailGroupByStatusByDateResponse[];
  bookingCompleteEmailsData: BookingsCompleteEmailGroupByStatusByDateResponse[];
  optimizationData: OptimizationData[];
  inspectionBoostBookings: BoostBookingsGroupByStatusByDateResponse[];
  soldBoostBookings: BoostBookingsGroupByStatusByDateResponse[];
  fbPageAdLimitsData: FacebookPageResponse[];
  reportingTasksData: ReportingTasksResponse;
  deletedFinishedTasksData: DeletedTasksData[];
  deletedInvalidTasksData: DeletedTasksData[];
}

const getHomepageData = async (): Promise<HomepageResponseData> => {
  const {
    bookingCampaignUpload,
    chart,
    goLiveEmails,
    bookingCompleteEmails,
    optimization,
    inspectionBoostBookings,
    soldBoostBookings,
    facebookPagesAdLimits,
    reportingTasks,
    deletedTasks,
  } = await fetchJson<HomepageResponse>('/homepage');

  const optimizationData = optimization.actions.map((optimizationAction) => {
    const { action, status, system } = optimizationAction;
    return { id: `${action}_${status}_${system}`, ...optimizationAction };
  });
  const deletedFinishedTasksData = deletedTasks.finishedTasks.map(
    (task, index) => ({ ...task, key: index }),
  );
  const deletedInvalidTasksData = deletedTasks.invalidTasks.map(
    (task, index) => ({ ...task, key: index }),
  );

  return {
    bookingCampaignUpload,
    chartData: chart,
    liveEmailsData: goLiveEmails.emails,
    bookingCompleteEmailsData: bookingCompleteEmails.completeEmails,
    optimizationData,
    inspectionBoostBookings,
    soldBoostBookings,
    fbPageAdLimitsData: facebookPagesAdLimits,
    reportingTasksData: reportingTasks,
    deletedFinishedTasksData,
    deletedInvalidTasksData,
  };
};

export const homepageDataQueryKey = ['homepageData'];

const Home = (): React.JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: homepageData,
  } = useQuery({ queryKey: homepageDataQueryKey, queryFn: getHomepageData });

  return (
    <Stack gap="extraLarge">
      <H1>Audience Maximiser Automation tool</H1>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {homepageData && (
        <Stack gap="large">
          {homepageData.bookingCampaignUpload.jobProgress !==
            JobProgress.NotStarted && (
            <CampaignUploadStatusAlert
              bookingUploadStatus={
                homepageData.bookingCampaignUpload.jobProgress
              }
            />
          )}
          <Inline gap="large" alignItems="stretch">
            <BookingCampaignPanel
              isUploadDisabled={
                homepageData.bookingCampaignUpload.jobProgress !==
                JobProgress.NotStarted
              }
            />
            <PerformanceReportPanel />
          </Inline>
          <ChartPanel chartData={homepageData.chartData} />
          <Inline gap="large" alignItems="flex-start">
            <EmailsPanel
              heading="Go live emails for today"
              buttonText="Open email list"
              emailsData={homepageData.liveEmailsData}
              modalContents={<LiveEmailsModal />}
            />
            <EmailsPanel
              heading="Booking complete emails for today"
              buttonText="Open complete email list"
              emailsData={homepageData.bookingCompleteEmailsData}
              modalContents={<BookingCompleteEmailsModal />}
            />
            <EmailsPanel
              heading="Inspection Boost bookings"
              buttonText="Open inspection boost booking list"
              emailsData={homepageData.inspectionBoostBookings}
              modalContents={<BoostBookingsModal type="inspection" />}
            />
            <EmailsPanel
              heading="Sold Boost bookings"
              buttonText="Open sold boost booking list"
              emailsData={homepageData.soldBoostBookings}
              modalContents={<BoostBookingsModal type="sold" />}
            />
          </Inline>
          <OptimizationPanel
            heading="Nimbus optimisations for today"
            optimizationData={homepageData.optimizationData}
          />
          <FbPageAdLimitsPanel
            fbPageAdLimitsData={homepageData.fbPageAdLimitsData}
          />
          <Inline gap="large" alignItems="flex-start">
            <ReportingTasksPanel
              reportingTasksData={homepageData.reportingTasksData}
            />
            <DeletedTasksPanel
              deletedFinishedTasksData={homepageData.deletedFinishedTasksData}
              deletedInvalidTasksData={homepageData.deletedInvalidTasksData}
            />
          </Inline>
        </Stack>
      )}
    </Stack>
  );
};

export default Home;
