import {
  ArrowDownTwoMd,
  ArrowUpTwoMd,
  Button,
  DeleteMd,
  EyeHideMd,
  EyeMd,
  H1,
  Inline,
  Link,
  MultiSelect,
  SelectOption,
  spacingHelper,
  Stack,
  TextInput,
} from '@rea-group/construct-kit-core';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import ExternalLink from '../components/ExternalLink';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import Pagination, { usePagination } from '../components/Pagination';
import Status from '../components/Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../components/Table/Table';
import debounce from '../utils/debounce';
import { formatAustralianDate } from '../utils/formatDate';
import formatLive from '../utils/formatLive';
import { isPresent } from '../utils/helpers';

interface BoostBookingTracker {
  id: number;
  impressions: number;
  listingId: number;
  fbCampaignName: string | null;
  fbCampaignId: string | null;
  listingPostcode: string;
  propertyType: string;
  listingPrice: string;
  listingState: string | null;
  bookingId: string;
  status: string;
  fbCpcLive: boolean;
  startDate: string | null;
  endDate: string | null;
  fbImps: number;
  fbImpsYdy: number;
  fbClicks: number;
  fbClicksYdy: number;
  fbSpend: string;
  fbCtr: string | null;
  fbCpc: string | null;
  boostType: 'inspection' | 'sold' | 'campaignBoost';
}

type BoostBookingTrackerColumnName = keyof Omit<BoostBookingTracker, 'id'>;

type ColumnNameWithoutFilters = 'fbCampaignId';

type ColumnNameWithFilters = Exclude<
  BoostBookingTrackerColumnName,
  ColumnNameWithoutFilters
>;

type BoostBookingTrackerFilters = Partial<
  Record<BoostBookingTrackerColumnName, string>
>;

export interface BoostBookingTrackersResponse {
  boostBookingTrackers: BoostBookingTracker[];
  totalCount: number;
}

const TableContainer = styled.div`
  overflow: scroll;
  height: 75vh;
`;

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

const StyledTextInput = styled(TextInput)`
  .customInputWrapper {
    padding: ${spacingHelper('twoExtraSmall extraSmall 0')};
  }
`;

const StyledHeaderButton = styled(Button)`
  padding: ${spacingHelper('0 extraSmall')};

  span:first-child {
    margin-right: ${spacingHelper('twoExtraSmall')};
  }
`;

const getBoostBookingTrackersData = (
  pageSize: number,
  pageNumber: number,
  filters: BoostBookingTrackerFilters,
  sortBy: BoostBookingTrackerColumnName | undefined,
  sortDirection: SortDirection | undefined,
): Promise<BoostBookingTrackersResponse> => {
  const filterQueryParams = Object.entries(filters)
    .filter(([, filterValue]) => isPresent(filterValue))
    .map(([columnName, filterValue]) => `&${columnName}=${filterValue}`)
    .join('');
  const sortingQueryParams =
    isPresent(sortBy) && isPresent(sortDirection)
      ? `&sortBy=${sortBy}&sortDirection=${sortDirection}`
      : '';
  return fetchJson<BoostBookingTrackersResponse>(
    `/boost-booking-trackers?pageSize=${pageSize}&pageNumber=${pageNumber}${filterQueryParams}${sortingQueryParams}`,
  );
};

const formatNumber = (value: number): string => value.toLocaleString('en');

interface BoostTrackerProps {
  pageSize?: number;
}

const columnNamesToLabelsWithFilters: Record<ColumnNameWithFilters, string> = {
  impressions: 'Imps',
  listingId: 'Listing ID',
  boostType: 'Boost Type',
  fbCampaignName: 'Campaign Name',
  listingPostcode: 'Postcode',
  propertyType: 'PropType',
  listingPrice: 'Price',
  listingState: 'State',
  bookingId: 'Booking ID',
  status: 'Status',
  fbCpcLive: 'FB CPC Live',
  startDate: 'Start Date',
  endDate: 'End Date',
  fbImps: 'Boost Imps',
  fbImpsYdy: 'Boost Imps YDY',
  fbClicks: 'Boost Clicks',
  fbClicksYdy: 'Boost Clicks YDY',
  fbSpend: 'Boost Spend',
  fbCtr: 'Boost CTR',
  fbCpc: 'Boost CPC',
};

const columnNamesToLabelsWithoutFilters: Record<
  ColumnNameWithoutFilters,
  string
> = { fbCampaignId: 'FB' };

export const columnNamesToLabels: Record<
  BoostBookingTrackerColumnName,
  string
> = { ...columnNamesToLabelsWithFilters, ...columnNamesToLabelsWithoutFilters };

const columnNamesWithoutFilters = Object.keys(
  columnNamesToLabelsWithoutFilters,
);

const orderedColumnNames: BoostBookingTrackerColumnName[] = [
  'impressions',
  'listingId',
  'bookingId',
  'boostType',
  'fbCampaignName',
  'listingPostcode',
  'propertyType',
  'listingPrice',
  'listingState',
  'status',
  'fbCpcLive',
  'startDate',
  'endDate',
  'fbImps',
  'fbImpsYdy',
  'fbClicks',
  'fbClicksYdy',
  'fbSpend',
  'fbCtr',
  'fbCpc',
  'fbCampaignId',
];

const multiSelectOptions: SelectOption[] = orderedColumnNames.map((name) => ({
  value: name,
  label: columnNamesToLabels[name],
}));

const createCellRenderers = ({
  impressions,
  listingId,
  fbCampaignName,
  fbCampaignId,
  listingPostcode,
  propertyType,
  listingPrice,
  listingState,
  bookingId,
  status,
  fbCpcLive,
  startDate,
  endDate,
  fbImps,
  fbImpsYdy,
  fbClicks,
  fbClicksYdy,
  fbSpend,
  fbCtr,
  fbCpc,
  boostType,
}: BoostBookingTracker): Record<
  BoostBookingTrackerColumnName,
  () => React.JSX.Element | string | number | boolean | null
> => ({
  impressions: () => formatNumber(impressions),
  listingId: () => (
    <Link href={`https://www.realestate.com.au/${listingId}`} target="_blank">
      {listingId}
    </Link>
  ),
  listingPostcode: () => listingPostcode,
  propertyType: () => propertyType,
  listingPrice: () => listingPrice,
  listingState: () => listingState,
  bookingId: () => (
    <Link
      href={`/booking-data?id=${bookingId}`}
      variant="primary"
      target="_blank"
    >
      {bookingId}
    </Link>
  ),
  status: () => <Status status={status} />,
  startDate: () => formatAustralianDate(startDate),
  endDate: () => formatAustralianDate(endDate),
  fbImps: () => formatNumber(fbImps),
  fbImpsYdy: () => formatNumber(fbImpsYdy),
  fbClicks: () => formatNumber(fbClicks),
  fbClicksYdy: () => formatNumber(fbClicksYdy),
  fbSpend: () => fbSpend,
  fbCtr: () => isPresent(fbCtr) && `${fbCtr}%`,
  fbCpc: () => fbCpc,
  fbCpcLive: () => formatLive(fbCpcLive),
  fbCampaignId: () =>
    fbCampaignId && (
      <ExternalLink
        href={`https://business.facebook.com/ads/manager/campaign/adsets/?ids=${fbCampaignId}`}
      >
        FB
      </ExternalLink>
    ),
  fbCampaignName: () => fbCampaignName,
  boostType: () => boostType,
});

const freezeSettingHeader: Partial<
  Record<BoostBookingTrackerColumnName, string>
> = {
  impressions: `
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  `,
  listingId: `
  position: sticky;
  top: 0;
  left: 6.594rem;
  z-index: 1;
  `,
};

const freezeSettingBody: Partial<
  Record<BoostBookingTrackerColumnName, string>
> = {
  impressions: `
  position: sticky;
  left: 0;
  `,
  listingId: `
  position: sticky;
  left: 6.594rem;
  `,
};

const BoostTracker = ({
  pageSize = 50,
}: BoostTrackerProps): React.JSX.Element => {
  const {
    pageNumber,
    onPreviousPageButtonClick,
    onNextPageButtonClick,
    resetPagination,
  } = usePagination();

  const scrollToTop = (): void => {
    window.scrollTo(0, 0);
  };

  const displayedColumnsLS = useMemo(() => {
    const value = window.localStorage.getItem('boostTrackerDisplayedColumns');

    return isPresent(value)
      ? (value
          .split(',')
          .filter((name) => name !== '') as BoostBookingTrackerColumnName[])
      : undefined;
  }, []);

  useEffect(() => {
    if (
      isPresent(displayedColumnsLS) &&
      displayedColumnsLS.toString() !== orderedColumnNames.toString()
    ) {
      window.localStorage.setItem(
        'boostTrackerDisplayedColumns',
        orderedColumnNames.join(),
      );
    }
  }, [displayedColumnsLS]);

  const [uiFilters, setUiFilters] = useState<BoostBookingTrackerFilters>({});
  const [filters, setFilters] = useState<BoostBookingTrackerFilters>({});
  const [sortBy, setSortBy] = useState<BoostBookingTrackerColumnName>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const [displayedColumns, setDisplayedColumns] = useState<
    BoostBookingTrackerColumnName[]
  >(isPresent(displayedColumnsLS) ? displayedColumnsLS : orderedColumnNames);
  const [selectedOptions, setSelectedOptions] = useState(
    displayedColumns.map((name) => ({
      value: name,
      label: columnNamesToLabels[name],
    })) as SelectOption[],
  );
  const {
    isFetching,
    isError,
    error,
    data: boostBookingTrackersData,
  } = useQuery({
    queryKey: [
      'boostBookingTrackersData',
      filters,
      sortBy,
      sortDirection,
      pageNumber,
    ],
    queryFn: () =>
      getBoostBookingTrackersData(
        pageSize,
        pageNumber,
        filters,
        sortBy,
        sortDirection,
      ),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  const setFiltersDebounced = useMemo(
    () =>
      debounce((updatedFilters: BoostBookingTrackerFilters): void => {
        setFilters(updatedFilters);
        resetPagination();
      }, 500),
    [resetPagination],
  );

  const handleFilterChange = (
    name: BoostBookingTrackerColumnName,
    value: string,
  ): void => {
    const updatedFilters = {
      ...uiFilters,
      [name]: isPresent(value) && value !== '' ? value : undefined,
    };

    setUiFilters(updatedFilters);
    setFiltersDebounced(updatedFilters);
  };

  const handleSort = (name: BoostBookingTrackerColumnName): void => {
    setSortBy(name);

    if (
      sortBy !== name ||
      (sortBy === name && sortDirection === SortDirection.Desc)
    ) {
      setSortDirection(SortDirection.Asc);
    } else {
      setSortDirection(SortDirection.Desc);
    }

    resetPagination();
  };

  const isCompact = true;
  const isStickyHeader = true;
  const useMinWidthColumns = ['impressions'];

  return (
    <Stack gap="extraLarge">
      <Inline justifyContent="space-between" alignItems="flex-end" grow={false}>
        <H1>Boost Tracker</H1>
        <Inline grow={false}>
          <Inline grow={false} gap="extraSmall">
            <MultiSelect
              icon={<EyeMd />}
              sizeVariant="medium"
              options={multiSelectOptions}
              selectedOptions={selectedOptions}
              placeholder="Displayed columns"
              label="Displayed columns"
              hideLabel
              onSelectedOptionsChange={(selectedOptions) => {
                setSelectedOptions(selectedOptions);

                const selectedOptionsKeys = selectedOptions.map(
                  ({ value }) => value,
                ) as BoostBookingTrackerColumnName[];

                const orderedDisplayedColumns = orderedColumnNames.filter(
                  (name) => selectedOptionsKeys.includes(name),
                );

                setDisplayedColumns(orderedDisplayedColumns);

                window.localStorage.setItem(
                  'boostTrackerDisplayedColumns',
                  orderedDisplayedColumns.join(),
                );
              }}
              renderDisplayText={() => 'Displayed columns'}
            />
            <Button
              variant="outline"
              icon={<EyeMd />}
              sizeVariant="medium"
              disabled={selectedOptions.length === multiSelectOptions.length}
              onClick={() => {
                setSelectedOptions(multiSelectOptions);

                setDisplayedColumns(orderedColumnNames);

                window.localStorage.setItem(
                  'boostTrackerDisplayedColumns',
                  orderedColumnNames.join(),
                );
              }}
            >
              Display all
            </Button>
            <Button
              variant="outline"
              icon={<EyeHideMd />}
              sizeVariant="medium"
              disabled={selectedOptions.length === 0}
              onClick={() => {
                setSelectedOptions([]);

                setDisplayedColumns([]);

                window.localStorage.setItem('boostTrackerDisplayedColumns', '');
              }}
            >
              Hide all
            </Button>
          </Inline>
          <Button
            variant="outline"
            icon={<DeleteMd />}
            sizeVariant="medium"
            onClick={() => {
              setUiFilters({});
              setFilters({});
            }}
          >
            Clear filters
          </Button>
        </Inline>
      </Inline>
      {isFetching && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      <Stack gap="small">
        <TableContainer>
          <Table isStickyHeader={isStickyHeader}>
            <TableHeader isStickyHeader={isStickyHeader}>
              {displayedColumns
                .map((name) => ({ name, label: columnNamesToLabels[name] }))
                .map(({ name, label }) => (
                  <TableHeaderColumn
                    key={name}
                    freeze={freezeSettingHeader[name]}
                    isCompact={isCompact}
                    hasMinWidth={useMinWidthColumns.includes(name)}
                  >
                    <Stack gap="extraSmall">
                      <StyledHeaderButton
                        data-testid={name}
                        variant="link-primary"
                        onClick={() => handleSort(name)}
                        icon={
                          sortBy === name ? (
                            sortDirection === SortDirection.Asc ? (
                              <ArrowUpTwoMd />
                            ) : (
                              <ArrowDownTwoMd />
                            )
                          ) : null
                        }
                        iconPlacement="right"
                      >
                        {label}
                      </StyledHeaderButton>
                      {!columnNamesWithoutFilters.includes(name) && (
                        <StyledTextInput
                          label={label}
                          value={
                            isPresent(uiFilters[name]) ? uiFilters[name] : ''
                          }
                          hideLabel
                          sizeVariant="small"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleFilterChange(name, e.target.value)
                          }
                          dangerouslySetClassNames={{
                            scrollableArea: 'customInputWrapper',
                          }}
                        />
                      )}
                    </Stack>
                  </TableHeaderColumn>
                ))}
              <TableHeaderColumn isCompact={isCompact} />
            </TableHeader>
            {boostBookingTrackersData && (
              <TableBody>
                {boostBookingTrackersData.boostBookingTrackers.length === 0 ? (
                  <EmptyTableBody />
                ) : (
                  boostBookingTrackersData.boostBookingTrackers.map(
                    (boostBookingTracker) => {
                      // eslint-disable-next-line testing-library/render-result-naming-convention
                      const cellRenderers =
                        createCellRenderers(boostBookingTracker);

                      return (
                        <TableRow key={boostBookingTracker.id}>
                          {displayedColumns.map((name) => (
                            <TableContentColumn
                              key={`${boostBookingTracker.id}-${name}`}
                              isCompact={isCompact}
                              freeze={freezeSettingBody[name]}
                            >
                              {cellRenderers[name]()}
                            </TableContentColumn>
                          ))}
                        </TableRow>
                      );
                    },
                  )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {boostBookingTrackersData &&
          boostBookingTrackersData.totalCount > 0 && (
            <Pagination
              pageSize={pageSize}
              pageNumber={pageNumber}
              totalCount={boostBookingTrackersData.totalCount}
              onPreviousPageButtonClick={() => {
                onPreviousPageButtonClick();
                scrollToTop();
              }}
              onNextPageButtonClick={() => {
                onNextPageButtonClick();
                scrollToTop();
              }}
            />
          )}
      </Stack>
    </Stack>
  );
};

export default BoostTracker;
