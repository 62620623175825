import {
  H1,
  PillTab,
  PillTabList,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
} from '@rea-group/construct-kit-core';
import FBAudienceCountApi from '../components/fbAudienceCount/FBAudienceCountApi';
import FBAudienceCountNimbus from '../components/fbAudienceCount/FBAudienceCountNimbus';

const FBAudienceCount = (): React.JSX.Element => {
  return (
    <Stack gap="medium">
      <H1>FB Audience Count</H1>
      <Tabs defaultSelectedTab={0}>
        <PillTabList>
          <PillTab>Nimbus Version</PillTab>
          <PillTab>API Version</PillTab>
        </PillTabList>
        <TabPanels>
          <TabPanel>
            <FBAudienceCountNimbus />
          </TabPanel>
          <TabPanel>
            <FBAudienceCountApi />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default FBAudienceCount;
