import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface FacebookReportingCampaignData {
  id: number;
  campId: number;
  campName: string;
  adsetId: number;
  adsetName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number | null;
  clicks: number | null;
  reach: number | null;
  spend: number | null;
  budget: number | null;
}

export interface BookingDataFbDailyCampaignReportingPanelProps {
  data: FacebookReportingCampaignData[];
}

const BookingDataFbDailyCampaignReportingPanel = ({
  data,
}: BookingDataFbDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="FB Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Adset ID</TableHeaderColumn>
        <TableHeaderColumn>Adset name</TableHeaderColumn>
        <TableHeaderColumn>Report date</TableHeaderColumn>
        <TableHeaderColumn>Start date</TableHeaderColumn>
        <TableHeaderColumn>End date</TableHeaderColumn>
        <TableHeaderColumn>Impressions</TableHeaderColumn>
        <TableHeaderColumn>Clicks</TableHeaderColumn>
        <TableHeaderColumn>Reach</TableHeaderColumn>
        <TableHeaderColumn>Spend</TableHeaderColumn>
        <TableHeaderColumn>Budget</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({
              id,
              campId,
              campName,
              adsetId,
              adsetName,
              reportDate,
              startDate,
              endDate,
              impressions,
              clicks,
              reach,
              spend,
              budget,
            }) => (
              <TableRow key={id}>
                <TableContentColumn>{campId}</TableContentColumn>
                <TableContentColumn>{campName}</TableContentColumn>
                <TableContentColumn>{adsetId}</TableContentColumn>
                <TableContentColumn>{adsetName}</TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(reportDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(startDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(endDate)}
                </TableContentColumn>
                <TableContentColumn>{impressions}</TableContentColumn>
                <TableContentColumn>{clicks}</TableContentColumn>
                <TableContentColumn>{reach}</TableContentColumn>
                <TableContentColumn>{spend}</TableContentColumn>
                <TableContentColumn>{budget}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataFbDailyCampaignReportingPanel;
