import { number, object, string } from 'yup';

export const editBookingFormSchema = object().shape({
  startDate: string().required(),
  bookingPeriod: string().required(),
  impressions: string().required(),
  listingId: number().required(),
  listingPostcode: string().required(),
  propertyType: string().required(),
  listingPrice: string().required(),
  agencyId: string().required(),
  listingStreetAddress: string().required(),
  listingSuburb: string().required(),
  listingState: string().required(),
  listingRegion: string().optional(),
  listingRegionState: string().optional(),
  packageId: number().optional(),
  customTargeting: string().optional(),
});
