import { Link } from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import { formatDateTime } from '../../utils/formatDate';
import ErrorAlert from '../ErrorAlert';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { isPresent } from '../../utils/helpers';
import { PageLoadingSpinner } from '../LoadingSpinner';

interface GetBoostBookingsResponse {
  results: BoostBookingsResponse[];
}

interface BoostBookingsResponse {
  id: number;
  bookingId: string;
  status: string;
  fbCampaignId: string;
  fbAccountId: string;
  lastProcessed: string;
  note: string | null;
  boostType: string;
}

const getBoostBookings = async (
  type?: string,
  date?: string,
): Promise<BoostBookingsResponse[]> => {
  const urlParams = new URLSearchParams();
  if (isPresent(date)) {
    urlParams.append('date', date);
  }

  if (isPresent(type)) {
    urlParams.append('type', type);
  }

  const queryStr =
    [...new Set(urlParams.keys())].length > 0 ? `?${urlParams.toString()}` : '';

  const { results } = await fetchJson<GetBoostBookingsResponse>(
    `/bookings/boost${queryStr}`,
  );

  return results;
};

const Container = styled.div`
  min-width: 50rem;
`;

interface BoostBookingsModalProps {
  date?: string;
  type?: string;
}

const BoostBookingsModal = ({
  date,
  type,
}: BoostBookingsModalProps): React.JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: boostBookingsModalData,
  } = useQuery({
    queryKey: ['boostBookingsList', type, date],
    queryFn: () => getBoostBookings(type, date),
  });

  return (
    <Container>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {boostBookingsModalData && (
        <Table>
          <TableHeader>
            <TableHeaderColumn>Booking ID</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Boost Type</TableHeaderColumn>
            <TableHeaderColumn>FB Campaign ID</TableHeaderColumn>
            <TableHeaderColumn>FB Account ID</TableHeaderColumn>
            <TableHeaderColumn>Last Processed</TableHeaderColumn>
            <TableHeaderColumn>Note</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {boostBookingsModalData.length === 0 ? (
              <EmptyTableBody />
            ) : (
              boostBookingsModalData.map(
                ({
                  id,
                  bookingId,
                  status,
                  boostType,
                  fbCampaignId,
                  fbAccountId,
                  lastProcessed,
                  note,
                }) => (
                  <TableRow key={id}>
                    <TableContentColumn>
                      <Link
                        href={`/booking-data?id=${bookingId}`}
                        variant="primary"
                        target="_blank"
                      >
                        {bookingId}
                      </Link>
                    </TableContentColumn>
                    <TableContentColumn>
                      <Status status={status} />
                    </TableContentColumn>
                    <TableContentColumn>{boostType}</TableContentColumn>
                    <TableContentColumn>{fbCampaignId}</TableContentColumn>
                    <TableContentColumn>{fbAccountId}</TableContentColumn>
                    <TableContentColumn>
                      {formatDateTime(lastProcessed)}
                    </TableContentColumn>
                    <TableContentColumn noWrap={false}>
                      {note}
                    </TableContentColumn>
                  </TableRow>
                ),
              )
            )}
          </TableBody>
        </Table>
      )}
    </Container>
  );
};

export default BoostBookingsModal;
