import styled from 'styled-components';
import { Button, ButtonVariant, Spinner } from '@rea-group/construct-kit-core';

const StyledSpinner = styled(Spinner)`
  width: 1rem;
  height: 1rem;
`;

interface ResumeButtonProps {
  isSpinning: boolean;
  onClick: () => void;
  children: React.ReactNode;
  variant?: ButtonVariant;
}

const ResumeButton = ({
  isSpinning,
  onClick,
  children,
  variant,
}: ResumeButtonProps): React.JSX.Element => {
  return (
    <Button
      sizeVariant="small"
      variant={variant}
      disabled={isSpinning}
      iconPlacement="right"
      icon={isSpinning ? <StyledSpinner /> : undefined}
      onClick={onClick}
    >
      {isSpinning ? 'Loading' : children}
    </Button>
  );
};

export { ResumeButton };
