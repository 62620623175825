import {
  H1,
  Stack,
  Inline,
  Label,
  Text,
  Link as TextLink,
  Alert,
  Accordion,
  AccordionItem,
  Button,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { PropertyDetailWithCreatives, PropertyLexaError } from './types';
import { isPresent } from '../../utils/helpers';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

const StyledStack = styled(Stack)`
  > * {
    width: fit-content;
  }
`;

const PropertyField = ({
  label,
  value,
  isLink = false,
}: {
  label: string;
  value?: string;
  isLink?: boolean;
}): React.JSX.Element | null =>
  isPresent(value) ? (
    <Inline>
      <Label>{label}</Label>
      {isLink ? (
        <TextLink href={value} variant="inline">
          {value}
        </TextLink>
      ) : (
        <Text>{value}</Text>
      )}
    </Inline>
  ) : null;

const PropertyDetails = (): React.JSX.Element => {
  const location = useLocation();
  const [expandedJson, setExpandedJson] = useState<Record<string, boolean>>({});

  const state = location.state as {
    data: (PropertyDetailWithCreatives | PropertyLexaError)[];
  };

  const details: (PropertyDetailWithCreatives | PropertyLexaError)[] =
    state?.data || [];

  const listingWithError = details.filter(
    (item): item is PropertyLexaError =>
      'errorMessage' in item && isPresent(item.errorMessage),
  );
  const propDetails = details.filter(
    (item): item is PropertyDetailWithCreatives => !('errorMessage' in item),
  );
  return (
    <StyledStack gap="small">
      <Link to="/prop-tool">Back</Link>
      {listingWithError.map((error) => (
        <Alert key={error.listingId} variant="error">
          {`${error.errorMessage} for listing ID: ${error.listingId}`}
        </Alert>
      ))}
      <H1>Property Details</H1>
      <Accordion>
        {propDetails.map((detail) => (
          <AccordionItem
            key={detail.listingId}
            title={`Listing ID: ${detail.listingId}`}
          >
            <StyledStack gap="0">
              <PropertyField label="Suburb" value={detail.address?.suburb} />
              <PropertyField label="Headline" value={detail.adCopy?.title} />

              <PropertyField
                label="Beds"
                value={detail.features?.general?.bedrooms?.toString()}
              />
              <PropertyField
                label="Cars"
                value={detail.features?.general?.parkingSpaces?.toString()}
              />
              <PropertyField
                label="Baths"
                value={detail.features?.general?.bathrooms?.toString()}
              />
              <PropertyField label="Description" value={detail.adCopy?.body} />
              <PropertyField
                label="Description2"
                value={detail.adCopy.description}
              />
              <PropertyField
                label="Agency Color:"
                value={detail.agency.brandingColors.primary}
              />
              <PropertyField
                label="Agency ID"
                value={detail.agency?.agencyId}
              />
              <PropertyField label="Agency Name" value={detail.agency?.name} />

              <PropertyField
                label="Agency Logo Small:"
                value={detail.imageUrls.agencyLogoUrls.small}
                isLink
              />
              <PropertyField
                label="Agency Logo Default:"
                value={detail.imageUrls.agencyLogoUrls.default}
                isLink
              />
              <PropertyField
                label="Agency Logo Large:"
                value={detail.imageUrls.agencyLogoUrls.large}
                isLink
              />
              <PropertyField
                label="Agency Logo Preferred:"
                value={detail.imageUrls.agencyLogoUrls.preferred}
                isLink
              />
              <PropertyField
                label="Agency Logo ORIGINAL:"
                value={detail.imageUrls.agencyLogoUrls.original}
                isLink
              />
              <PropertyField label="Property URL" value={detail.link} isLink />
              <PropertyField
                label="Lister Name"
                value={detail.firstLister?.name}
              />
              <PropertyField
                label="Lister Photo:"
                value={detail.imageUrls.listerPhotoUrl}
                isLink
              />
            </StyledStack>
            <StyledStack gap="0">
              <Label>FB Landscape image URLs</Label>
              {detail.imageUrls.fbLandscapeImageUrls.map((image, index) => (
                <Inline key={`${index}-${image}`}>
                  <Label>{`Image ${index + 1} URL:`}</Label>
                  <TextLink href={image} variant="inline">
                    {image}
                  </TextLink>
                </Inline>
              ))}
            </StyledStack>
            <StyledStack gap="0">
              <Label>FB Square image URLs</Label>
              {detail.imageUrls.fbSquareImageUrls.map((image, index) => (
                <Inline key={`${index}-${image}`}>
                  <Label>{`Image ${index + 1} URL:`}</Label>
                  <TextLink href={image} variant="inline">
                    {image}
                  </TextLink>
                </Inline>
              ))}
            </StyledStack>
            <StyledStack gap="0">
              <Label>RAW image URLs</Label>
              {detail.imageUrls.rawImageUrls.map((image, index) => (
                <Inline key={`${index}-${image}`}>
                  <Label>{`Image ${index + 1} URL:`}</Label>
                  <TextLink href={image} variant="inline">
                    {image}
                  </TextLink>
                </Inline>
              ))}
            </StyledStack>

            <StyledStack gap="0">
              <Button
                variant="link-primary"
                onClick={() =>
                  setExpandedJson((prev) => ({
                    ...prev,
                    [detail.listingId]: !prev[detail.listingId],
                  }))
                }
              >
                {`${expandedJson[detail.listingId] ? 'Hide' : 'View'} Raw JSON Return`}
              </Button>
              {expandedJson[detail.listingId] && (
                <StyledStack gap="0">
                  <Label>Raw JSON Return: </Label>
                  <pre>
                    {isPresent(detail.rawLexaJson) &&
                      JSON.stringify(JSON.parse(detail.rawLexaJson), null, 3)}
                  </pre>
                </StyledStack>
              )}
            </StyledStack>
          </AccordionItem>
        ))}
      </Accordion>
    </StyledStack>
  );
};

export default PropertyDetails;
