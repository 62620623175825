import { Link } from '@rea-group/construct-kit-core';
import { formatAustralianDate } from '../../utils/formatDate';
import formatLive from '../../utils/formatLive';
import formatPrice from '../../utils/formatPrice';
import ExternalLink from '../ExternalLink';
import Status from '../Status';
import {
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import { isDspEnabled } from '../../utils/helpers';

const formatNumber = (value: number): string => value.toLocaleString('en');

export interface BookingTrackerData {
  id: number;
  startDate: string;
  bookingPeriod: string;
  impressions: string;
  listingId: number;
  listingPostcode: string;
  propertyType: string;
  listingPrice: string;
  agencyId: string;
  listingStreetAddress: string;
  listingSuburb: string;
  listingState: string;
  listingRegion: string | null;
  listingRegionState: string | null;
  listingStatus: string;
  dateCreated: string;
  bookingId: string;
  status: string;
  radius: string;
  fbCpcRadius: string;
  fbMtRadius: string;
  actualStartDate: string | null;
  actualEndDate: string | null;
  actualFbEndDate: string | null;
  actualMmEndDate: string | null;
  actualYhEndDate: string | null;
  actualAfEndDate: string | null;
  actualTbEndDate: string | null;
  actualTtdEndDate: string | null;
  fbCampaignId: string | null;
  fbLive: boolean;
  fbLiveCpm: boolean;
  fbLiveCpc: boolean;
  fbLiveMt: boolean;
  fbImps: number;
  fbImpsYesterday: number;
  fbClicks: number;
  fbClicksYesterday: number;
  fbSpend: number;
  fbSpendYesterday: number;
  fbBudget: number;
  fbBudgetYesterday: number;
  fbCpcImps: number;
  fbCpcImpsYesterday: number;
  fbCpcClicks: number;
  fbCpcClicksYesterday: number;
  fbCpcSpend: number;
  fbCpcSpendYesterday: number;
  fbCpcBudget: number;
  fbCpcBudgetYesterday: number;
  fbCpmImps: number;
  fbCpmImpsYesterday: number;
  fbCpmClicks: number;
  fbCpmClicksYesterday: number;
  fbCpmSpend: number;
  fbCpmSpendYesterday: number;
  fbCpmBudget: number;
  fbCpmBudgetYesterday: number;
  fbMtImps: number;
  fbMtImpsYesterday: number;
  fbMtClicksYesterday: number;
  fbMtClicks: number;
  fbMtSpend: number;
  fbMtSpendYesterday: number;
  fbMtBudget: number;
  fbMtBudgetYesterday: number;
  mmCampaignId: string | null;
  mmCampaignName: string | null;
  mmLive: boolean;
  mmImps: number;
  mmClicks: number;
  mmSpend: number;
  mmBudget: number;
  mmImpsYesterday: number;
  mmClicksYesterday: number;
  mmSpendYesterday: number;
  mmBudgetYesterday: number;
  yhCampaignId: string | null;
  yhLive: boolean;
  yhImps: number;
  yhClicks: number;
  yhSpend: number;
  yhBudget: number;
  yhImpsYesterday: number;
  yhClicksYesterday: number;
  yhSpendYesterday: number;
  yhBudgetYesterday: number;
  yhCpmImps: number;
  yhCpmClicks: number;
  yhCpmSpend: number;
  yhCpmBudget: number;
  yhCpmImpsYesterday: number;
  yhCpmClicksYesterday: number;
  yhCpmSpendYesterday: number;
  yhCpmBudgetYesterday: number;
  yhCpcImps: number;
  yhCpcClicks: number;
  yhCpcSpend: number;
  yhCpcBudget: number;
  yhCpcImpsYesterday: number;
  yhCpcClicksYesterday: number;
  yhCpcSpendYesterday: number;
  yhCpcBudgetYesterday: number;
  afCampaignId: number;
  afSpend: number;
  afSpendYesterday: number;
  afLive: boolean;
  afImps: number;
  afClicks: number;
  afBudget: number;
  afImpsYesterday: number;
  afClicksYesterday: number;
  afBudgetYesterday: number;
  tbCampaignGroupId: number;
  tbSpend: number;
  tbSpendYesterday: number;
  tbLive: boolean;
  tbImps: number;
  tbClicks: number;
  tbBudget: number;
  tbImpsYesterday: number;
  tbClicksYesterday: number;
  tbBudgetYesterday: number;
  ttdCampaignId: string | null;
  ttdSpend: number;
  ttdSpendYesterday: number;
  ttdLive: boolean;
  ttdImps: number;
  ttdClicks: number;
  ttdBudget: number;
  ttdImpsYesterday: number;
  ttdClicksYesterday: number;
  ttdBudgetYesterday: number;
  dv360CampaignId: string | null;
  notes: string | null;
}

interface BookingDataBookingTrackerPanelProps {
  data: BookingTrackerData;
}

type BookingTrackerDataColumnName = keyof BookingTrackerData;

export const columnLabels: Partial<
  Record<BookingTrackerDataColumnName, string>
> = {
  id: 'ID',
  startDate: 'Start Date',
  bookingPeriod: 'Booking Period',
  impressions: 'Impressions',
  listingId: 'Listing ID',
  listingPostcode: 'Listing Postcode',
  propertyType: 'Property Type',
  listingPrice: 'Listing Price',
  agencyId: 'Agency ID',
  listingStreetAddress: 'Listing Street Address',
  listingSuburb: 'Listing Suburb',
  listingState: 'Listing State',
  listingRegion: 'Listing Region',
  listingRegionState: 'Listing Region State',
  listingStatus: 'Listing Status',
  dateCreated: 'Date Created',
  bookingId: 'Booking ID',
  status: 'Status',
  radius: 'Radius',
  fbCpcRadius: 'FB CPC Radius',
  fbMtRadius: 'FB MT Radius',
  actualStartDate: 'Actual Start date',
  actualEndDate: 'Actual End Date',
  actualFbEndDate: 'Actual FB End Date',
  actualMmEndDate: 'Actual MM End Date',
  ...(isDspEnabled('yahoo') && { actualYhEndDate: 'Actual YH End Date' }),
  actualAfEndDate: 'Actual AF End Date',
  ...(isDspEnabled('taboola') && { actualTbEndDate: 'Actual TB End Date' }),
  actualTtdEndDate: 'Actual TTD End Date',
  fbCampaignId: 'FB Campaign ID',
  fbLive: 'FB Live',
  fbLiveCpm: 'FB Live CPM',
  fbLiveCpc: 'FB Live CPC',
  fbLiveMt: 'FB Live MT',
  fbImps: 'FB Imps',
  fbImpsYesterday: 'FB Imps YDY',
  fbClicks: 'FB Clicks',
  fbClicksYesterday: 'FB Clicks YDY',
  fbSpend: 'FB Spend',
  fbSpendYesterday: 'FB Spend YDY',
  fbBudget: 'FB Budget',
  fbBudgetYesterday: 'FB Budget YDY',
  fbCpcImps: 'FB CPC Imps',
  fbCpcImpsYesterday: 'FB CPC Imps YDY',
  fbCpcClicks: 'FB CPC Clicks',
  fbCpcClicksYesterday: 'FB CPC Clicks YDY',
  fbCpcSpend: 'FB CPC Spend',
  fbCpcSpendYesterday: 'FB CPC Spend YDY',
  fbCpcBudget: 'FB CPC Budget',
  fbCpcBudgetYesterday: 'FB CPC Budget YDY',
  fbCpmImps: 'FB CPM Imps',
  fbCpmImpsYesterday: 'FB CPM Imps YDY',
  fbCpmClicks: 'FB CPM Clicks',
  fbCpmClicksYesterday: 'FB CPM Clicks YDY',
  fbCpmSpend: 'FB CPM Spend',
  fbCpmSpendYesterday: 'FB CPM Spend YDY',
  fbCpmBudget: 'FB CPM Budget',
  fbCpmBudgetYesterday: 'FB CPM Budget YDY',
  fbMtImps: 'FB MT Imps',
  fbMtImpsYesterday: 'FB MT Imps YDY',
  fbMtClicksYesterday: 'FB MT Clicks YDY',
  fbMtClicks: 'FB MT Clicks',
  fbMtSpend: 'FB MT Spend',
  fbMtSpendYesterday: 'FB MT Spend YDY',
  fbMtBudget: 'FB MT Budget',
  fbMtBudgetYesterday: 'FB MT Budget YDY',
  mmCampaignId: 'MM Campaign ID',
  mmCampaignName: 'MM Campaign Name',
  mmLive: 'MM Live',
  mmImps: 'MM Imps',
  mmClicks: 'MM Clicks',
  mmSpend: 'MM Spend',
  mmBudget: 'MM Budget',
  mmImpsYesterday: 'MM Imps YDY',
  mmClicksYesterday: 'MM Clicks YDY',
  mmSpendYesterday: 'MM Spend YDY',
  mmBudgetYesterday: 'MM Budget YDY',
  ...(isDspEnabled('yahoo') && {
    yhCampaignId: 'YH Campaign ID',
    yhLive: 'YH Live',
    yhImps: 'YH Imps',
    yhClicks: 'YH Clicks',
    yhSpend: 'YH Spend',
    yhBudget: 'YH Budget',
    yhImpsYesterday: 'YH Imps YDY',
    yhClicksYesterday: 'YH Clicks YDY',
    yhSpendYesterday: 'YH Spend YDY',
    yhBudgetYesterday: 'YH Budget YDY',
    yhCpmImps: 'YH CPM Imps',
    yhCpmClicks: 'YH CPM Clicks',
    yhCpmSpend: 'YH CPM Spend',
    yhCpmBudget: 'YH CPM Budget',
    yhCpmImpsYesterday: 'YH CPM Imps YDY',
    yhCpmClicksYesterday: 'YH CPM Clicks YDY',
    yhCpmSpendYesterday: 'YH CPM Spend YDY',
    yhCpmBudgetYesterday: 'YH CPM Budget YDY',
    yhCpcImps: 'YH CPC Imps',
    yhCpcClicks: 'YH CPC Clicks',
    yhCpcSpend: 'YH CPC Spend',
    yhCpcBudget: 'YH CPC Budget',
    yhCpcImpsYesterday: 'YH CPC Imps YDY',
    yhCpcClicksYesterday: 'YH CPC Clicks YDY',
    yhCpcSpendYesterday: 'YH CPC Spend YDY',
    yhCpcBudgetYesterday: 'YH CPC Budget YDY',
  }),
  afCampaignId: 'AF Campaign ID',
  afSpend: 'AF Spend',
  afSpendYesterday: 'AF Spend YDY',
  afLive: 'AF Live',
  afImps: 'AF Imps',
  afClicks: 'AF Clicks',
  afBudget: 'AF Budget',
  afImpsYesterday: 'AF Imps YDY',
  afClicksYesterday: 'AF Clicks YDY',
  afBudgetYesterday: 'AF Budget YDY',
  ...(isDspEnabled('taboola') && {
    tbCampaignGroupId: 'TB Campaign Group ID',
    tbSpend: 'TB Spend',
    tbSpendYesterday: 'TB Spend YDY',
    tbLive: 'TB Live',
    tbImps: 'TB Imps',
    tbClicks: 'TB Clicks',
    tbBudget: 'TB Budget',
    tbImpsYesterday: 'TB Imps YDY',
    tbClicksYesterday: 'TB Clicks YDY',
    tbBudgetYesterday: 'TB Budget YDY',
  }),
  ttdCampaignId: 'TTD Campaign ID',
  ttdSpend: 'TTD Spend',
  ttdSpendYesterday: 'TTD Spend YDY',
  ttdLive: 'TTD Live',
  ttdImps: 'TTD Imps',
  ttdClicks: 'TTD Clicks',
  ttdBudget: 'TTD Budget',
  ttdImpsYesterday: 'TTD Imps YDY',
  ttdClicksYesterday: 'TTD Clicks YDY',
  ttdBudgetYesterday: 'TTD Budget YDY',
  dv360CampaignId: 'DV360 Campaign ID',
  notes: 'Notes',
};

const columnNames = Object.keys(columnLabels) as BookingTrackerDataColumnName[];

const createCellRenderers = ({
  id,
  startDate,
  bookingPeriod,
  impressions,
  listingId,
  listingPostcode,
  propertyType,
  listingPrice,
  agencyId,
  listingStreetAddress,
  listingSuburb,
  listingState,
  listingRegion,
  listingRegionState,
  listingStatus,
  dateCreated,
  bookingId,
  status,
  radius,
  fbCpcRadius,
  fbMtRadius,
  actualStartDate,
  actualEndDate,
  actualFbEndDate,
  actualMmEndDate,
  actualYhEndDate,
  actualAfEndDate,
  actualTbEndDate,
  actualTtdEndDate,
  fbCampaignId,
  fbLive,
  fbLiveCpm,
  fbLiveCpc,
  fbLiveMt,
  fbImps,
  fbImpsYesterday,
  fbClicks,
  fbClicksYesterday,
  fbSpend,
  fbSpendYesterday,
  fbBudget,
  fbBudgetYesterday,
  fbCpcImps,
  fbCpcImpsYesterday,
  fbCpcClicks,
  fbCpcClicksYesterday,
  fbCpcSpend,
  fbCpcSpendYesterday,
  fbCpcBudget,
  fbCpcBudgetYesterday,
  fbCpmImps,
  fbCpmImpsYesterday,
  fbCpmClicks,
  fbCpmClicksYesterday,
  fbCpmSpend,
  fbCpmSpendYesterday,
  fbCpmBudget,
  fbCpmBudgetYesterday,
  fbMtImps,
  fbMtImpsYesterday,
  fbMtClicksYesterday,
  fbMtClicks,
  fbMtSpend,
  fbMtSpendYesterday,
  fbMtBudget,
  fbMtBudgetYesterday,
  mmCampaignId,
  mmCampaignName,
  mmLive,
  mmImps,
  mmClicks,
  mmSpend,
  mmBudget,
  mmImpsYesterday,
  mmClicksYesterday,
  mmSpendYesterday,
  mmBudgetYesterday,
  yhCampaignId,
  yhLive,
  yhImps,
  yhClicks,
  yhSpend,
  yhBudget,
  yhImpsYesterday,
  yhClicksYesterday,
  yhSpendYesterday,
  yhBudgetYesterday,
  yhCpmImps,
  yhCpmClicks,
  yhCpmSpend,
  yhCpmBudget,
  yhCpmImpsYesterday,
  yhCpmClicksYesterday,
  yhCpmSpendYesterday,
  yhCpmBudgetYesterday,
  yhCpcImps,
  yhCpcClicks,
  yhCpcSpend,
  yhCpcBudget,
  yhCpcImpsYesterday,
  yhCpcClicksYesterday,
  yhCpcSpendYesterday,
  yhCpcBudgetYesterday,
  afCampaignId,
  afSpend,
  afSpendYesterday,
  afLive,
  afImps,
  afClicks,
  afBudget,
  afImpsYesterday,
  afClicksYesterday,
  afBudgetYesterday,
  tbCampaignGroupId,
  tbSpend,
  tbSpendYesterday,
  tbLive,
  tbImps,
  tbClicks,
  tbBudget,
  tbImpsYesterday,
  tbClicksYesterday,
  tbBudgetYesterday,
  ttdCampaignId,
  ttdSpend,
  ttdSpendYesterday,
  ttdLive,
  ttdImps,
  ttdClicks,
  ttdBudget,
  ttdImpsYesterday,
  ttdClicksYesterday,
  ttdBudgetYesterday,
  dv360CampaignId,
  notes,
}: BookingTrackerData): Record<
  BookingTrackerDataColumnName,
  () => React.JSX.Element | string | number | boolean | null
> => ({
  id: () => id,
  startDate: () => startDate,
  bookingPeriod: () => bookingPeriod,
  impressions: () => formatNumber(Number(impressions)),
  listingId: () => (
    <Link href={`https://www.realestate.com.au/${listingId}`} target="_blank">
      {listingId}
    </Link>
  ),
  listingPostcode: () => listingPostcode,
  propertyType: () => propertyType,
  listingPrice: () => listingPrice,
  agencyId: () => agencyId,
  listingStreetAddress: () => listingStreetAddress,
  listingSuburb: () => listingSuburb,
  listingState: () => listingState,
  listingRegion: () => listingRegion,
  listingRegionState: () => listingRegionState,
  listingStatus: () => listingStatus,
  dateCreated: () => dateCreated,
  bookingId: () => bookingId,
  status: () => <Status status={status} />,
  radius: () => radius,
  fbCpcRadius: () => fbCpcRadius,
  fbMtRadius: () => fbMtRadius,
  actualStartDate: () => formatAustralianDate(actualStartDate),
  actualEndDate: () => formatAustralianDate(actualEndDate),
  actualFbEndDate: () => formatAustralianDate(actualFbEndDate),
  actualMmEndDate: () => formatAustralianDate(actualMmEndDate),
  actualYhEndDate: () => formatAustralianDate(actualYhEndDate),
  actualAfEndDate: () => formatAustralianDate(actualAfEndDate),
  actualTbEndDate: () => formatAustralianDate(actualTbEndDate),
  actualTtdEndDate: () => formatAustralianDate(actualTtdEndDate),
  fbCampaignId: () =>
    fbCampaignId && (
      <ExternalLink
        href={`https://business.facebook.com/ads/manager/campaign/adsets/?ids=${fbCampaignId}`}
      >
        Facebook Link
      </ExternalLink>
    ),
  fbLive: () => formatLive(fbLive),
  fbLiveCpm: () => formatLive(fbLiveCpm),
  fbLiveCpc: () => formatLive(fbLiveCpc),
  fbLiveMt: () => formatLive(fbLiveMt),
  fbImps: () => fbImps,
  fbImpsYesterday: () => fbImpsYesterday,
  fbClicks: () => fbClicks,
  fbClicksYesterday: () => fbClicksYesterday,
  fbSpend: () => formatPrice(fbSpend),
  fbSpendYesterday: () => formatPrice(fbSpendYesterday),
  fbBudget: () => fbBudget,
  fbBudgetYesterday: () => fbBudgetYesterday,
  fbCpcImps: () => fbCpcImps,
  fbCpcImpsYesterday: () => fbCpcImpsYesterday,
  fbCpcClicks: () => fbCpcClicks,
  fbCpcClicksYesterday: () => fbCpcClicksYesterday,
  fbCpcSpend: () => formatPrice(fbCpcSpend),
  fbCpcSpendYesterday: () => formatPrice(fbCpcSpendYesterday),
  fbCpcBudget: () => fbCpcBudget,
  fbCpcBudgetYesterday: () => fbCpcBudgetYesterday,
  fbCpmImps: () => fbCpmImps,
  fbCpmImpsYesterday: () => fbCpmImpsYesterday,
  fbCpmClicks: () => fbCpmClicks,
  fbCpmClicksYesterday: () => fbCpmClicksYesterday,
  fbCpmSpend: () => formatPrice(fbCpmSpend),
  fbCpmSpendYesterday: () => formatPrice(fbCpmSpendYesterday),
  fbCpmBudget: () => fbCpmBudget,
  fbCpmBudgetYesterday: () => fbCpmBudgetYesterday,
  fbMtImps: () => fbMtImps,
  fbMtImpsYesterday: () => fbMtImpsYesterday,
  fbMtClicksYesterday: () => fbMtClicksYesterday,
  fbMtClicks: () => fbMtClicks,
  fbMtSpend: () => formatPrice(fbMtSpend),
  fbMtSpendYesterday: () => formatPrice(fbMtSpendYesterday),
  fbMtBudget: () => fbMtBudget,
  fbMtBudgetYesterday: () => fbMtBudgetYesterday,
  mmCampaignId: () =>
    mmCampaignId && (
      <ExternalLink
        href={`https://t1.mediamath.com/app/#campaign/edit/${mmCampaignId}/details/general`}
      >
        Mediamath Link
      </ExternalLink>
    ),
  mmCampaignName: () => mmCampaignName,
  mmLive: () => formatLive(mmLive),
  mmImps: () => mmImps,
  mmClicks: () => mmClicks,
  mmSpend: () => formatPrice(mmSpend),
  mmBudget: () => mmBudget,
  mmImpsYesterday: () => mmImpsYesterday,
  mmClicksYesterday: () => mmClicksYesterday,
  mmSpendYesterday: () => formatPrice(mmSpendYesterday),
  mmBudgetYesterday: () => mmBudgetYesterday,
  yhCampaignId: () =>
    yhCampaignId && (
      <ExternalLink
        href={`https://sso.admanagerplus.yahoo.com/app/campaigns/${yhCampaignId}/lines`}
      >
        Yahoo Link
      </ExternalLink>
    ),
  yhLive: () => formatLive(yhLive),
  yhImps: () => yhImps,
  yhClicks: () => yhClicks,
  yhSpend: () => formatPrice(yhSpend),
  yhBudget: () => yhBudget,
  yhImpsYesterday: () => yhImpsYesterday,
  yhClicksYesterday: () => yhClicksYesterday,
  yhSpendYesterday: () => formatPrice(yhSpendYesterday),
  yhBudgetYesterday: () => yhBudgetYesterday,
  yhCpmImps: () => yhCpmImps,
  yhCpmClicks: () => yhCpmClicks,
  yhCpmSpend: () => formatPrice(yhCpmSpend),
  yhCpmBudget: () => yhCpmBudget,
  yhCpmImpsYesterday: () => yhCpmImpsYesterday,
  yhCpmClicksYesterday: () => yhCpmClicksYesterday,
  yhCpmSpendYesterday: () => formatPrice(yhCpmSpendYesterday),
  yhCpmBudgetYesterday: () => yhCpmBudgetYesterday,
  yhCpcImps: () => yhCpcImps,
  yhCpcClicks: () => yhCpcClicks,
  yhCpcSpend: () => formatPrice(yhCpcSpend),
  yhCpcBudget: () => yhCpcBudget,
  yhCpcImpsYesterday: () => yhCpcImpsYesterday,
  yhCpcClicksYesterday: () => yhCpcClicksYesterday,
  yhCpcSpendYesterday: () => formatPrice(yhCpcSpendYesterday),
  yhCpcBudgetYesterday: () => yhCpcBudgetYesterday,
  afCampaignId: () =>
    afCampaignId && (
      <ExternalLink
        href={`https://flow.adform.com/campaign/${afCampaignId}/overview`}
      >
        Adform Link
      </ExternalLink>
    ),
  afSpend: () => formatPrice(afSpend),
  afSpendYesterday: () => formatPrice(afSpendYesterday),
  afLive: () => formatLive(afLive),
  afImps: () => afImps,
  afClicks: () => afClicks,
  afBudget: () => afBudget,
  afImpsYesterday: () => afImpsYesterday,
  afClicksYesterday: () => afClicksYesterday,
  afBudgetYesterday: () => afBudgetYesterday,
  tbCampaignGroupId: () => tbCampaignGroupId,
  tbSpend: () => formatPrice(tbSpend),
  tbSpendYesterday: () => formatPrice(tbSpendYesterday),
  tbLive: () => formatLive(tbLive),
  tbImps: () => tbImps,
  tbClicks: () => tbClicks,
  tbBudget: () => tbBudget,
  tbImpsYesterday: () => tbImpsYesterday,
  tbClicksYesterday: () => tbClicksYesterday,
  tbBudgetYesterday: () => tbBudgetYesterday,
  ttdCampaignId: () =>
    ttdCampaignId && (
      <ExternalLink
        href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${ttdCampaignId}/details`}
      >
        Tradedesk Link
      </ExternalLink>
    ),
  ttdSpend: () => formatPrice(ttdSpend),
  ttdSpendYesterday: () => formatPrice(ttdSpendYesterday),
  ttdLive: () => formatLive(ttdLive),
  ttdImps: () => ttdImps,
  ttdClicks: () => ttdClicks,
  ttdBudget: () => ttdBudget,
  ttdImpsYesterday: () => ttdImpsYesterday,
  ttdClicksYesterday: () => ttdClicksYesterday,
  ttdBudgetYesterday: () => ttdBudgetYesterday,
  dv360CampaignId: () => dv360CampaignId,
  notes: () => notes,
});

const BookingDataBookingTrackerPanel = ({
  data,
}: BookingDataBookingTrackerPanelProps): React.JSX.Element => {
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const cellRenderers = createCellRenderers(data);
  return (
    <BookingDataPanel heading="Booking Tracker">
      <Table>
        <TableHeader>
          {columnNames.map((columnName) => (
            <TableHeaderColumn key={`th-${columnName}`}>
              {columnLabels[columnName]}
            </TableHeaderColumn>
          ))}
        </TableHeader>
        <TableBody>
          <TableRow>
            {columnNames.map((columnName) => (
              <TableContentColumn key={`td-${columnName}`}>
                {cellRenderers[columnName]()}
              </TableContentColumn>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </BookingDataPanel>
  );
};

export default BookingDataBookingTrackerPanel;
