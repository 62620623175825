import { Link } from '@rea-group/construct-kit-core';
import { ValidationErrorBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';

interface MatchingPropertyTypeTargetErrorModalProps {
  bookings: ValidationErrorBooking[];
}

const MatchingPropertyTypeTargetErrorModal = ({
  bookings,
}: MatchingPropertyTypeTargetErrorModalProps): React.JSX.Element => {
  return (
    <BookingsModal heading="Unsuccessful Property Type Target Match">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Property Type</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(
              ({ rowNumber, bookingId, listingId, propertyType }) => (
                <TableRow key={rowNumber}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>{propertyType}</TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default MatchingPropertyTypeTargetErrorModal;
