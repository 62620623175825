import { Button, Strong } from '@rea-group/construct-kit-core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putVoid } from '../../API/fetch';
import {
  FacebookPageResponse,
  homepageDataQueryKey,
  HomepageResponseData,
} from '../../pages/Home';
import { isPresent } from '../../utils/helpers';
import ErrorAlert from '../ErrorAlert';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import Panel from './Panel';

interface FbPageAdLimitsPanelProps {
  fbPageAdLimitsData: FacebookPageResponse[];
}

const FbPageAdLimitsPanel = ({
  fbPageAdLimitsData,
}: FbPageAdLimitsPanelProps): React.JSX.Element => {
  const currentPageName =
    fbPageAdLimitsData.find(({ isInUse }) => isInUse)?.pageName ?? '';

  const queryClient = useQueryClient();

  const { isError, error, mutate } = useMutation({
    mutationFn: (pageId: string) =>
      putVoid('/facebook-page/switch', { body: JSON.stringify({ pageId }) }),
    onSuccess: (_, pageId) => {
      queryClient.setQueryData<HomepageResponseData>(
        homepageDataQueryKey,
        (previousHomePageData) => {
          if (!isPresent(previousHomePageData)) {
            return undefined;
          }
          const updatedFbPageAdLimitsData =
            previousHomePageData.fbPageAdLimitsData.map((fbPage) => ({
              ...fbPage,
              isInUse: fbPage.pageId === pageId,
            }));

          return {
            ...previousHomePageData,
            fbPageAdLimitsData: updatedFbPageAdLimitsData,
          };
        },
      );
    },
  });

  return (
    <Panel
      heading="Facebook page ad limits"
      subHeading={
        <>
          Current page: <Strong>{currentPageName}</Strong>
        </>
      }
    >
      {isError && <ErrorAlert error={error} />}
      <Table>
        <TableHeader>
          <TableHeaderColumn>Page ID</TableHeaderColumn>
          <TableHeaderColumn>Page name</TableHeaderColumn>
          <TableHeaderColumn>Ad count</TableHeaderColumn>
          <TableHeaderColumn>Ad limit</TableHeaderColumn>
          <TableHeaderColumn>Remaining</TableHeaderColumn>
          <TableHeaderColumn>Use</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {fbPageAdLimitsData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            fbPageAdLimitsData.map(
              ({ pageId, pageName, adCount, adLimit, remaining, isInUse }) => (
                <TableRow key={pageId}>
                  <TableContentColumn>{pageId}</TableContentColumn>
                  <TableContentColumn>{pageName}</TableContentColumn>
                  <TableContentColumn>{adCount}</TableContentColumn>
                  <TableContentColumn>{adLimit}</TableContentColumn>
                  <TableContentColumn>{remaining}</TableContentColumn>
                  <TableContentColumn>
                    {isInUse ? (
                      'In Use'
                    ) : (
                      <Button
                        variant="link-primary"
                        onClick={() => mutate(pageId)}
                      >
                        Use this link
                      </Button>
                    )}
                  </TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </Panel>
  );
};

export default FbPageAdLimitsPanel;
