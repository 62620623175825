import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableContentColumn,
  EmptyTableBody,
} from '../Table/Table';

export interface FacebookAudience {
  id: number;
  accountId: string;
  audienceId: string;
  audienceName: string;
}

export interface FBAudienceDetailsTableProps {
  data: FacebookAudience[];
}

const FBAudienceDetailsTable = ({
  data,
}: FBAudienceDetailsTableProps): React.JSX.Element => (
  <Table>
    <TableHeader>
      <TableHeaderColumn>Account ID</TableHeaderColumn>
      <TableHeaderColumn>Audience ID</TableHeaderColumn>
      <TableHeaderColumn>Audience Name</TableHeaderColumn>
      <TableHeaderColumn>ID</TableHeaderColumn>
    </TableHeader>
    <TableBody>
      {data.length === 0 ? (
        <EmptyTableBody />
      ) : (
        data.map(({ accountId, audienceId, audienceName, id }) => (
          <TableRow key={id}>
            <TableContentColumn>{accountId}</TableContentColumn>
            <TableContentColumn>{audienceId}</TableContentColumn>
            <TableContentColumn>{audienceName}</TableContentColumn>
            <TableContentColumn>{id}</TableContentColumn>
          </TableRow>
        ))
      )}
    </TableBody>
  </Table>
);

export default FBAudienceDetailsTable;
