import { Link } from '@rea-group/construct-kit-core';
import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface RecognizedBookingData {
  id: number;
  startDate: string;
  bookingPeriod: string;
  impressions: number;
  listingId: number;
  listingPostcode: string;
  propertyType: string;
  listingPrice: string;
  agencyId: string;
  listingStreetAddress: string;
  listingSuburb: string;
  listingState: string;
  listingRegion: string;
  createdDate: string;
  bookingId: string;
}

export interface BookingDataRecognisedBookingsListPanelProps {
  data: RecognizedBookingData[];
}

const BookingDataRecognisedBookingsListPanel = ({
  data,
}: BookingDataRecognisedBookingsListPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="Recognised Bookings List">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Start date</TableHeaderColumn>
        <TableHeaderColumn>Booking period</TableHeaderColumn>
        <TableHeaderColumn>Impressions</TableHeaderColumn>
        <TableHeaderColumn>Listing ID</TableHeaderColumn>
        <TableHeaderColumn>Listing postcode</TableHeaderColumn>
        <TableHeaderColumn>Property type</TableHeaderColumn>
        <TableHeaderColumn>Listing price</TableHeaderColumn>
        <TableHeaderColumn>Agency ID</TableHeaderColumn>
        <TableHeaderColumn>Listing street address</TableHeaderColumn>
        <TableHeaderColumn>Listing suburb</TableHeaderColumn>
        <TableHeaderColumn>Listing state</TableHeaderColumn>
        <TableHeaderColumn>Listing region</TableHeaderColumn>
        <TableHeaderColumn>Created date</TableHeaderColumn>
        <TableHeaderColumn>Booking ID</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({
              id,
              startDate,
              bookingPeriod,
              impressions,
              listingId,
              listingPostcode,
              propertyType,
              listingPrice,
              agencyId,
              listingStreetAddress,
              listingSuburb,
              listingState,
              listingRegion,
              createdDate,
              bookingId,
            }) => (
              <TableRow key={id}>
                <TableContentColumn>
                  {formatAustralianDate(startDate)}
                </TableContentColumn>
                <TableContentColumn>{bookingPeriod}</TableContentColumn>
                <TableContentColumn>
                  {impressions.toLocaleString('en')}
                </TableContentColumn>
                <TableContentColumn>
                  <Link
                    href={`https://www.realestate.com.au/${listingId}`}
                    target="_blank"
                  >
                    {listingId}
                  </Link>
                </TableContentColumn>
                <TableContentColumn>{listingPostcode}</TableContentColumn>
                <TableContentColumn>{propertyType}</TableContentColumn>
                <TableContentColumn>{listingPrice}</TableContentColumn>
                <TableContentColumn>{agencyId}</TableContentColumn>
                <TableContentColumn>{listingStreetAddress}</TableContentColumn>
                <TableContentColumn>{listingSuburb}</TableContentColumn>
                <TableContentColumn>{listingState}</TableContentColumn>
                <TableContentColumn>{listingRegion}</TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(createdDate)}
                </TableContentColumn>
                <TableContentColumn>{bookingId}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataRecognisedBookingsListPanel;
