import {
  H1,
  H2,
  Stack,
  Label,
  TextArea,
  Radio,
  Button,
  Card,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { ListingDetailsResponse } from './types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { fetchJson } from '../../API/fetch';
import { downloadFile } from '../../utils/downloadFile';
import { isEmpty } from 'lodash';

const StyledStack = styled(Stack)`
  > * {
    width: fit-content;
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 300px;
`;

const LISTING_ID_REGEX = /^[0-9,]+$/;

const validateListingIds = (listingIds: string): string => {
  if (isEmpty(listingIds)) return 'Please enter Property ID.';
  if (!LISTING_ID_REGEX.test(listingIds) || listingIds.endsWith(','))
    return 'Property ID should only contain numbers separated by commas.';
  return '';
};

const PropTool = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({
    images: { listingIds: '', type: 'RESI' },
    details: { listingIds: '', type: 'RESI' },
  });

  const [errors, setErrors] = useState({ images: '', details: '' });

  const handleInputChange = (
    type: 'images' | 'details',
    value: string,
  ): void => {
    setRequestData((prev) => ({
      ...prev,
      [type]: { ...prev[type], listingIds: value.trim() },
    }));
    setErrors((prev) => ({ ...prev, [type]: '' }));
  };

  const downloadImages = (): void => {
    const validationError = validateListingIds(requestData.images.listingIds);
    if (!isEmpty(validationError)) {
      setErrors((prev) => ({ ...prev, images: validationError }));
    } else {
      downloadFile(
        `${process.env.API_ROOT}/prop-tool/creatives?listingIds=${requestData.images.listingIds}&type=${requestData.images.type}`,
      );
    }
  };

  const handleDownloadPropDetails = (): void => {
    const validationError = validateListingIds(requestData.details.listingIds);
    if (!isEmpty(validationError)) {
      setErrors((prev) => ({ ...prev, details: validationError }));
    } else {
      const details = fetchJson<ListingDetailsResponse>(
        `/prop-tool/listing-details?listingIds=${requestData.details.listingIds}&type=${requestData.details.type}`,
      );
      details
        .then((data) => {
          void navigate('/prop-tool/details', {
            state: { data: data.listingDetails },
          });
        })
        .catch((error) => {
          setErrors((prev) => ({ ...prev, details: (error as Error).message }));
        });
    }
  };

  return (
    <StyledStack gap="medium">
      <H1>Image Download Tool</H1>
      <StyledStack>
        <H2>RESI</H2>
        <Card>
          <StyledStack inset="medium medium">
            <Label>Version - Nimbus</Label>
            <StyledTextArea
              label="Property ID:"
              placeholder="Enter Property ID or multiple IDs separated by comma"
              onChange={(e) => handleInputChange('images', e.target.value)}
              errorMessage={errors.images}
            />
            <Label>Select type:</Label>
            <StyledStack gap="0">
              <Radio
                name="resi"
                id="resi"
                label="Resi"
                value="RESI"
                defaultChecked
              />
            </StyledStack>
            <Button onClick={downloadImages}>Download</Button>
          </StyledStack>
        </Card>
      </StyledStack>

      <StyledStack>
        <H2>Prop Details</H2>
        <Card>
          <StyledStack inset="large medium">
            <StyledTextArea
              label="Property ID:"
              placeholder="Enter Property ID or multiple IDs separated by comma"
              onChange={(e) => handleInputChange('details', e.target.value)}
              errorMessage={errors.details}
            />
            <Label>Select type:</Label>
            <StyledStack gap="0">
              <Radio
                name="resi-prop"
                id="resi-prop"
                label="Resi"
                value="RESI"
                defaultChecked
              />
            </StyledStack>
            <Button onClick={handleDownloadPropDetails}>Download</Button>
          </StyledStack>
        </Card>
      </StyledStack>
    </StyledStack>
  );
};

export default PropTool;
