import { H1, Stack } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import PackageForm from '../../components/packages/PackageForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  PackageFormValues,
  PackageRequest,
} from '../../components/packages/types';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorAlert from '../../components/ErrorAlert';
import { fetchJson } from '../../API/fetch';
import { PackageResponse } from './types';
import { fromPackageResponse } from '../../components/packages/helpers';
import { PageLoadingSpinner } from '../../components/LoadingSpinner';

const PackageContainer = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`;

type Variables = { values: PackageRequest; packageId: string };

type Params = { packageId: string };

const updatePackage = ({
  values,
  packageId,
}: Variables): Promise<PackageResponse> =>
  fetchJson<PackageResponse>(`/packages/${packageId}`, {
    method: 'PUT',
    body: JSON.stringify(values),
  });

const getPackageData = async (
  packageId: string,
): Promise<PackageFormValues> => {
  const packageResponse = await fetchJson<PackageResponse>(
    `/packages/${packageId}`,
  );
  return fromPackageResponse(packageResponse);
};

const EditPackage = (): React.JSX.Element => {
  const queryClient = useQueryClient();
  const { packageId } = useParams<Params>() as Params;

  const {
    isLoading,
    isError,
    error,
    data: packageData,
  } = useQuery({
    queryKey: ['editPackage', packageId],
    queryFn: () => getPackageData(packageId),
  });

  const navigate = useNavigate();

  const updateMutation = useMutation({
    mutationFn: updatePackage,
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ['editPackage', data.id.toString()],
        fromPackageResponse(data),
      );
      await queryClient.invalidateQueries({ queryKey: ['packagesData'] });

      const result = navigate('/packages');

      if (result instanceof Promise) {
        await result;
      }
    },
  });

  return (
    <PackageContainer>
      <Stack>
        <H1>Edit package</H1>
        {isLoading && <PageLoadingSpinner />}
        {(isError || updateMutation.isError) && (
          <ErrorAlert error={error || updateMutation.error} />
        )}
        {packageData && (
          <PackageForm
            onSave={(values: PackageRequest) => {
              updateMutation.mutate({ values, packageId });
            }}
            defaultValues={packageData}
            isLoading={updateMutation.isPending}
          />
        )}
      </Stack>
    </PackageContainer>
  );
};

export default EditPackage;
