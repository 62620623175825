import {
  Tab,
  TabList,
  TabBar,
  Tabs,
  spacingHelper,
  Stack,
} from '@rea-group/construct-kit-core';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useFeatureToggles } from '../utils/useFeatureToggles';

const LogoContainer = styled.div`
  padding: ${spacingHelper('medium 0 medium large')};
  position: absolute;
  left: 0;
  top: 0;
`;

const Nav = styled.nav`
  padding-top: ${spacingHelper('medium')};
`;

const Logo = styled.img`
  width: 9.4rem;
  height: 2.25rem;
`;

const StyledTabBar = styled(TabBar)`
  margin-bottom: 0;
`;

const tabs = [
  { key: 0, title: 'Home', to: '/' },
  { key: 1, title: 'Job Summary', to: '/job-summary' },
  { key: 2, title: 'Packages', to: '/packages' },
  { key: 3, title: 'Targeting Mods', to: '/targeting-mods' },
  { key: 4, title: 'FB Ad Count', to: '/fb-ad-count' },
  { key: 5, title: 'FB Audience Count', to: '/fb-audience-count' },
  { key: 6, title: 'Status Monitor', to: '/status-monitor' },
  { key: 7, title: 'Tracker', to: '/tracker' },
  { key: 8, title: 'Boost Tracker', to: '/boost-tracker' },
  { key: 9, title: 'Booking Data', to: '/booking-data' },
  { key: 10, title: 'Job History', to: '/job-history' },
  { key: 10, title: 'Prop Tool', to: '/prop-tool' },
];

const getSelectedTabIndex = (pathname: string): number | undefined => {
  if (pathname === '/') {
    return 0;
  }

  const selectedTabIndex = tabs.findIndex(
    ({ to }) => to !== '/' && pathname.indexOf(to) === 0,
  );

  return selectedTabIndex !== -1 ? selectedTabIndex : undefined;
};

const Header = (): React.JSX.Element => {
  const location = useLocation();
  const selectedTab = getSelectedTabIndex(location.pathname);
  const { isJobSummaryPageEnabled } = useFeatureToggles();

  if (!isJobSummaryPageEnabled) {
    const index = tabs.findIndex((tab) => tab.to === '/job-summary');
    if (index !== -1) {
      tabs.splice(index, 1);
    }
  }

  return (
    <Stack gap="0">
      <LogoContainer>
        <Logo src="/logo.png" alt="logo" />
      </LogoContainer>
      <Nav>
        <Tabs
          defaultSelectedTab={0}
          selectedTab={selectedTab}
          variant="align-right"
        >
          <StyledTabBar>
            <TabList buttonColor="backgroundPrimaryBase">
              {tabs.map(({ key, title, to }) => (
                <Tab key={key} as={NavLink} to={to}>
                  {title}
                </Tab>
              ))}
            </TabList>
          </StyledTabBar>
        </Tabs>
      </Nav>
    </Stack>
  );
};

export default Header;
