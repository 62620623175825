export const isPresent = <T>(something: T | undefined | null): something is T =>
  something !== undefined && something !== null;

export const emptyIfNotPresent = (value: number | string | null): string =>
  isPresent(value) ? value.toString() : '';

type DSP = 'facebook' | 'adform' | 'tradedesk' | 'yahoo' | 'taboola';

const DISABLED_DSPS: DSP[] = ['yahoo', 'taboola'];

export const isDspEnabled = (dsp: DSP): boolean => !DISABLED_DSPS.includes(dsp);
