import { ConstructKit, Pangea } from '@rea-group/construct-kit-core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Home from './pages/Home';
import BookingData from './pages/BookingData';
import Tracker from './pages/Tracker';
import Packages from './pages/packages/Packages';
import StatusMonitor from './pages/StatusMonitor';
import TargetingMods from './pages/targetingMods/TargetingMods';
import Layout from './components/Layout';
import FBAdCount from './pages/FBAdCount';
import FBAudienceCount from './pages/FBAudienceCount';
import JobQueue from './pages/JobQueue';
import Login from './pages/Login';
import UploadBookingCampaign from './pages/UploadBookingCampaign';
import CreatePackage from './pages/packages/CreatePackage';
import EditPackage from './pages/packages/EditPackage';
import CreateTargetingMod from './pages/targetingMods/CreateTargetingMod';
import EditTargetingMod from './pages/targetingMods/EditTargetingMod';
import CampaignImagesPreview from './pages/CampaignImagesPreview';
import ScrollToTop from './components/ScrollToTop';
import { FeatureToggleProvider } from './utils/useFeatureToggles';
import BoostTracker from './pages/BoostTracker';
import JobHistory from './pages/JobHistory';
import JobSummary from './pages/JobSummary';
import PropTool from './pages/propTool/PropTool';
import PropertyDetails from './pages/propTool/PropertyDetails';

const App = (): React.JSX.Element => {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <FeatureToggleProvider>
          <ConstructKit>
            <Pangea />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route
                  path="upload-booking-campaign"
                  element={<UploadBookingCampaign />}
                />
                <Route path="job-queue" element={<JobQueue />} />
                <Route path="job-summary" element={<JobSummary />} />
                <Route path="packages" element={<Packages />} />
                <Route path="packages/create" element={<CreatePackage />} />
                <Route
                  path="packages/edit/:packageId"
                  element={<EditPackage />}
                />
                <Route path="targeting-mods" element={<TargetingMods />} />
                <Route
                  path="targeting-mods/create"
                  element={<CreateTargetingMod />}
                />
                <Route
                  path="targeting-mods/edit/:targetId"
                  element={<EditTargetingMod />}
                />
                <Route path="fb-ad-count" element={<FBAdCount />} />
                <Route path="fb-audience-count" element={<FBAudienceCount />} />
                <Route path="booking-data" element={<BookingData />} />
                <Route path="tracker" element={<Tracker />} />
                <Route path="status-monitor" element={<StatusMonitor />} />
                <Route
                  path="campaign-images-preview"
                  element={<CampaignImagesPreview />}
                />
                <Route path="boost-tracker" element={<BoostTracker />} />
                <Route path="job-history" element={<JobHistory />} />
                <Route path="prop-tool" element={<PropTool />} />
                <Route path="prop-tool/details" element={<PropertyDetails />} />
              </Route>
            </Routes>
          </ConstructKit>
        </FeatureToggleProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
