import { Button, Inline } from '@rea-group/construct-kit-core';
import { endOfToday, startOfToday } from 'date-fns';
import { DateRange } from 'react-day-picker/src/types';
import RangeDatePicker from './RangeDatePicker';

interface RangeDatePickerProps {
  placeHolders: { from: string; to: string };
  dateRange: DateRange;
  setDateRange: (arg: DateRange) => void;
}

export const RangeDatePickerWithPresets = ({
  placeHolders,
  dateRange,
  setDateRange,
}: RangeDatePickerProps): React.JSX.Element => {
  const selectLast24Hours = (): void =>
    setDateRange({ from: startOfToday(), to: endOfToday() });

  const selectLast3Days = (): void =>
    setDateRange({
      from: new Date(new Date().setDate(startOfToday().getDate() - 2)),
      to: endOfToday(),
    });

  const selectLast7Days = (): void =>
    setDateRange({
      from: new Date(new Date().setDate(startOfToday().getDate() - 6)),
      to: endOfToday(),
    });

  const FooterButtonPresets = (
    <Inline>
      <Button
        variant="primary"
        sizeVariant="medium"
        onClick={selectLast24Hours}
      >
        Last 24 Hours
      </Button>
      <Button variant="primary" sizeVariant="medium" onClick={selectLast3Days}>
        Last 3 Days
      </Button>
      <Button variant="primary" sizeVariant="medium" onClick={selectLast7Days}>
        Last 7 Days
      </Button>
    </Inline>
  );

  return (
    <RangeDatePicker
      placeHolders={placeHolders}
      dateRange={dateRange}
      setDateRange={setDateRange}
      footer={FooterButtonPresets}
    />
  );
};

export default RangeDatePickerWithPresets;
