import TotalCampaignReportingPanel from './TotalCampaignReportingPanel';

export interface TradedeskReportingCampaignFlatData {
  id: number;
  campaignId: string;
  campaignName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number;
  clicks: number;
  spend: number;
  budget: number | null;
}

export interface BookingDataTtdTotalCampaignReportingPanelProps {
  data: TradedeskReportingCampaignFlatData[];
}

const BookingDataTtdTotalCampaignReportingPanel = ({
  data,
}: BookingDataTtdTotalCampaignReportingPanelProps): React.JSX.Element => (
  <TotalCampaignReportingPanel
    heading="TTD Total Campaign Reporting"
    data={data}
  />
);

export default BookingDataTtdTotalCampaignReportingPanel;
