import { Inline, paletteHelper, Tooltip } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

interface Props {
  title: string;
  messages: string[] | undefined;
  type?: 'error' | 'warning' | 'success';
}

const InlineError = styled(Inline)`
  color: ${paletteHelper('textError')};
`;

const TooltipError = styled(Tooltip)`
  > button {
    color: ${paletteHelper('textError')};
  }
  white-space: pre-wrap;
`;

const TooltipWarning = styled(Tooltip)`
  > button {
    color: ${paletteHelper('textWarningInverted')};
  }
  white-space: pre-wrap;
`;

const InlineWarning = styled(Inline)`
  color: ${paletteHelper('textWarningInverted')};
`;

const TooltipSuccess = styled(Tooltip)`
  > button {
    color: ${paletteHelper('textSuccessInverted')};
  }
  white-space: pre-wrap;
`;

const InlineSuccess = styled(Inline)`
  color: ${paletteHelper('textSuccessInverted')};
`;

const AlertTooltip = ({
  title,
  messages,
  type = 'error',
}: Props): React.JSX.Element | null => {
  if (!messages || messages.length === 0) {
    return null;
  }

  if (type === 'success') {
    return (
      <InlineSuccess>
        {title} <TooltipSuccess>{messages.join('\n')}</TooltipSuccess>
      </InlineSuccess>
    );
  }

  if (type === 'warning') {
    return (
      <InlineWarning>
        {title} <TooltipWarning>{messages.join('\n')}</TooltipWarning>
      </InlineWarning>
    );
  }

  return (
    <InlineError>
      {title} <TooltipError>{messages.join('\n')}</TooltipError>
    </InlineError>
  );
};

export default AlertTooltip;
