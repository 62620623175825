import { formatAustralianDate, formatDateTime } from '../../utils/formatDate';
import ExternalLink from '../ExternalLink';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface BoostBookingsData {
  id: number;
  bookingId: string;
  boostType: string;
  boostTrackerStatus: string;
  packageId: number | null;
  listingId: number | null;
  startDate: string | null;
  endDate: string | null;
  status: string;
  lastProcessed: string | null;
  createdAt: string | null;
  fbCampaignId: string;
  fbAccountId: string;
  note: string;
  bookingsTrackerId: number | null;
}

export interface BookingDataBoostBookingsListPanelProps {
  data: BoostBookingsData[];
}

type BoostBookingsDataColumnName = keyof BoostBookingsData;

export const boostBookingColumnLabels: Record<
  BoostBookingsDataColumnName,
  string
> = {
  id: 'ID',
  bookingId: 'Booking ID',
  boostType: 'Boost Type',
  boostTrackerStatus: 'Boost Tracker Status',
  packageId: 'Package ID',
  listingId: 'Listing ID',
  startDate: 'Start Date',
  endDate: 'End Date',
  status: 'Status',
  lastProcessed: 'Processed date',
  createdAt: 'Created At',
  fbCampaignId: 'FB Campaign ID',
  fbAccountId: 'FB Account ID',
  note: 'Note',
  bookingsTrackerId: 'Bookings Tracker ID',
};

const boostBookingColumnNames = Object.keys(
  boostBookingColumnLabels,
) as BoostBookingsDataColumnName[];

const createCellRenderers = ({
  id,
  bookingId,
  boostType,
  boostTrackerStatus,
  packageId,
  listingId,
  startDate,
  endDate,
  status,
  lastProcessed,
  createdAt,
  fbCampaignId,
  fbAccountId,
  note,
  bookingsTrackerId,
}: BoostBookingsData): Record<
  BoostBookingsDataColumnName,
  () => React.JSX.Element | string | number | boolean | null
> => ({
  id: () => id,
  bookingId: () => bookingId,
  boostType: () => boostType,
  boostTrackerStatus: () => <Status status={boostTrackerStatus} />,
  packageId: () => packageId,
  listingId: () => listingId,
  startDate: () => formatAustralianDate(startDate),
  endDate: () => formatAustralianDate(endDate),
  status: () => <Status status={status} />,
  lastProcessed: () => formatDateTime(lastProcessed),
  createdAt: () => formatDateTime(createdAt),
  fbCampaignId: () =>
    fbCampaignId && (
      <ExternalLink
        href={`https://business.facebook.com/ads/manager/campaign/adsets/?ids=${fbCampaignId}`}
      >
        {fbCampaignId}
      </ExternalLink>
    ),
  fbAccountId: () => fbAccountId,
  note: () => note,
  bookingsTrackerId: () => bookingsTrackerId,
});

export const BookingDataBoostBookingsListPanel = ({
  data,
}: BookingDataBoostBookingsListPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="Boost Bookings">
    <Table>
      <TableHeader>
        {boostBookingColumnNames.map((boostBookingColumnName) => (
          <TableHeaderColumn key={`th-${boostBookingColumnName}`}>
            {boostBookingColumnLabels[boostBookingColumnName]}
          </TableHeaderColumn>
        ))}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((dataRow) => {
            // eslint-disable-next-line testing-library/render-result-naming-convention
            const boostBookingCellRenderers = createCellRenderers(dataRow);

            return (
              <TableRow key={`tr-${dataRow.id}`}>
                {boostBookingColumnNames.map((boostBookingColumnName) => (
                  <TableContentColumn key={`td-${boostBookingColumnName}`}>
                    {boostBookingCellRenderers[boostBookingColumnName]()}
                  </TableContentColumn>
                ))}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);
