import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import {
  renderCampaignMetrics,
  renderCampaignMetricsHeaders,
} from './renderCampaignMetrics';

export interface TradedeskReportingCampaignData {
  id: number;
  campaignId: string;
  campaignName: string;
  adgroupId: string;
  adgroupName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number;
  clicks: number;
  spend: number;
  budget: number | null;
}

export interface BookingDataTtdDailyCampaignReportingPanelProps {
  data: TradedeskReportingCampaignData[];
}

const BookingDataTtdDailyCampaignReportingPanel = ({
  data,
}: BookingDataTtdDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="TTD Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Ad Group ID</TableHeaderColumn>
        <TableHeaderColumn>Ad Group name</TableHeaderColumn>
        {renderCampaignMetricsHeaders()}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((row) => (
            <TableRow key={row.id}>
              <TableContentColumn>{row.campaignId}</TableContentColumn>
              <TableContentColumn>{row.campaignName}</TableContentColumn>
              <TableContentColumn>{row.adgroupId}</TableContentColumn>
              <TableContentColumn>{row.adgroupName}</TableContentColumn>
              {renderCampaignMetrics(row)}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataTtdDailyCampaignReportingPanel;
