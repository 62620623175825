import {
  Button,
  Checkbox,
  H2,
  H3,
  Inline,
  Link,
  Radio,
  Select,
  SelectOption,
  Stack,
  TextInput,
  Tooltip,
} from '@rea-group/construct-kit-core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import { PackageFormValues, PackageRequest } from './types';
import { packageFormSchema } from './packageFormSchema';
import {
  FormInline,
  HidableLink,
  StyledDivider,
  StyledInline,
  StyledStack,
} from './styles';
import { toPackageRequest } from './helpers';
import { isDspEnabled, isPresent } from '../../utils/helpers';
import { ButtonLoadingSpinner } from '../LoadingSpinner';
import { useQuery } from '@tanstack/react-query';
import { getFacebookAdCountsData } from '../../pages/FBAdCount';

const brandOptions = [
  { value: 'RESI', label: 'RESI' },
  { value: 'BUILDER', label: 'BUILDER' },
];

const defaultTargetingOptions = [
  { value: 'postcodes', label: 'Postcode radius' },
  { value: 'state', label: 'State' },
  { value: 'national', label: 'National' },
];

const faceBookBillingEventOptions = [
  { value: 'APP_INSTALLS', label: 'APP_INSTALLS' },
  { value: 'CLICKS', label: 'CLICKS' },
  { value: 'IMPRESSIONS', label: 'IMPRESSIONS' },
  { value: 'LINK_CLICKS', label: 'LINK_CLICKS' },
  { value: 'NONE', label: 'NONE' },
  { value: 'OFFER_CLAIMS', label: 'OFFER_CLAIMS' },
  { value: 'PAGE_LIKES', label: 'PAGE_LIKES' },
  { value: 'POST_ENGAGEMENT', label: 'POST_ENGAGEMENT' },
  { value: 'VIDEO_VIEWS', label: 'VIDEO_VIEWS' },
];

const defaultFormValues: PackageFormValues = {
  name: '',
  neatName: '',
  packageId: '',
  duration: '',
  imps: '',
  campId: '0',
  spendGoal: undefined,
  clickMinGoal: '',
  clickAvgGoal: '',
  clickMaxGoal: '',
  defaultTargeting: 'postcodes',
  applyPlatinumAdditionalTargetingRules: false,
  facebookFlag: false,
  facebookDuration: '7',
  facebookBid: 1,
  facebookDailySpend: 5,
  facebookBidCpm: 3,
  facebookDailySpendCpm: 2.2,
  facebookBillingEvent: 'LINK_CLICKS',
  facebookPauseCpmFlag: false,
  facebookMTFlag: false,
  facebookMTDuration: '7',
  facebookMTBid: 1,
  facebookMTDailySpend: 5,
  facebookMTBillingEvent: 'LINK_CLICKS',
  facebookAccount: '',
  boostFlag: false,
  boostDuration: '1',
  boostDailySpend: 1,
  boostMTFlag: false,
  boostMTDuration: '1',
  boostMTDailySpend: 1,
  campaignBoostFlag: false,
  campaignBoostDuration: '1',
  campaignBoostDailySpend: 1,
  campaignBoostBid: 1,
  soldBoostFlag: false,
  soldBoostDuration: '1',
  soldBoostDailySpend: 1,
  soldBoostBid: 1,
  soldBoostMTFlag: false,
  soldBoostMTDuration: '1',
  soldBoostMTDailySpend: 1,
  soldBoostMTBid: 1,
  brand: 'RESI',
  yahooFlag: false,
  yahooDuration: '6',
  yahooCpcBid: 1,
  yahooDailySpend: 5,
  yahooMultiplier1a: 1,
  yahooMultiplier2a: 1,
  yahooMultiplier1b: 1,
  yahooMultiplier2b: 1,
  yahooMultiplier3a: 1,
  yahooMultiplier3b: 1,
  yahooMultiplierGame: 1,
  yahooCampId: '',
  oldYahooDisplayFlag: false,
  oldYahooDisplayCampId: '',
  yahooDisplayFlag: false,
  yahooDisplayCampId: '',
  yahooDisplayDuration: '1',
  yahooNativeFlag: false,
  yahooNativeCampId: '',
  yahooNativeDuration: '1',
  adformFlag: false,
  adformDuration: '1',
  adformCampId: '',
  adform3pdCampId: '',
  adformCookielessCampId: '',
  adformCookieless3pdCampId: '',
  adformCookieCookielessCampId: '',
  adformCookieCookieless3pdCampId: '',
  adformCampTypeToUse: 0,
  tradedeskFlag: false,
  tradedeskCampId: '',
  tradedesk3pdCampId: '',
  tradedeskCookielessCampId: '',
  tradedeskCookieless3pdCampId: '',
  tradedeskCookieCookielessCampId: '',
  tradedeskCookieCookieless3pdCampId: '',
  tradedeskCampTypeToUse: 0,
  tradedeskDuration: '1',
  isArchived: false,
};

export type PackageFormProps = {
  onSave: (values: PackageRequest) => void;
  defaultValues?: PackageFormValues;
  isLoading: boolean;
};

const facebookBAUAdAccount = { value: '', label: 'BAU Ad Accounts' };

const PackageForm = ({
  onSave,
  defaultValues = defaultFormValues,
  isLoading,
}: PackageFormProps): React.JSX.Element => {
  const { data: facebookAdCountsData } = useQuery({
    queryKey: ['facebookAdCountsData'],
    queryFn: getFacebookAdCountsData,
  });
  const facebookResiAccounts = isPresent(facebookAdCountsData)
    ? [
        facebookBAUAdAccount,
        ...facebookAdCountsData.resiAccounts.map((account) => ({
          value: account.accountId,
          label: `${account.accountName} (Ad count: ${account.adCount})`,
        })),
      ]
    : [facebookBAUAdAccount];

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(packageFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const handleSelectChange =
    (
      name:
        | 'brand'
        | 'facebookBillingEvent'
        | 'facebookMTBillingEvent'
        | 'facebookAccount'
        | 'defaultTargeting',
    ) =>
    (selectedOption?: SelectOption | null) => {
      setValue(name, selectedOption?.value ?? '');
    };

  const [
    facebookFlag,
    facebookMTFlag,
    boostFlag,
    boostMTFlag,
    campaignBoostFlag,
    soldBoostFlag,
    soldBoostMTFlag,
    adformFlag,
    adformCampId,
    adform3pdCampId,
    adformCookielessCampId,
    adformCookieless3pdCampId,
    adformCookieCookielessCampId,
    adformCookieCookieless3pdCampId,
    tradedeskFlag,
    tradedeskCampId,
    yahooFlag,
    oldYahooDisplayCampId,
    yahooCampId,
    adformCampTypeToUse,
    tradedeskCampTypeToUse,
    tradedeskCookielessCampId,
    tradedeskCookieCookielessCampId,
    tradedesk3pdCampId,
    tradedeskCookieless3pdCampId,
    tradedeskCookieCookieless3pdCampId,
  ] = watch([
    'facebookFlag',
    'facebookMTFlag',
    'boostFlag',
    'boostMTFlag',
    'campaignBoostFlag',
    'soldBoostFlag',
    'soldBoostMTFlag',
    'adformFlag',
    'adformCampId',
    'adform3pdCampId',
    'adformCookielessCampId',
    'adformCookieless3pdCampId',
    'adformCookieCookielessCampId',
    'adformCookieCookieless3pdCampId',
    'tradedeskFlag',
    'tradedeskCampId',
    'yahooFlag',
    'oldYahooDisplayCampId',
    'yahooCampId',
    'adformCampTypeToUse',
    'tradedeskCampTypeToUse',
    'tradedeskCookielessCampId',
    'tradedeskCookieCookielessCampId',
    'tradedesk3pdCampId',
    'tradedeskCookieless3pdCampId',
    'tradedeskCookieCookieless3pdCampId',
  ]);

  const defaultBrand = brandOptions.find(
    (b) => b.value === defaultValues.brand,
  );
  const defaultFacebookBillingEvent = faceBookBillingEventOptions.find(
    (b) => b.value === defaultValues.facebookBillingEvent,
  );
  const defaultOptionOfDefaultTargeting = defaultTargetingOptions.find(
    (b) => b.value === defaultValues.defaultTargeting,
  );
  const defaultFacebookAccount = facebookResiAccounts.find(
    (b) => b.value === defaultValues?.facebookAccount,
  );

  return (
    <form
      onSubmit={(e) => {
        void handleSubmit((values) => {
          onSave(toPackageRequest(values));
        })(e);
      }}
    >
      <StyledStack>
        <StyledStack>
          <H2>Package details</H2>
          <StyledStack>
            <FormInline>
              <TextInput
                label="Name"
                {...register('name')}
                errorMessage={errors.name?.message?.toString()}
              />
              <input type="hidden" {...register('isArchived')} value="false" />

              <Select
                label="Brand"
                options={brandOptions}
                defaultSelectedOption={defaultBrand}
                {...register('brand')}
                onSelectedOptionChange={handleSelectChange('brand')}
                errorMessage={errors.brand?.message?.toString()}
              />
            </FormInline>
            <FormInline>
              <TextInput
                label="Package ID"
                {...register('packageId')}
                errorMessage={errors.packageId?.message?.toString()}
              />
              <TextInput
                label="Email Display Package Name"
                {...register('neatName')}
                errorMessage={errors.neatName?.message?.toString()}
              />
            </FormInline>
            <FormInline>
              <TextInput
                label="Duration"
                {...register('duration')}
                errorMessage={errors.duration?.message?.toString()}
              />
              <TextInput
                label="Impressions"
                {...register('imps')}
                errorMessage={errors.imps?.message?.toString()}
              />
            </FormInline>

            <FormInline>
              <TextInput
                label="Spend Goal"
                {...register('spendGoal')}
                errorMessage={errors.spendGoal?.message?.toString()}
              />
              <TextInput
                label="Click Min Goal"
                {...register('clickMinGoal')}
                errorMessage={errors.clickMinGoal?.message?.toString()}
              />
            </FormInline>
            <FormInline>
              <TextInput
                label="Click Avg Goal"
                {...register('clickAvgGoal')}
                errorMessage={errors.clickAvgGoal?.message?.toString()}
              />
              <TextInput
                label="Click Max Goal"
                {...register('clickMaxGoal')}
                errorMessage={errors.clickMaxGoal?.message?.toString()}
              />
            </FormInline>
            <FormInline>
              <Select
                label="Default targeting"
                options={defaultTargetingOptions}
                defaultSelectedOption={defaultOptionOfDefaultTargeting}
                {...register('defaultTargeting')}
                onSelectedOptionChange={handleSelectChange('defaultTargeting')}
                errorMessage={errors.defaultTargeting?.message?.toString()}
              />
              <div />
            </FormInline>
            <FormInline>
              <Inline inset="small" gap="twoExtraSmall" grow={false}>
                <Checkbox
                  label="Apply Platinum additional targeting rules"
                  {...register('applyPlatinumAdditionalTargetingRules')}
                />
                <Tooltip>
                  (1) For listings with price &gt;= 5m, use &apos;5m+&apos;
                  audience for price targeting;
                  <br />
                  (2) For apartments/units with price &gt;= 5m, remove property
                  type from targeting
                </Tooltip>
              </Inline>
              <div />
            </FormInline>
          </StyledStack>
        </StyledStack>
        <StyledDivider />
        <StyledStack>
          <H2>Adform</H2>
          <Checkbox label="Run Adform?" {...register('adformFlag')} />
          {adformFlag && (
            <>
              <FormInline>
                <TextInput
                  label="Adform duration"
                  {...register('adformDuration')}
                  errorMessage={errors.adformDuration?.message?.toString()}
                />
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    value="0"
                    label="Adform template camp. ID"
                    defaultChecked={adformCampTypeToUse === 0}
                  />
                  <TextInput
                    label="Adform template camp. ID field"
                    {...register('adformCampId')}
                    errorMessage={errors.adformCampId?.message?.toString()}
                    hideLabel
                  />

                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adformCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adformCampId) ||
                      !isPresent(adformCampId?.match(/^[1-9]\d*$/))
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    label="Adform 3PD template camp. ID"
                    value="1"
                    defaultChecked={adformCampTypeToUse === 1}
                  />
                  <TextInput
                    label="Adform 3PD template camp. ID field"
                    {...register('adform3pdCampId')}
                    errorMessage={errors.adform3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adform3pdCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adform3pdCampId) ||
                      !isPresent(adform3pdCampId?.match(/^[1-9]\d*$/))
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>

                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    label="Adform Cookieless template camp. ID"
                    value="2"
                    defaultChecked={adformCampTypeToUse === 2}
                  />

                  <TextInput
                    label="Adform Cookieless template camp. ID field"
                    {...register('adformCookielessCampId')}
                    errorMessage={errors.adformCookielessCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adformCookielessCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adformCookielessCampId) ||
                      !isPresent(adformCookielessCampId?.match(/^[1-9]\d*$/))
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    label="Adform Cookieless 3PD template camp. ID"
                    value="3"
                    defaultChecked={adformCampTypeToUse === 3}
                  />
                  <TextInput
                    label="Adform Cookieless 3PD template camp. ID field"
                    {...register('adformCookieless3pdCampId')}
                    errorMessage={errors.adformCookieless3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adformCookieless3pdCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adformCookieless3pdCampId) ||
                      !isPresent(adformCookieless3pdCampId?.match(/^[1-9]\d*$/))
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>

                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    label="Adform Cookie & Cookieless template camp. ID"
                    value="4"
                    defaultChecked={adformCampTypeToUse === 4}
                  />

                  <TextInput
                    label="Adform Cookie & Cookieless template camp. ID field"
                    {...register('adformCookieCookielessCampId')}
                    errorMessage={errors.adformCookieCookielessCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adformCookieCookielessCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adformCookieCookielessCampId) ||
                      !isPresent(
                        adformCookieCookielessCampId?.match(/^[1-9]\d*$/),
                      )
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('adformCampTypeToUse')}
                    label="Adform Cookie & Cookieless 3PD template camp. ID"
                    value="5"
                    defaultChecked={adformCampTypeToUse === 5}
                  />
                  <TextInput
                    label="Adform Cookie & Cookieless 3PD template camp. ID field"
                    {...register('adformCookieCookieless3pdCampId')}
                    errorMessage={errors.adformCookieCookieless3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://flow.adform.com/campaign/${adformCookieCookieless3pdCampId}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.adformCookieCookieless3pdCampId) ||
                      !isPresent(
                        adformCookieCookieless3pdCampId?.match(/^[1-9]\d*$/),
                      )
                    }
                  >
                    View in Adform
                  </HidableLink>
                </Stack>
                <div></div>
              </FormInline>
            </>
          )}
        </StyledStack>
        <StyledDivider />
        <StyledStack>
          <H2>Facebook</H2>
          <Checkbox label="Run Facebook?" {...register('facebookFlag')} />
          {facebookFlag && (
            <>
              <StyledStack>
                <FormInline>
                  <TextInput
                    label="Facebook duration"
                    {...register('facebookDuration')}
                    errorMessage={errors.facebookDuration?.message?.toString()}
                  />
                  <TextInput
                    label="Click bid (CPC)"
                    {...register('facebookBid')}
                    errorMessage={errors.facebookBid?.message?.toString()}
                  />
                </FormInline>
                <FormInline>
                  <TextInput
                    label="Daily spend (CPC)"
                    {...register('facebookDailySpend')}
                    errorMessage={errors.facebookDailySpend?.message?.toString()}
                  />
                  <TextInput
                    label="Impression bid (CPM)"
                    {...register('facebookBidCpm')}
                    errorMessage={errors.facebookBidCpm?.message?.toString()}
                  />
                </FormInline>
                <FormInline>
                  <TextInput
                    label="Daily spend (CPM)"
                    {...register('facebookDailySpendCpm')}
                    errorMessage={errors.facebookDailySpendCpm?.message?.toString()}
                  />
                  <Select
                    label="Billing event"
                    options={faceBookBillingEventOptions}
                    defaultSelectedOption={defaultFacebookBillingEvent}
                    {...register('facebookBillingEvent')}
                    onSelectedOptionChange={handleSelectChange(
                      'facebookBillingEvent',
                    )}
                    errorMessage={errors.facebookBillingEvent?.message?.toString()}
                  />
                </FormInline>
                <FormInline>
                  <Select
                    label={
                      <>
                        Facebook account{' '}
                        <Tooltip>
                          Bookings upload with this package will create Facebook
                          campaign under this account.
                        </Tooltip>
                      </>
                    }
                    options={facebookResiAccounts}
                    defaultSelectedOption={defaultFacebookAccount}
                    {...register('facebookAccount')}
                    onSelectedOptionChange={handleSelectChange(
                      'facebookAccount',
                    )}
                    errorMessage={errors.facebookAccount?.message?.toString()}
                  />
                  <div />
                </FormInline>
                <FormInline>
                  <Checkbox
                    label="Pause CPM Adset?"
                    {...register('facebookPauseCpmFlag')}
                  />
                  <Checkbox
                    label="Skip CPM Adset?"
                    {...register('facebookSkipCpmFlag')}
                  />
                  <Checkbox
                    label="Create MT adset?"
                    {...register('facebookMTFlag')}
                  />
                </FormInline>
                {facebookMTFlag && (
                  <>
                    <FormInline>
                      <TextInput
                        label="MT duration"
                        {...register('facebookMTDuration')}
                        errorMessage={errors.facebookMTDuration?.message?.toString()}
                      />
                      <TextInput
                        label="MT click bid (CPC)"
                        {...register('facebookMTBid')}
                        errorMessage={errors.facebookMTBid?.message?.toString()}
                      />
                    </FormInline>

                    <FormInline>
                      <TextInput
                        label="MT Daily spend (CPC)"
                        {...register('facebookMTDailySpend')}
                        errorMessage={errors.facebookMTDailySpend?.message?.toString()}
                      />
                      <Select
                        label="MT Billing event"
                        options={faceBookBillingEventOptions}
                        defaultSelectedOption={defaultFacebookBillingEvent}
                        {...register('facebookMTBillingEvent')}
                        onSelectedOptionChange={handleSelectChange(
                          'facebookMTBillingEvent',
                        )}
                        errorMessage={errors.facebookMTBillingEvent?.message?.toString()}
                      />
                    </FormInline>
                  </>
                )}
              </StyledStack>
              <StyledStack>
                <H3>Inspection boost</H3>
                <Checkbox
                  label="Run inspection boost?"
                  {...register('boostFlag')}
                />
                {boostFlag && (
                  <>
                    <FormInline>
                      <TextInput
                        label="Inspection boost duration"
                        {...register('boostDuration')}
                        errorMessage={errors.boostDuration?.message?.toString()}
                      />
                      <TextInput
                        label="Inspection boost daily spend (CPC)"
                        {...register('boostDailySpend')}
                        errorMessage={errors.boostDailySpend?.message?.toString()}
                      />
                    </FormInline>
                    <FormInline>
                      <Checkbox
                        label="Create MT adset?"
                        {...register('boostMTFlag')}
                      />
                    </FormInline>
                    {boostMTFlag && (
                      <FormInline>
                        <TextInput
                          label="Inspection boost MT duration"
                          {...register('boostMTDuration')}
                          errorMessage={errors.boostMTDuration?.message?.toString()}
                        />
                        <TextInput
                          label="Inspection boost MT daily spend (CPC)"
                          {...register('boostMTDailySpend')}
                          errorMessage={errors.boostMTDailySpend?.message?.toString()}
                        />
                      </FormInline>
                    )}
                  </>
                )}
              </StyledStack>
              <StyledStack>
                <H3>Campaign boost</H3>
                <Checkbox
                  label="Run campaign boost?"
                  {...register('campaignBoostFlag')}
                />
                {campaignBoostFlag && (
                  <StyledStack>
                    <FormInline>
                      <TextInput
                        label="Campaign boost duration"
                        {...register('campaignBoostDuration')}
                        errorMessage={errors.campaignBoostDuration?.message?.toString()}
                      />
                      <TextInput
                        label="Campaign boost daily spend (CPC)"
                        {...register('campaignBoostDailySpend')}
                        errorMessage={errors.campaignBoostDailySpend?.message?.toString()}
                      />
                    </FormInline>
                    <FormInline>
                      <TextInput
                        label="Campaign boost click bid (CPC)"
                        {...register('campaignBoostBid')}
                        errorMessage={errors.campaignBoostBid?.message?.toString()}
                      />
                      <div />
                    </FormInline>
                  </StyledStack>
                )}
              </StyledStack>
              <StyledStack>
                <H3>Sold boost</H3>
                <Checkbox
                  label="Run sold boost?"
                  {...register('soldBoostFlag')}
                />
                {soldBoostFlag && (
                  <StyledStack>
                    <FormInline>
                      <TextInput
                        label="Sold boost duration"
                        {...register('soldBoostDuration')}
                        errorMessage={errors.soldBoostDuration?.message?.toString()}
                      />
                      <TextInput
                        label="Sold boost daily spend (CPC)"
                        {...register('soldBoostDailySpend')}
                        errorMessage={errors.soldBoostDailySpend?.message?.toString()}
                      />
                    </FormInline>
                    <FormInline>
                      <TextInput
                        label="Sold boost click bid (CPC)"
                        {...register('soldBoostBid')}
                        errorMessage={errors.soldBoostBid?.message?.toString()}
                      />
                      <div />
                    </FormInline>
                    <FormInline>
                      <Checkbox
                        label="Create MT adset?"
                        {...register('soldBoostMTFlag')}
                      />
                    </FormInline>
                    {soldBoostMTFlag && (
                      <>
                        <FormInline>
                          <TextInput
                            label="Sold boost MT duration"
                            {...register('soldBoostMTDuration')}
                            errorMessage={errors.soldBoostMTDuration?.message?.toString()}
                          />
                          <TextInput
                            label="Sold boost MT daily spend (CPC)"
                            {...register('soldBoostMTDailySpend')}
                            errorMessage={errors.soldBoostMTDailySpend?.message?.toString()}
                          />
                        </FormInline>
                        <FormInline>
                          <TextInput
                            label="Sold boost MT click bid (CPC)"
                            {...register('soldBoostMTBid')}
                            errorMessage={errors.soldBoostMTBid?.message?.toString()}
                          />
                          <div />
                        </FormInline>
                      </>
                    )}
                  </StyledStack>
                )}
              </StyledStack>
            </>
          )}
        </StyledStack>
        <StyledDivider />
        <StyledStack>
          <H2>Tradedesk</H2>
          <Checkbox label="Run Tradedesk?" {...register('tradedeskFlag')} />
          {tradedeskFlag && (
            <>
              <FormInline>
                <TextInput
                  label="Tradedesk duration"
                  {...register('tradedeskDuration')}
                  errorMessage={errors.tradedeskDuration?.message?.toString()}
                />
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="0"
                    label="Tradedesk template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 0}
                  />
                  <TextInput
                    label="Tradedesk template camp. ID field"
                    {...register('tradedeskCampId')}
                    errorMessage={errors.tradedeskCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedeskCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedeskCampId) ||
                      !isPresent(tradedeskCampId?.trim().match(/^.{1,10}$/))
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="1"
                    label="Tradedesk Cookieless template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 1}
                  />
                  <TextInput
                    label="Tradedesk Cookieless template camp. ID field"
                    {...register('tradedeskCookielessCampId')}
                    errorMessage={errors.tradedeskCookielessCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedeskCookielessCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedeskCookielessCampId) ||
                      !isPresent(
                        tradedeskCookielessCampId?.trim().match(/^.{1,10}$/),
                      )
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="2"
                    label="Tradedesk Cookie & Cookieless template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 2}
                  />
                  <TextInput
                    label="Tradedesk Cookie & Cookieless template camp. ID field"
                    {...register('tradedeskCookieCookielessCampId')}
                    errorMessage={errors.tradedeskCookieCookielessCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedeskCookieCookielessCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedeskCookieCookielessCampId) ||
                      !isPresent(
                        tradedeskCookieCookielessCampId
                          ?.trim()
                          .match(/^.{1,10}$/),
                      )
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="3"
                    label="Tradedesk 3PD template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 3}
                  />
                  <TextInput
                    label="Tradedesk 3PD template camp. ID"
                    {...register('tradedesk3pdCampId')}
                    errorMessage={errors.tradedesk3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedesk3pdCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedesk3pdCampId) ||
                      !isPresent(tradedesk3pdCampId?.trim().match(/^.{1,10}$/))
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="4"
                    label="Tradedesk Cookieless 3PD template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 4}
                  />
                  <TextInput
                    label="Tradedesk Cookieless 3PD template camp. ID"
                    {...register('tradedeskCookieless3pdCampId')}
                    errorMessage={errors.tradedeskCookieless3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedeskCookieless3pdCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedeskCookieless3pdCampId) ||
                      !isPresent(
                        tradedeskCookieless3pdCampId?.trim().match(/^.{1,10}$/),
                      )
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
              </FormInline>
              <FormInline>
                <Stack gap="twoExtraSmall">
                  <Radio
                    {...register('tradedeskCampTypeToUse')}
                    value="5"
                    label="Tradedesk Cookie Cookieless 3PD template camp. ID"
                    defaultChecked={tradedeskCampTypeToUse === 5}
                  />
                  <TextInput
                    label="Tradedesk Cookie Cookieless 3PD template camp. ID"
                    {...register('tradedeskCookieCookieless3pdCampId')}
                    errorMessage={errors.tradedeskCookieCookieless3pdCampId?.message?.toString()}
                    hideLabel
                  />
                  <HidableLink
                    href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${tradedeskCookieCookieless3pdCampId}/details`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="inline"
                    hidden={
                      isPresent(errors.tradedeskCookieCookieless3pdCampId) ||
                      !isPresent(
                        tradedeskCookieCookieless3pdCampId
                          ?.trim()
                          .match(/^.{1,10}$/),
                      )
                    }
                  >
                    View in Tradedesk
                  </HidableLink>
                </Stack>
                <div></div>
              </FormInline>
            </>
          )}
        </StyledStack>
        <StyledDivider />
        {isDspEnabled('yahoo') && (
          <StyledStack>
            <H2>Yahoo</H2>
            <Checkbox label="Run Yahoo?" {...register('yahooFlag')} />
            {yahooFlag && (
              <StyledStack>
                <StyledStack>
                  <FormInline>
                    <TextInput
                      label="Yahoo duration"
                      {...register('yahooDuration')}
                      errorMessage={errors.yahooDuration?.message?.toString()}
                    />
                    <TextInput
                      label="Yahoo daily spend (CPC)"
                      {...register('yahooDailySpend')}
                      errorMessage={errors.yahooDailySpend?.message?.toString()}
                    />
                  </FormInline>
                  <FormInline>
                    <TextInput
                      label="Yahoo bid (CPC)"
                      {...register('yahooCpcBid')}
                      errorMessage={errors.yahooCpcBid?.message?.toString()}
                    />
                    <div />
                  </FormInline>
                  <FormInline>
                    <TextInput
                      label="Yahoo bid multiplier 1A"
                      {...register('yahooMultiplier1a')}
                      errorMessage={errors.yahooMultiplier1a?.message?.toString()}
                    />
                    <TextInput
                      label="Yahoo bid multiplier 1B"
                      {...register('yahooMultiplier1b')}
                      errorMessage={errors.yahooMultiplier1b?.message?.toString()}
                    />
                  </FormInline>
                  <FormInline>
                    <TextInput
                      label="Yahoo bid multiplier 2A"
                      {...register('yahooMultiplier2a')}
                      errorMessage={errors.yahooMultiplier2a?.message?.toString()}
                    />
                    <TextInput
                      label="Yahoo bid multiplier 2B"
                      {...register('yahooMultiplier2b')}
                      errorMessage={errors.yahooMultiplier2b?.message?.toString()}
                    />
                  </FormInline>
                  <FormInline>
                    <TextInput
                      label="Yahoo bid multiplier 3A"
                      {...register('yahooMultiplier3a')}
                      errorMessage={errors.yahooMultiplier3a?.message?.toString()}
                    />
                    <TextInput
                      label="Yahoo bid multiplier 3B"
                      {...register('yahooMultiplier3b')}
                      errorMessage={errors.yahooMultiplier3b?.message?.toString()}
                    />
                  </FormInline>
                  <FormInline>
                    <TextInput
                      label="Yahoo bid multiplier GAME"
                      {...register('yahooMultiplierGame')}
                      errorMessage={errors.yahooMultiplierGame?.message?.toString()}
                    />
                    <Stack gap="twoExtraSmall">
                      <TextInput
                        label="Yahoo template camp. ID"
                        {...register('yahooCampId')}
                        errorMessage={errors.yahooCampId?.message?.toString()}
                      />
                      <HidableLink
                        href={`https://sso.admanagerplus.yahoo.com/app/campaigns/${yahooCampId}/lines`}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="inline"
                        hidden={
                          isPresent(errors.yahooCampId) ||
                          !isPresent(yahooCampId?.match(/^[1-9]\d*$/))
                        }
                      >
                        View in Yahoo
                      </HidableLink>
                    </Stack>
                  </FormInline>
                  <FormInline>
                    <Stack gap="twoExtraSmall">
                      <TextInput
                        label="Yahoo display template camp. ID"
                        {...register('oldYahooDisplayCampId')}
                        errorMessage={errors.oldYahooDisplayCampId?.message?.toString()}
                      />
                      <Checkbox
                        label="Use display template?"
                        {...register('oldYahooDisplayFlag')}
                      />
                      <HidableLink
                        href={`https://sso.admanagerplus.yahoo.com/app/campaigns/${oldYahooDisplayCampId}/lines`}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="inline"
                        hidden={
                          isPresent(errors.oldYahooDisplayCampId) ||
                          !isPresent(oldYahooDisplayCampId?.match(/^[1-9]\d*$/))
                        }
                      >
                        View in Yahoo
                      </HidableLink>
                    </Stack>
                    <div />
                  </FormInline>
                </StyledStack>
              </StyledStack>
            )}
          </StyledStack>
        )}
        {isDspEnabled('yahoo') && <StyledDivider />}
        <StyledInline justifyContent="end">
          <Link variant="button-outline" as={RouterLink} to="/packages">
            Cancel
          </Link>
          <Button
            variant="primary"
            type="submit"
            disabled={isLoading}
            iconPlacement="right"
            icon={isLoading ? <ButtonLoadingSpinner /> : undefined}
          >
            {isLoading ? 'Loading' : 'Save'}
          </Button>
        </StyledInline>
      </StyledStack>
    </form>
  );
};

export default PackageForm;
