import { Link } from '@rea-group/construct-kit-core';
import { Link as RouterLink } from 'react-router-dom';
import { RecognizedBooking } from '../../pages/BookingData';
import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

interface RecognizedBookingsPanelProps {
  data: RecognizedBooking[];
}

const RecognizedBookingsPanel = ({
  data,
}: RecognizedBookingsPanelProps): React.JSX.Element => {
  return (
    <BookingDataPanel heading="Bookings for the listing ID">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Start date</TableHeaderColumn>
          <TableHeaderColumn>Created date</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {data.length === 0 ? (
            <EmptyTableBody />
          ) : (
            data.map(({ bookingId, startDate, createdDate }) => (
              <TableRow key={bookingId}>
                <TableContentColumn>
                  <Link
                    as={RouterLink}
                    to={`/booking-data?id=${bookingId}`}
                    variant="primary"
                  >
                    {bookingId}
                  </Link>
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(startDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(createdDate)}
                </TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </BookingDataPanel>
  );
};

export default RecognizedBookingsPanel;
