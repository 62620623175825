import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import {
  renderCampaignMetrics,
  renderCampaignMetricsHeaders,
} from './renderCampaignMetrics';

export interface AdformReportingCampaignData {
  id: number;
  campaignId: number;
  campaignName: string;
  lineItemId: number;
  lineItemName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number;
  clicks: number;
  spend: number;
  budget: number | null;
}

export interface BookingDataAfDailyCampaignReportingPanelProps {
  data: AdformReportingCampaignData[];
}

const BookingDataAfDailyCampaignReportingPanel = ({
  data,
}: BookingDataAfDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="AF Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Line ID</TableHeaderColumn>
        <TableHeaderColumn>Line name</TableHeaderColumn>
        {renderCampaignMetricsHeaders()}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((row) => (
            <TableRow key={row.id}>
              <TableContentColumn>{row.campaignId}</TableContentColumn>
              <TableContentColumn>{row.campaignName}</TableContentColumn>
              <TableContentColumn>{row.lineItemId}</TableContentColumn>
              <TableContentColumn>{row.lineItemName}</TableContentColumn>
              {renderCampaignMetrics(row)}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataAfDailyCampaignReportingPanel;
