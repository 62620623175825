import { Link } from '@rea-group/construct-kit-core';
import { ValidationWarningBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';

interface DreamHomeWarningModalProps {
  bookings: ValidationWarningBooking[];
}

const DreamHomeWarningModal = ({
  bookings,
}: DreamHomeWarningModalProps): React.JSX.Element => {
  return (
    <BookingsModal heading="Dream Homes">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Impressions</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(({ rowNumber, bookingId, listingId, impressions }) => (
              <TableRow key={rowNumber}>
                <TableContentColumn>{bookingId}</TableContentColumn>
                <TableContentColumn>
                  <Link
                    href={`https://www.realestate.com.au/${listingId}`}
                    target="_blank"
                  >
                    {listingId}
                  </Link>
                </TableContentColumn>
                <TableContentColumn>
                  {impressions.toLocaleString()}
                </TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default DreamHomeWarningModal;
