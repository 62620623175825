import TotalCampaignReportingPanel from './TotalCampaignReportingPanel';

export interface AdformReportingCampaignFlatData {
  id: number;
  campaignId: number;
  campaignName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number;
  clicks: number;
  spend: number;
  budget: number | null;
}

export interface BookingDataAfTotalCampaignReportingPanelProps {
  data: AdformReportingCampaignFlatData[];
}

const BookingDataAfTotalCampaignReportingPanel = ({
  data,
}: BookingDataAfTotalCampaignReportingPanelProps): React.JSX.Element => (
  <TotalCampaignReportingPanel
    heading="AF Total Campaign Reporting"
    data={data}
  />
);

export default BookingDataAfTotalCampaignReportingPanel;
