import { H1, Stack, H4 } from '@rea-group/construct-kit-core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import { useState } from 'react';
import { fetchJson, putVoid } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import { isPresent } from '../utils/helpers';
import { Job, JobsTable } from '../components/JobsTable';
import { DateRange } from 'react-day-picker/src/types';
import RangeDatePickerWithPresets from '../components/DatePicker/RangeDatePickerWithPresets';
import styled from 'styled-components';

const RangeDatePickerContainer = styled.div`
  width: 40%;
`;

const resolveBookingWarnings = (bookingId: string): Promise<void> =>
  putVoid(`/bookings/resolve-warnings/${bookingId}`);

interface JobHistoryResponse {
  jobs: Job[];
}

const buildQueryParams = (dateRange: DateRange): string => {
  const urlParams = new URLSearchParams();

  if (isPresent(dateRange.from)) {
    urlParams.append(
      'startDate',
      formatISO(dateRange.from, { representation: 'date' }),
    );
  }

  if (isPresent(dateRange.to)) {
    urlParams.append(
      'endDate',
      formatISO(dateRange.to, { representation: 'date' }),
    );
  }

  return urlParams.toString();
};

const JobHistory = (): React.JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: new Date(),
    to: new Date(),
  });

  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['jobsHistoryData', dateRange],
    queryFn: () => {
      return fetchJson<JobHistoryResponse>(
        `/jobs/history?${buildQueryParams(dateRange)}`,
      );
    },
  });
  const queryClient = useQueryClient();

  const resolveBookingWarningsMutation = useMutation({
    mutationFn: resolveBookingWarnings,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['jobsHistoryData'] });
    },
  });

  const { jobs } = data ?? {};

  return (
    <Stack gap="medium">
      <H1>Job History</H1>
      <Stack gap="twoExtraSmall">
        <H4>Jobs Created</H4>
        <RangeDatePickerContainer>
          <RangeDatePickerWithPresets
            placeHolders={{ from: 'Start date', to: 'End date' }}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </RangeDatePickerContainer>
      </Stack>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {jobs && (
        <JobsTable
          jobs={jobs}
          showWarnings={true}
          showProcessDate={true}
          showStartDate={true}
          onResolveButtonClick={resolveBookingWarningsMutation.mutate}
        />
      )}
    </Stack>
  );
};

export default JobHistory;
