import { PackageResponse } from '../../pages/packages/types';
import { isPresent } from '../../utils/helpers';
import { PackageFormValues, PackageRequest } from './types';

const nullIfFlagNotSet = <V>(
  flag: boolean,
  value: V | null | undefined,
): V | null => (flag && isPresent(value) ? value : null);

const safeParseInt = (string: string | undefined): number | null =>
  isPresent(string) ? parseInt(string) : null;

export const toPackageRequest = (values: PackageFormValues): PackageRequest => {
  return {
    name: values.name,
    neatName: values.neatName ?? null,
    packageId: values.packageId ?? null,
    brand: values.brand,
    duration: parseInt(values.duration),
    imps: parseInt(values.imps),
    campId: parseInt(values.campId ?? '0'),
    spendGoal: values.spendGoal ?? null,
    clickMinGoal: safeParseInt(values.clickMinGoal),
    clickAvgGoal: safeParseInt(values.clickAvgGoal),
    clickMaxGoal: safeParseInt(values.clickMaxGoal),
    defaultTargeting: values.defaultTargeting,
    applyPlatinumAdditionalTargetingRules:
      values.applyPlatinumAdditionalTargetingRules,
    facebookFlag: values.facebookFlag,
    facebookDuration: nullIfFlagNotSet(
      values.facebookFlag,
      safeParseInt(values.facebookDuration),
    ),
    facebookBid: nullIfFlagNotSet(values.facebookFlag, values.facebookBid),
    facebookDailySpend: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookDailySpend,
    ),
    facebookBidCpm: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookBidCpm,
    ),
    facebookDailySpendCpm: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookDailySpendCpm,
    ),
    facebookBillingEvent: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookBillingEvent,
    ),
    facebookPauseCpmFlag: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookPauseCpmFlag,
    ),
    facebookSkipCpmFlag: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookSkipCpmFlag,
    ),
    facebookMTFlag: nullIfFlagNotSet(
      values.facebookFlag,
      values.facebookMTFlag,
    ),
    facebookMTDuration: nullIfFlagNotSet(
      values.facebookMTFlag ?? false,
      safeParseInt(values.facebookMTDuration),
    ),
    facebookMTBid: nullIfFlagNotSet(
      values.facebookMTFlag ?? false,
      values.facebookMTBid,
    ),
    facebookMTDailySpend: nullIfFlagNotSet(
      values.facebookMTFlag ?? false,
      values.facebookMTDailySpend,
    ),
    facebookMTBillingEvent: nullIfFlagNotSet(
      values.facebookMTFlag ?? false,
      values.facebookMTBillingEvent,
    ),
    facebookAccount: nullIfFlagNotSet(
      values.facebookFlag ?? false,
      values.facebookAccount,
    ),
    boostFlag: values.boostFlag,
    boostDuration: nullIfFlagNotSet(
      values.boostFlag,
      safeParseInt(values.boostDuration),
    ),
    boostDailySpend: nullIfFlagNotSet(values.boostFlag, values.boostDailySpend),

    boostMTFlag: nullIfFlagNotSet(values.boostFlag, values.boostMTFlag),
    boostMTDuration: nullIfFlagNotSet(
      values.boostMTFlag ?? false,
      safeParseInt(values.boostMTDuration),
    ),
    boostMTDailySpend: nullIfFlagNotSet(
      values.boostMTFlag ?? false,
      values.boostMTDailySpend,
    ),

    campaignBoostFlag: values.campaignBoostFlag,
    campaignBoostDuration: nullIfFlagNotSet(
      values.campaignBoostFlag,
      safeParseInt(values.campaignBoostDuration),
    ),
    campaignBoostDailySpend: nullIfFlagNotSet(
      values.campaignBoostFlag,
      values.campaignBoostDailySpend,
    ),
    campaignBoostBid: nullIfFlagNotSet(
      values.campaignBoostFlag,
      values.campaignBoostBid,
    ),

    soldBoostFlag: values.soldBoostFlag,

    soldBoostDuration: nullIfFlagNotSet(
      values.soldBoostFlag,
      safeParseInt(values.soldBoostDuration),
    ),
    soldBoostDailySpend: nullIfFlagNotSet(
      values.soldBoostFlag,
      values.soldBoostDailySpend,
    ),
    soldBoostBid: nullIfFlagNotSet(values.soldBoostFlag, values.soldBoostBid),
    soldBoostMTFlag: nullIfFlagNotSet(
      values.soldBoostFlag,
      values.soldBoostMTFlag,
    ),
    soldBoostMTDuration: nullIfFlagNotSet(
      values.soldBoostMTFlag ?? false,
      safeParseInt(values.soldBoostMTDuration),
    ),
    soldBoostMTDailySpend: nullIfFlagNotSet(
      values.soldBoostMTFlag ?? false,
      values.soldBoostMTDailySpend,
    ),
    soldBoostMTBid: nullIfFlagNotSet(
      values.soldBoostMTFlag ?? false,
      values.soldBoostMTBid,
    ),
    yahooFlag: values.yahooFlag,
    yahooDuration: nullIfFlagNotSet(
      values.yahooFlag,
      safeParseInt(values.yahooDuration),
    ),
    yahooCpcBid: nullIfFlagNotSet(values.yahooFlag, values.yahooCpcBid),
    yahooDailySpend: nullIfFlagNotSet(values.yahooFlag, values.yahooDailySpend),
    yahooMultiplier1a: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier1a,
    ),
    yahooMultiplier1b: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier1b,
    ),
    yahooMultiplier2a: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier2a,
    ),
    yahooMultiplier2b: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier2b,
    ),
    yahooMultiplier3a: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier3a,
    ),
    yahooMultiplier3b: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplier3b,
    ),
    yahooMultiplierGame: nullIfFlagNotSet(
      values.yahooFlag,
      values.yahooMultiplierGame,
    ),
    yahooCampId: nullIfFlagNotSet(
      values.yahooFlag,
      safeParseInt(values.yahooCampId),
    ),
    oldYahooDisplayFlag: values.oldYahooDisplayFlag ?? null,
    oldYahooDisplayCampId: nullIfFlagNotSet(
      values.yahooFlag,
      safeParseInt(values.oldYahooDisplayCampId),
    ),
    yahooDisplayFlag: values.yahooDisplayFlag,
    yahooDisplayDuration: nullIfFlagNotSet(
      values.yahooDisplayFlag,
      safeParseInt(values.yahooDisplayDuration),
    ),
    yahooDisplayCampId: nullIfFlagNotSet(
      values.yahooDisplayFlag,
      safeParseInt(values.yahooDisplayCampId),
    ),
    yahooNativeFlag: values.yahooNativeFlag,
    yahooNativeDuration: nullIfFlagNotSet(
      values.yahooNativeFlag,
      safeParseInt(values.yahooNativeDuration),
    ),
    yahooNativeCampId: nullIfFlagNotSet(
      values.yahooNativeFlag,
      safeParseInt(values.yahooNativeCampId),
    ),
    adformFlag: values.adformFlag,
    adformDuration: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformDuration),
    ),
    adformCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformCampId),
    ),
    adform3pdCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adform3pdCampId),
    ),
    adformCookielessCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformCookielessCampId),
    ),
    adformCookieless3pdCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformCookieless3pdCampId),
    ),

    adformCookieCookielessCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformCookieCookielessCampId),
    ),
    adformCookieCookieless3pdCampId: nullIfFlagNotSet(
      values.adformFlag,
      safeParseInt(values.adformCookieCookieless3pdCampId),
    ),
    adformCampTypeToUse: values.adformCampTypeToUse ?? 0,
    tradedeskFlag: values.tradedeskFlag,
    tradedeskDuration: nullIfFlagNotSet(
      values.tradedeskFlag,
      safeParseInt(values.tradedeskDuration),
    ),
    tradedeskCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedeskCampId,
    ),
    tradedeskCampTypeToUse: values.tradedeskCampTypeToUse ?? 0,
    tradedeskCookielessCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedeskCookielessCampId,
    ),
    tradedeskCookieCookielessCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedeskCookieCookielessCampId,
    ),
    tradedesk3pdCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedesk3pdCampId,
    ),
    tradedeskCookieless3pdCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedeskCookieless3pdCampId,
    ),
    tradedeskCookieCookieless3pdCampId: nullIfFlagNotSet(
      values.tradedeskFlag,
      values.tradedeskCookieCookieless3pdCampId,
    ),
    isArchived: values.isArchived,
  };
};

export const fromPackageResponse = (
  currentPackage: PackageResponse,
): PackageFormValues => {
  return {
    name: currentPackage.name,
    neatName: currentPackage.neatName,
    packageId: currentPackage.packageId ?? undefined,
    brand: currentPackage.brand,
    duration: currentPackage.duration.toString(),
    imps: currentPackage.imps.toString(),
    campId: currentPackage.campId.toString(),
    spendGoal: currentPackage.spendGoal ?? undefined,
    clickMinGoal: currentPackage.clickMinGoal?.toString(),
    clickAvgGoal: currentPackage.clickAvgGoal?.toString(),
    clickMaxGoal: currentPackage.clickMaxGoal?.toString(),
    defaultTargeting: currentPackage.defaultTargeting,
    applyPlatinumAdditionalTargetingRules:
      currentPackage.applyPlatinumAdditionalTargetingRules,
    facebookFlag: currentPackage.facebookFlag,
    facebookDuration: currentPackage.facebookDuration?.toString(),
    facebookBid: currentPackage.facebookBid ?? undefined,
    facebookDailySpend: currentPackage.facebookDailySpend ?? undefined,
    facebookBidCpm: currentPackage.facebookBidCpm ?? undefined,
    facebookDailySpendCpm: currentPackage.facebookDailySpendCpm ?? undefined,
    facebookBillingEvent: currentPackage.facebookBillingEvent ?? undefined,
    facebookPauseCpmFlag: currentPackage.facebookPauseCpmFlag ?? undefined,
    facebookSkipCpmFlag: currentPackage.facebookSkipCpmFlag ?? undefined,
    facebookMTFlag: currentPackage.facebookMTFlag ?? undefined,
    facebookMTDuration: currentPackage.facebookMTDuration?.toString(),
    facebookMTBid: currentPackage.facebookMTBid ?? undefined,
    facebookMTDailySpend: currentPackage.facebookMTDailySpend ?? undefined,
    facebookMTBillingEvent: currentPackage.facebookMTBillingEvent ?? undefined,
    facebookAccount: currentPackage.facebookAccount ?? '',
    boostFlag: currentPackage.boostFlag,
    boostDuration: currentPackage.boostDuration?.toString(),
    boostDailySpend: currentPackage.boostDailySpend ?? undefined,
    boostMTFlag: currentPackage.boostMTFlag ?? undefined,
    boostMTDuration: currentPackage.boostMTDuration?.toString(),
    boostMTDailySpend: currentPackage.boostMTDailySpend ?? undefined,
    campaignBoostFlag: currentPackage.campaignBoostFlag,
    campaignBoostDuration: currentPackage.campaignBoostDuration?.toString(),
    campaignBoostDailySpend:
      currentPackage.campaignBoostDailySpend ?? undefined,
    campaignBoostBid: currentPackage.campaignBoostBid ?? undefined,
    soldBoostFlag: currentPackage.soldBoostFlag,
    soldBoostDuration: currentPackage.soldBoostDuration?.toString(),
    soldBoostDailySpend: currentPackage.soldBoostDailySpend ?? undefined,
    soldBoostBid: currentPackage.soldBoostBid ?? undefined,
    soldBoostMTFlag: currentPackage.soldBoostMTFlag ?? undefined,
    soldBoostMTDuration: currentPackage.soldBoostMTDuration?.toString(),
    soldBoostMTDailySpend: currentPackage.soldBoostMTDailySpend ?? undefined,
    soldBoostMTBid: currentPackage.soldBoostMTBid ?? undefined,
    yahooFlag: currentPackage.yahooFlag,
    yahooDuration: currentPackage.yahooDuration?.toString(),
    yahooCpcBid: currentPackage.yahooCpcBid ?? undefined,
    yahooDailySpend: currentPackage.yahooDailySpend ?? undefined,
    yahooMultiplier1a: currentPackage.yahooMultiplier1a ?? undefined,
    yahooMultiplier1b: currentPackage.yahooMultiplier1b ?? undefined,
    yahooMultiplier2a: currentPackage.yahooMultiplier2a ?? undefined,
    yahooMultiplier2b: currentPackage.yahooMultiplier2b ?? undefined,
    yahooMultiplier3a: currentPackage.yahooMultiplier3a ?? undefined,
    yahooMultiplier3b: currentPackage.yahooMultiplier3b ?? undefined,
    yahooMultiplierGame: currentPackage.yahooMultiplierGame ?? undefined,
    yahooCampId: currentPackage.yahooCampId?.toString(),
    oldYahooDisplayFlag: currentPackage.oldYahooDisplayFlag ?? undefined,
    oldYahooDisplayCampId: currentPackage.oldYahooDisplayCampId?.toString(),
    yahooDisplayFlag: currentPackage.yahooDisplayFlag,
    yahooDisplayDuration: currentPackage.yahooDisplayDuration?.toString(),
    yahooDisplayCampId: currentPackage.yahooDisplayCampId?.toString(),
    yahooNativeFlag: currentPackage.yahooNativeFlag,
    yahooNativeDuration: currentPackage.yahooNativeDuration?.toString(),
    yahooNativeCampId: currentPackage.yahooNativeCampId?.toString(),
    adformFlag: currentPackage.adformFlag,
    adformDuration: currentPackage.adformDuration?.toString(),
    adformCampId: currentPackage.adformCampId?.toString(),
    adform3pdCampId: currentPackage.adform3pdCampId?.toString(),
    adformCookielessCampId: currentPackage.adformCookielessCampId?.toString(),
    adformCookieless3pdCampId:
      currentPackage.adformCookieless3pdCampId?.toString(),
    adformCookieCookielessCampId:
      currentPackage.adformCookieCookielessCampId?.toString(),
    adformCookieCookieless3pdCampId:
      currentPackage.adformCookieCookieless3pdCampId?.toString(),
    adformCampTypeToUse: currentPackage.adformCampTypeToUse,
    tradedeskFlag: currentPackage.tradedeskFlag,
    tradedeskDuration: currentPackage.tradedeskDuration?.toString(),
    tradedeskCampId: currentPackage.tradedeskCampId?.toString(),
    tradedeskCampTypeToUse: currentPackage.tradedeskCampTypeToUse,
    tradedeskCookielessCampId:
      currentPackage.tradedeskCookielessCampId?.toString(),
    tradedeskCookieCookielessCampId:
      currentPackage.tradedeskCookieCookielessCampId?.toString(),
    tradedesk3pdCampId: currentPackage.tradedesk3pdCampId?.toString(),
    tradedeskCookieless3pdCampId:
      currentPackage.tradedeskCookieless3pdCampId?.toString(),
    tradedeskCookieCookieless3pdCampId:
      currentPackage.tradedeskCookieCookieless3pdCampId?.toString(),
    isArchived: currentPackage.isArchived,
  };
};
