import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface TaboolaReportingCampaignData {
  id: number;
  campaignId: number;
  campaignName: string;
  campaignGroupId: number;
  campaignGroupName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number | null;
  clicks: number | null;
  spend: number | null;
  budget: number | null;
}

export interface BookingDataTbDailyCampaignReportingPanelProps {
  data: TaboolaReportingCampaignData[];
}

const BookingDataTbDailyCampaignReportingPanel = ({
  data,
}: BookingDataTbDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="TB Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Campaign Group ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign Group name</TableHeaderColumn>
        <TableHeaderColumn>Report date</TableHeaderColumn>
        <TableHeaderColumn>Start date</TableHeaderColumn>
        <TableHeaderColumn>End date</TableHeaderColumn>
        <TableHeaderColumn>Impressions</TableHeaderColumn>
        <TableHeaderColumn>Clicks</TableHeaderColumn>
        <TableHeaderColumn>Spend</TableHeaderColumn>
        <TableHeaderColumn>Budget</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({
              id,
              campaignId,
              campaignName,
              campaignGroupId,
              campaignGroupName,
              reportDate,
              startDate,
              endDate,
              impressions,
              clicks,
              spend,
              budget,
            }) => (
              <TableRow key={id}>
                <TableContentColumn>{campaignId}</TableContentColumn>
                <TableContentColumn>{campaignName}</TableContentColumn>
                <TableContentColumn>{campaignGroupId}</TableContentColumn>
                <TableContentColumn>{campaignGroupName}</TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(reportDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(startDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(endDate)}
                </TableContentColumn>
                <TableContentColumn>{impressions}</TableContentColumn>
                <TableContentColumn>{clicks}</TableContentColumn>
                <TableContentColumn>{spend}</TableContentColumn>
                <TableContentColumn>{budget}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataTbDailyCampaignReportingPanel;
