import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface YahooReportingCampaignData {
  id: number;
  campId: number;
  campName: string;
  lineId: number;
  lineName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number | null;
  clicks: number | null;
  spend: number | null;
  budget: number | null;
}

export interface BookingDataYhDailyCampaignReportingPanelProps {
  data: YahooReportingCampaignData[];
}

const BookingDataYhDailyCampaignReportingPanel = ({
  data,
}: BookingDataYhDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="YH Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Line ID</TableHeaderColumn>
        <TableHeaderColumn>Line name</TableHeaderColumn>
        <TableHeaderColumn>Report date</TableHeaderColumn>
        <TableHeaderColumn>Start date</TableHeaderColumn>
        <TableHeaderColumn>End date</TableHeaderColumn>
        <TableHeaderColumn>Impressions</TableHeaderColumn>
        <TableHeaderColumn>Clicks</TableHeaderColumn>
        <TableHeaderColumn>Spend</TableHeaderColumn>
        <TableHeaderColumn>Budget</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({
              id,
              campId,
              campName,
              lineId,
              lineName,
              reportDate,
              startDate,
              endDate,
              impressions,
              clicks,
              spend,
              budget,
            }) => (
              <TableRow key={id}>
                <TableContentColumn>{campId}</TableContentColumn>
                <TableContentColumn>{campName}</TableContentColumn>
                <TableContentColumn>{lineId}</TableContentColumn>
                <TableContentColumn>{lineName}</TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(reportDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(startDate)}
                </TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(endDate)}
                </TableContentColumn>
                <TableContentColumn>{impressions}</TableContentColumn>
                <TableContentColumn>{clicks}</TableContentColumn>
                <TableContentColumn>{spend}</TableContentColumn>
                <TableContentColumn>{budget}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataYhDailyCampaignReportingPanel;
