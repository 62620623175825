import { formatAustralianDate } from '../../utils/formatDate';
import { TableContentColumn, TableHeaderColumn } from '../Table/Table';

interface ReportingCampaignMetrics {
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number | null;
  clicks: number | null;
  spend: number | null;
  budget: number | null;
}

export const renderCampaignMetrics = ({
  reportDate,
  startDate,
  endDate,
  impressions,
  clicks,
  spend,
  budget,
}: ReportingCampaignMetrics): React.JSX.Element => (
  <>
    <TableContentColumn>{formatAustralianDate(reportDate)}</TableContentColumn>
    <TableContentColumn>{formatAustralianDate(startDate)}</TableContentColumn>
    <TableContentColumn>{formatAustralianDate(endDate)}</TableContentColumn>
    <TableContentColumn>{impressions}</TableContentColumn>
    <TableContentColumn>{clicks}</TableContentColumn>
    <TableContentColumn>{spend}</TableContentColumn>
    <TableContentColumn>{budget}</TableContentColumn>
  </>
);

export const renderCampaignMetricsHeaders = (): React.JSX.Element => (
  <>
    <TableHeaderColumn>Report date</TableHeaderColumn>
    <TableHeaderColumn>Start date</TableHeaderColumn>
    <TableHeaderColumn>End date</TableHeaderColumn>
    <TableHeaderColumn>Impressions</TableHeaderColumn>
    <TableHeaderColumn>Clicks</TableHeaderColumn>
    <TableHeaderColumn>Spend</TableHeaderColumn>
    <TableHeaderColumn>Budget</TableHeaderColumn>
  </>
);
