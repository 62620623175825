import { Link } from '@rea-group/construct-kit-core';
import { ValidationErrorBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';

interface MatchingPostcodeRadiusTargetErrorModalProps {
  bookings: ValidationErrorBooking[];
}

const MatchingPostcodeRadiusTargetErrorModal = ({
  bookings,
}: MatchingPostcodeRadiusTargetErrorModalProps): React.JSX.Element => {
  return (
    <BookingsModal heading="Unsuccessful Postcode Radius Target Match">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Listing Postcode</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(
              ({ rowNumber, bookingId, listingId, listingPostcode }) => (
                <TableRow key={rowNumber}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>{listingPostcode}</TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default MatchingPostcodeRadiusTargetErrorModal;
