import {
  Button,
  Card,
  Divider,
  H1,
  H3,
  Inline,
  P,
  SearchLg,
  Stack,
  TextInput,
} from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import BookingDataDetails from '../components/bookingData/BookingDataDetails';
import RecognizedBookingsPanel from '../components/bookingData/RecognizedBookingsPanel';
import { isPresent } from '../utils/helpers';
import { PageLoadingSpinner } from '../components/LoadingSpinner';

const SearchBoxContainer = styled.div`
  width: 40%;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const SearchButton = styled(Button)`
  flex-grow: 0;
`;

const TipPanel = styled(Card)`
  min-height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPanelStack = styled(Stack)`
  width: max-content;
`;

const LISTING_ID_REGEX = /^\d{9}$/;

export interface RecognizedBooking {
  bookingId: string;
  startDate: string;
  createdDate: string;
}

interface GetRecognizedBookingsResponse {
  bookings: RecognizedBooking[];
}

const BookingData = (): React.JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const idParam = searchParams.get('id');

  const [searchText, setSearchText] = useState(idParam ?? '');
  const idType =
    isPresent(idParam) && LISTING_ID_REGEX.test(idParam)
      ? 'listing'
      : 'booking';

  useEffect(() => {
    setSearchText(idParam ?? '');
  }, [idParam]);

  const bookingDataByListingIdQuery = useQuery({
    queryKey: ['bookingDataByListingId', idParam],
    queryFn: () => {
      if (!isPresent(idParam)) {
        return;
      }

      return fetchJson<GetRecognizedBookingsResponse>(
        `/recognized-bookings?listingId=${idParam}`,
      );
    },
    enabled: isPresent(idParam) && idParam !== '' && idType === 'listing',
  });

  const handleSearch = (): void => {
    setSearchParams({ id: searchText });
  };

  return (
    <Stack gap="extraLarge">
      <Stack gap="medium">
        <H1>Booking Data</H1>
        <SearchBoxContainer>
          <Inline gap="medium" alignItems="end">
            <TextInput
              label="Search for your booking or listing ID"
              value={searchText}
              type="search"
              iconLeft={<SearchLg />}
              placeholder="Booking or listing ID"
              onChange={(e) => setSearchText(e.target.value.trim())}
            />
            <SearchButton onClick={handleSearch}>Show results</SearchButton>
          </Inline>
        </SearchBoxContainer>
        <StyledDivider />
      </Stack>
      {bookingDataByListingIdQuery.isLoading && <PageLoadingSpinner />}
      {bookingDataByListingIdQuery.isError && (
        <ErrorAlert error={bookingDataByListingIdQuery.error} />
      )}
      {((!isPresent(idParam) ||
        (idType === 'listing' &&
          (bookingDataByListingIdQuery.data?.bookings ?? []).length === 0)) && (
        <TipPanel>
          <Stack gap="medium" alignItems="center">
            <H3>There is no booking information.</H3>
            <P>Please enter an ID and click search to see results.</P>
          </Stack>
        </TipPanel>
      )) || (
        <StyledPanelStack>
          {bookingDataByListingIdQuery.data && (
            <RecognizedBookingsPanel
              data={bookingDataByListingIdQuery.data.bookings}
            />
          )}
          {isPresent(idParam) && idType === 'booking' && (
            <BookingDataDetails bookingId={idParam} />
          )}
        </StyledPanelStack>
      )}
    </Stack>
  );
};

export default BookingData;
