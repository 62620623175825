import { formatDateTime } from '../../utils/formatDate';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface BookingEmailData {
  id: number;
  bookingId: string;
  status: string;
  emailSent: string | null;
  note: string | null;
}

export interface BookingDataGoLiveEmailsPanelProps {
  data: BookingEmailData[];
}

const BookingDataGoLiveEmailsPanel = ({
  data,
}: BookingDataGoLiveEmailsPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="Go Live Emails">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Booking ID</TableHeaderColumn>
        <TableHeaderColumn>Status</TableHeaderColumn>
        <TableHeaderColumn>Email sent</TableHeaderColumn>
        <TableHeaderColumn>Note</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(({ id, bookingId, status, emailSent, note }) => (
            <TableRow key={id}>
              <TableContentColumn>{bookingId}</TableContentColumn>
              <TableContentColumn>
                <Status status={status} />
              </TableContentColumn>
              <TableContentColumn>
                {formatDateTime(emailSent)}
              </TableContentColumn>
              <TableContentColumn>{note}</TableContentColumn>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataGoLiveEmailsPanel;
