import {
  ArrowDownTwoMd,
  ArrowUpTwoMd,
  Badge,
  Button,
  DeleteMd,
  EyeHideMd,
  EyeMd,
  H1,
  Inline,
  Link,
  ListboxOption,
  Menu,
  MenuItem,
  MultiSelect,
  paletteHelper,
  Select,
  SelectOption,
  spacingHelper,
  Stack,
  TextArea,
  TextInput,
} from '@rea-group/construct-kit-core';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import ExternalLink from '../components/ExternalLink';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import Pagination, { usePagination } from '../components/Pagination';
import Status, { BookingTrackerStatus } from '../components/Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../components/Table/Table';
import BookingTrackerHistoryModal from '../components/tracker/BookingTrackerHistoryModal';
import BookingTrackerReportModal from '../components/tracker/BookingTrackerReportModal';
import BookingTrackerUpdateModal, {
  Action,
} from '../components/tracker/BookingTrackerUpdateModal';
import SavePresetModal from '../components/tracker/SavePresetModal';
import debounce from '../utils/debounce';
import { formatAustralianDate } from '../utils/formatDate';
import formatLive from '../utils/formatLive';
import { isDspEnabled, isPresent } from '../utils/helpers';
import { uniqBy } from 'lodash';

interface BookingTracker {
  id: number;
  impressions: number;
  listingId: number;
  postcode: number;
  propertyType: string;
  price: string;
  state: string | null;
  bookingId: string;
  status: string;
  targeting: string;
  startDate: string;
  endDate: string;
  duration: number | null;
  bookingPeriod: number;
  totalImpressions: number;
  totalImpressionsYdy: number;
  totalClicks: number;
  totalClicksYdy: number;
  totalSpend: string;
  totalSpendYdy: string;
  impressionOsi: string | null;
  impressionOsiYdy: string | null;
  ctr: string | null;
  totalCpc: string | null;
  soldOn: string | null;
  minClicksOsi: string | null;
  avgClicksOsi: string | null;
  maxClicksOsi: string | null;
  maxSpendOsi: string | null;
  fbCpcLive: boolean;
  fbCpmLive: boolean;
  fbMtLive: boolean;
  afLive: boolean;
  ttdLive: boolean;
  yhLive: boolean;
  tbLive: boolean;
  fbImps: number;
  fbImpressionsYdy: number;
  fbCpcClicks: number;
  fbCpcClicksYdy: number;
  fbCpcCpc: string | null;
  fbCpcSpend: string;
  fbCpcSpendYdy: string;
  fbCpcSpendOsi: string | null;
  fbCpcSpendOsiYdy: string | null;
  fbCpmClicks: number;
  fbCpmClicksYdy: number;
  fbCpmCpc: string | null;
  fbCpmSpend: string;
  fbCpmSpendYdy: string;
  fbCpmSpendOsi: string | null;
  fbCpmSpendOsiYdy: string | null;
  fbMtClicks: number;
  fbMtClicksYdy: number;
  fbMtCpc: string | null;
  fbMtSpend: string;
  fbMtSpendYdy: string;
  fbMtSpendOsi: string | null;
  fbMtSpendOsiYdy: string | null;
  afImps: number;
  afImpsYesterday: number;
  afClicks: number;
  afClicksYesterday: number;
  afCpc: string | null;
  afSpend: string;
  afSpendYesterday: string;
  afSpendOsi: string | null;
  afSpendOsiYdy: string | null;
  ttdImps: number;
  ttdImpsYesterday: number;
  ttdClicks: number;
  ttdClicksYesterday: number;
  ttdCpc: string | null;
  ttdSpend: string;
  ttdSpendYesterday: string;
  ttdSpendOsi: string | null;
  ttdSpendOsiYdy: string | null;
  ttd3pdImps: number;
  ttd3pdImpsYesterday: number;
  ttd3pdClicks: number;
  ttd3pdClicksYesterday: number;
  ttd3pdCpc: string | null;
  ttd3pdSpend: string;
  ttd3pdSpendYesterday: string;
  ttd3pdSpendOsi: string | null;
  ttd3pdSpendOsiYdy: string | null;
  yhCpcImpressions: number | null;
  yhCpcImpressionsYdy: number | null;
  yhCpcClicks: number;
  yhCpcClicksYdy: number;
  yhCpcCpc: string | null;
  yhCpcSpend: string;
  yhCpcSpendYdy: string;
  yhCpcSpendOsi: string | null;
  yhCpcSpendOsiYdy: string | null;
  yhCpmImpressions: number | null;
  yhCpmImpressionsYdy: number | null;
  yhCpmClicks: number;
  yhCpmClicksYdy: number;
  yhCpmCpc: string | null;
  yhCpmSpend: string;
  yhCpmSpendYdy: string;
  yhCpmSpendOsi: string | null;
  yhCpmSpendOsiYdy: string | null;
  tbImps: number;
  tbImpsYesterday: number;
  tbClicks: number;
  tbClicksYesterday: number;
  tbCpc: string | null;
  tbSpend: string;
  tbSpendYesterday: string;
  tbSpendOsi: string | null;
  tbSpendOsiYdy: string | null;
  fbCampaignId: string | null;
  afCampaignId: number | null;
  ttdCampaignId: string | null;
  yhCampaignId: string | null;
  tbCampaignGroupId: number | null;
  notes: string | null;
}

export type BookingTrackerColumnName = keyof Omit<
  BookingTracker,
  'id' | 'bookingPeriod'
>;

type ColumnNameWithoutFilters =
  | 'fbCampaignId'
  | 'afCampaignId'
  | 'ttdCampaignId'
  | 'yhCampaignId'
  | 'tbCampaignGroupId';

type ColumnNameWithFilters = Exclude<
  BookingTrackerColumnName,
  ColumnNameWithoutFilters
>;

export type BookingTrackerFilters = Partial<
  Record<BookingTrackerColumnName, string>
>;

export interface BookingTrackersResponse {
  bookingTrackers: BookingTracker[];
  totalCount: number;
}

type BookingTrackerStatusInfo = { bookingTrackerId: string; action: Action };

const TableContainer = styled.div`
  overflow: scroll;
  height: 75vh;

  a:visited {
    color: ${paletteHelper('graphicPurple')};
  }
`;

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

const StyledTextInput = styled(TextInput)`
  .customInputWrapper {
    padding: ${spacingHelper('twoExtraSmall extraSmall 0')};
  }
`;

const StyledHeaderButton = styled(Button)`
  padding: ${spacingHelper('0 extraSmall')};

  span:first-child {
    margin-right: ${spacingHelper('twoExtraSmall')};
  }
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledListboxOption = styled(ListboxOption)`
  white-space: unset;
`;

const StyledMenu = styled(Menu)`
  vertical-align: middle;

  button {
    padding: 0;
    min-width: 0;
    min-height: 0;
  }
`;

const getBookingTrackersData = (
  pageSize: number,
  pageNumber: number,
  filters: BookingTrackerFilters,
  sortBy: BookingTrackerColumnName | undefined,
  sortDirection: SortDirection | undefined,
): Promise<BookingTrackersResponse> => {
  const filterQueryParams = Object.entries(filters)
    .filter(([, filterValue]) => isPresent(filterValue))
    .map(([columnName, filterValue]) => `&${columnName}=${filterValue}`)
    .join('');
  const sortingQueryParams =
    isPresent(sortBy) && isPresent(sortDirection)
      ? `&sortBy=${sortBy}&sortDirection=${sortDirection}`
      : '';
  return fetchJson<BookingTrackersResponse>(
    `/booking-trackers?pageSize=${pageSize}&pageNumber=${pageNumber}${filterQueryParams}${sortingQueryParams}`,
  );
};

const renderDuration = (
  duration: number | null,
  bookingPeriod: number,
): React.JSX.Element => {
  if (isPresent(duration) && duration > bookingPeriod + 3) {
    return <Badge variant="negative">{duration}</Badge>;
  }

  return <>{duration}</>;
};

const formatPercentage = (value: string): string =>
  `${Number(value).toFixed(2)}%`;

const formatNumber = (value: number): string => value.toLocaleString('en');

interface TrackerProps {
  pageSize?: number;
}

interface UpdateBookingTrackerNotesParams {
  bookingId: string;
  notes: string;
}

export const orderedColumnNames: BookingTrackerColumnName[] = [
  'impressions',
  'listingId',
  'postcode',
  'propertyType',
  'price',
  'state',
  'bookingId',
  'status',
  'targeting',
  'startDate',
  'endDate',
  'duration',
  'totalImpressions',
  'totalImpressionsYdy',
  'totalClicks',
  'totalClicksYdy',
  'totalSpend',
  'totalSpendYdy',
  'impressionOsi',
  'impressionOsiYdy',
  'ctr',
  'totalCpc',
  'soldOn',
  'minClicksOsi',
  'avgClicksOsi',
  'maxClicksOsi',
  'maxSpendOsi',
  'fbCpcLive',
  'fbCpmLive',
  'fbMtLive',
  'afLive',
  'ttdLive',
  ...(isDspEnabled('yahoo') ? (['yhLive'] as BookingTrackerColumnName[]) : []),
  ...(isDspEnabled('taboola')
    ? (['tbLive'] as BookingTrackerColumnName[])
    : []),
  'fbImps',
  'fbImpressionsYdy',
  'fbCpcClicks',
  'fbCpcClicksYdy',
  'fbCpcCpc',
  'fbCpcSpend',
  'fbCpcSpendYdy',
  'fbCpcSpendOsi',
  'fbCpcSpendOsiYdy',
  'fbCpmClicks',
  'fbCpmClicksYdy',
  'fbCpmCpc',
  'fbCpmSpend',
  'fbCpmSpendYdy',
  'fbCpmSpendOsi',
  'fbCpmSpendOsiYdy',
  'fbMtClicks',
  'fbMtClicksYdy',
  'fbMtCpc',
  'fbMtSpend',
  'fbMtSpendYdy',
  'fbMtSpendOsi',
  'fbMtSpendOsiYdy',
  'afImps',
  'afImpsYesterday',
  'afClicks',
  'afClicksYesterday',
  'afCpc',
  'afSpend',
  'afSpendYesterday',
  'afSpendOsi',
  'afSpendOsiYdy',
  'ttdImps',
  'ttdImpsYesterday',
  'ttdClicks',
  'ttdClicksYesterday',
  'ttdCpc',
  'ttdSpend',
  'ttdSpendYesterday',
  'ttdSpendOsi',
  'ttdSpendOsiYdy',
  'ttd3pdImps',
  'ttd3pdImpsYesterday',
  'ttd3pdClicks',
  'ttd3pdClicksYesterday',
  'ttd3pdCpc',
  'ttd3pdSpend',
  'ttd3pdSpendYesterday',
  'ttd3pdSpendOsi',
  'ttd3pdSpendOsiYdy',
  ...(isDspEnabled('yahoo')
    ? ([
        'yhCpcImpressions',
        'yhCpcImpressionsYdy',
        'yhCpcClicks',
        'yhCpcClicksYdy',
        'yhCpcCpc',
        'yhCpcSpend',
        'yhCpcSpendYdy',
        'yhCpcSpendOsi',
        'yhCpcSpendOsiYdy',
        'yhCpmImpressions',
        'yhCpmImpressionsYdy',
        'yhCpmClicks',
        'yhCpmClicksYdy',
        'yhCpmCpc',
        'yhCpmSpend',
        'yhCpmSpendYdy',
        'yhCpmSpendOsi',
        'yhCpmSpendOsiYdy',
      ] as BookingTrackerColumnName[])
    : []),
  ...(isDspEnabled('taboola')
    ? ([
        'tbImps',
        'tbImpsYesterday',
        'tbClicks',
        'tbClicksYesterday',
        'tbCpc',
        'tbSpend',
        'tbSpendYesterday',
        'tbSpendOsi',
        'tbSpendOsiYdy',
      ] as BookingTrackerColumnName[])
    : []),
  'fbCampaignId',
  'afCampaignId',
  'ttdCampaignId',
  ...(isDspEnabled('yahoo')
    ? (['yhCampaignId'] as BookingTrackerColumnName[])
    : []),
  ...(isDspEnabled('taboola')
    ? (['tbCampaignGroupId'] as BookingTrackerColumnName[])
    : []),
  'notes',
];

export const columnNamesToLabelsWithFilters: Record<
  ColumnNameWithFilters,
  string
> = {
  impressions: 'Imps',
  listingId: 'Listing ID',
  postcode: 'Postcode',
  propertyType: 'Prop Type',
  price: 'Price',
  state: 'State',
  bookingId: 'Booking ID',
  status: 'Status',
  targeting: 'Targeting',
  startDate: 'Start Date',
  endDate: 'End Date',
  duration: 'Duration',
  totalImpressions: 'Total Imps',
  totalImpressionsYdy: 'Total Imps YDY',
  totalClicks: 'Total Clicks',
  totalClicksYdy: 'Total Clicks YDY',
  totalSpend: 'Total Spend',
  totalSpendYdy: 'Total Spend YDY',
  impressionOsi: 'Imps OSI',
  impressionOsiYdy: 'Imps OSI YDY',
  ctr: 'CTR',
  totalCpc: 'Total CPC',
  soldOn: 'Sold Date',
  minClicksOsi: 'Min Clicks OSI',
  avgClicksOsi: 'Avg Clicks OSI',
  maxClicksOsi: 'Max Clicks OSI',
  maxSpendOsi: 'Max Spend OSI',
  fbCpcLive: 'FB CPC Live',
  fbCpmLive: 'FB CPM Live',
  fbMtLive: 'FB MT Live',
  afLive: 'AF Live',
  ttdLive: 'TTD Live',
  yhLive: 'YH Live',
  tbLive: 'TB Live',
  fbImps: 'FB Imps',
  fbImpressionsYdy: 'FB Imps YDY',
  fbCpcClicks: 'FB CPC Clicks',
  fbCpcClicksYdy: 'FB CPC Clicks YDY',
  fbCpcCpc: 'FB CPC CPC',
  fbCpcSpend: 'FB CPC Spend',
  fbCpcSpendYdy: 'FB CPC Spend YDY',
  fbCpcSpendOsi: 'FB CPC Spend OSI',
  fbCpcSpendOsiYdy: 'FB CPC Spend OSI YDY',
  fbCpmClicks: 'FB CPM Clicks',
  fbCpmClicksYdy: 'FB CPM Clicks YDY',
  fbCpmCpc: 'FB CPM CPC',
  fbCpmSpend: 'FB CPM Spend',
  fbCpmSpendYdy: 'FB CPM Spend YDY',
  fbCpmSpendOsi: 'FB CPM Spend OSI',
  fbCpmSpendOsiYdy: 'FB CPM Spend OSI YDY',
  fbMtClicks: 'FB MT Clicks',
  fbMtClicksYdy: 'FB MT Clicks YDY',
  fbMtCpc: 'FB MT CPC',
  fbMtSpend: 'FB MT Spend',
  fbMtSpendYdy: 'FB MT Spend YDY',
  fbMtSpendOsi: 'FB MT Spend OSI',
  fbMtSpendOsiYdy: 'FB MT Spend OSI YDY',
  afImps: 'AF Imps',
  afImpsYesterday: 'AF Imps YDY',
  afClicks: 'AF Clicks',
  afClicksYesterday: 'AF Clicks YDY',
  afCpc: 'AF CPC',
  afSpend: 'AF Spend',
  afSpendYesterday: 'AF Spend YDY',
  afSpendOsi: 'AF Spend OSI',
  afSpendOsiYdy: 'AF Spend OSI YDY',
  ttdImps: 'TTD Imps',
  ttdImpsYesterday: 'TTD Imps YDY',
  ttdClicks: 'TTD Clicks',
  ttdClicksYesterday: 'TTD Clicks YDY',
  ttdCpc: 'TTD CPC',
  ttdSpend: 'TTD Spend',
  ttdSpendYesterday: 'TTD Spend YDY',
  ttdSpendOsi: 'TTD Spend OSI',
  ttdSpendOsiYdy: 'TTD Spend OSI YDY',
  ttd3pdImps: 'TTD 3pd Imps',
  ttd3pdImpsYesterday: 'TTD 3pd Imps YDY',
  ttd3pdClicks: 'TTD 3pd Clicks',
  ttd3pdClicksYesterday: 'TTD 3pd Clicks YDY',
  ttd3pdCpc: 'TTD 3pd CPC',
  ttd3pdSpend: 'TTD 3pd Spend',
  ttd3pdSpendYesterday: 'TTD 3pd Spend YDY',
  ttd3pdSpendOsi: 'TTD 3pd Spend OSI',
  ttd3pdSpendOsiYdy: 'TTD 3pd Spend OSI YDY',
  yhCpcImpressions: 'YH Native CPC Imps',
  yhCpcImpressionsYdy: 'YH Native CPC Imps YDY',
  yhCpcClicks: 'YH Native CPC Clicks',
  yhCpcClicksYdy: 'YH Native CPC Clicks YDY',
  yhCpcCpc: 'YH Native CPC CPC',
  yhCpcSpend: 'YH Native CPC Spend',
  yhCpcSpendYdy: 'YH Native CPC Spend YDY',
  yhCpcSpendOsi: 'YH Native CPC Spend OSI',
  yhCpcSpendOsiYdy: 'YH Native CPC Spend OSI YDY',
  yhCpmImpressions: 'YH CPM Imps',
  yhCpmImpressionsYdy: 'YH CPM Imps YDY',
  yhCpmClicks: 'YH CPM Clicks',
  yhCpmClicksYdy: 'YH CPM Clicks YDY',
  yhCpmCpc: 'YH CPM CPC',
  yhCpmSpend: 'YH CPM Spend',
  yhCpmSpendYdy: 'YH CPM Spend YDY',
  yhCpmSpendOsi: 'YH CPM Spend OSI',
  yhCpmSpendOsiYdy: 'YH CPM Spend OSI YDY',
  tbImps: 'TB Imps',
  tbImpsYesterday: 'TB Imps YDY',
  tbClicks: 'TB Clicks',
  tbClicksYesterday: 'TB Clicks YDY',
  tbCpc: 'TB CPC',
  tbSpend: 'TB Spend',
  tbSpendYesterday: 'TB Spend YDY',
  tbSpendOsi: 'TB Spend OSI',
  tbSpendOsiYdy: 'TB Spend OSI YDY',
  notes: 'Notes',
};

const columnNamesToLabelsWithoutFilters: Record<
  ColumnNameWithoutFilters,
  string
> = {
  fbCampaignId: 'FB',
  afCampaignId: 'AF',
  ttdCampaignId: 'TTD',
  yhCampaignId: 'YH',
  tbCampaignGroupId: 'TB',
};

export const columnNamesToLabels: Record<BookingTrackerColumnName, string> = {
  ...columnNamesToLabelsWithFilters,
  ...columnNamesToLabelsWithoutFilters,
};

const columnNamesWithoutFilters = Object.keys(
  columnNamesToLabelsWithoutFilters,
);

const multiSelectOptions: SelectOption[] = orderedColumnNames.map((name) => ({
  value: name,
  label: columnNamesToLabels[name],
}));

const createCellRenderers = ({
  impressions,
  listingId,
  postcode,
  propertyType,
  price,
  state,
  bookingId,
  status,
  targeting,
  startDate,
  endDate,
  duration,
  bookingPeriod,
  totalImpressions,
  totalImpressionsYdy,
  totalClicks,
  totalClicksYdy,
  totalSpend,
  totalSpendYdy,
  impressionOsi,
  impressionOsiYdy,
  ctr,
  totalCpc,
  soldOn,
  minClicksOsi,
  avgClicksOsi,
  maxClicksOsi,
  maxSpendOsi,
  fbCpcLive,
  fbCpmLive,
  fbMtLive,
  afLive,
  ttdLive,
  yhLive,
  tbLive,
  fbImps,
  fbImpressionsYdy,
  fbCpcClicks,
  fbCpcClicksYdy,
  fbCpcCpc,
  fbCpcSpend,
  fbCpcSpendYdy,
  fbCpcSpendOsi,
  fbCpcSpendOsiYdy,
  fbCpmClicks,
  fbCpmClicksYdy,
  fbCpmCpc,
  fbCpmSpend,
  fbCpmSpendYdy,
  fbCpmSpendOsi,
  fbCpmSpendOsiYdy,
  fbMtClicks,
  fbMtClicksYdy,
  fbMtCpc,
  fbMtSpend,
  fbMtSpendYdy,
  fbMtSpendOsi,
  fbMtSpendOsiYdy,
  afImps,
  afImpsYesterday,
  afClicks,
  afClicksYesterday,
  afCpc,
  afSpend,
  afSpendYesterday,
  afSpendOsi,
  afSpendOsiYdy,
  ttdImps,
  ttdImpsYesterday,
  ttdClicks,
  ttdClicksYesterday,
  ttdCpc,
  ttdSpend,
  ttdSpendYesterday,
  ttdSpendOsi,
  ttdSpendOsiYdy,
  ttd3pdImps,
  ttd3pdImpsYesterday,
  ttd3pdClicks,
  ttd3pdClicksYesterday,
  ttd3pdCpc,
  ttd3pdSpend,
  ttd3pdSpendYesterday,
  ttd3pdSpendOsi,
  ttd3pdSpendOsiYdy,
  yhCpcImpressions,
  yhCpcImpressionsYdy,
  yhCpcClicks,
  yhCpcClicksYdy,
  yhCpcCpc,
  yhCpcSpend,
  yhCpcSpendYdy,
  yhCpcSpendOsi,
  yhCpcSpendOsiYdy,
  yhCpmImpressions,
  yhCpmImpressionsYdy,
  yhCpmClicks,
  yhCpmClicksYdy,
  yhCpmCpc,
  yhCpmSpend,
  yhCpmSpendYdy,
  yhCpmSpendOsi,
  yhCpmSpendOsiYdy,
  tbImps,
  tbImpsYesterday,
  tbClicks,
  tbClicksYesterday,
  tbCpc,
  tbSpend,
  tbSpendYesterday,
  tbSpendOsi,
  tbSpendOsiYdy,
  fbCampaignId,
  afCampaignId,
  ttdCampaignId,
  yhCampaignId,
  tbCampaignGroupId,
  notes,
}: BookingTracker): Record<
  BookingTrackerColumnName,
  () => React.JSX.Element | string | number | boolean | null
> => ({
  impressions: () => formatNumber(impressions),
  listingId: () => (
    <Link href={`https://www.realestate.com.au/${listingId}`} target="_blank">
      {listingId}
    </Link>
  ),
  postcode: () => postcode,
  propertyType: () => propertyType,
  price: () => price,
  state: () => state,
  bookingId: () => (
    <Link
      href={`/booking-data?id=${bookingId}`}
      variant="primary"
      target="_blank"
    >
      {bookingId}
    </Link>
  ),
  status: () => <Status status={status} />,
  targeting: () => targeting,
  startDate: () => formatAustralianDate(startDate),
  endDate: () => formatAustralianDate(endDate),
  duration: () => renderDuration(duration, bookingPeriod),
  totalImpressions: () => formatNumber(totalImpressions),
  totalImpressionsYdy: () => formatNumber(totalImpressionsYdy),
  totalClicks: () => formatNumber(totalClicks),
  totalClicksYdy: () => formatNumber(totalClicksYdy),
  totalSpend: () => formatNumber(Number(totalSpend)),
  totalSpendYdy: () => formatNumber(Number(totalSpendYdy)),
  impressionOsi: () =>
    isPresent(impressionOsi) && formatPercentage(impressionOsi),
  impressionOsiYdy: () =>
    isPresent(impressionOsiYdy) && formatPercentage(impressionOsiYdy),
  ctr: () => isPresent(ctr) && formatPercentage(ctr),
  totalCpc: () => totalCpc,
  soldOn: () => formatAustralianDate(soldOn),
  minClicksOsi: () => isPresent(minClicksOsi) && formatPercentage(minClicksOsi),
  avgClicksOsi: () => isPresent(avgClicksOsi) && formatPercentage(avgClicksOsi),
  maxClicksOsi: () => isPresent(maxClicksOsi) && formatPercentage(maxClicksOsi),
  maxSpendOsi: () => isPresent(maxSpendOsi) && formatPercentage(maxSpendOsi),
  fbCpcLive: () => formatLive(fbCpcLive),
  fbCpmLive: () => formatLive(fbCpmLive),
  fbMtLive: () => formatLive(fbMtLive),
  afLive: () => formatLive(afLive),
  ttdLive: () => formatLive(ttdLive),
  yhLive: () => formatLive(yhLive),
  tbLive: () => formatLive(tbLive),
  fbImps: () => formatNumber(fbImps),
  fbImpressionsYdy: () => fbImpressionsYdy,
  fbCpcClicks: () => fbCpcClicks,
  fbCpcClicksYdy: () => fbCpcClicksYdy,
  fbCpcCpc: () => fbCpcCpc,
  fbCpcSpend: () => fbCpcSpend,
  fbCpcSpendYdy: () => fbCpcSpendYdy,
  fbCpcSpendOsi: () =>
    isPresent(fbCpcSpendOsi) && formatPercentage(fbCpcSpendOsi),
  fbCpcSpendOsiYdy: () =>
    isPresent(fbCpcSpendOsiYdy) && formatPercentage(fbCpcSpendOsiYdy),
  fbCpmClicks: () => fbCpmClicks,
  fbCpmClicksYdy: () => fbCpmClicksYdy,
  fbCpmCpc: () => fbCpmCpc,
  fbCpmSpend: () => fbCpmSpend,
  fbCpmSpendYdy: () => fbCpmSpendYdy,
  fbCpmSpendOsi: () =>
    isPresent(fbCpmSpendOsi) && formatPercentage(fbCpmSpendOsi),
  fbCpmSpendOsiYdy: () =>
    isPresent(fbCpmSpendOsiYdy) && formatPercentage(fbCpmSpendOsiYdy),
  fbMtClicks: () => fbMtClicks,
  fbMtClicksYdy: () => fbMtClicksYdy,
  fbMtCpc: () => fbMtCpc,
  fbMtSpend: () => fbMtSpend,
  fbMtSpendYdy: () => fbMtSpendYdy,
  fbMtSpendOsi: () => isPresent(fbMtSpendOsi) && formatPercentage(fbMtSpendOsi),
  fbMtSpendOsiYdy: () =>
    isPresent(fbMtSpendOsiYdy) && formatPercentage(fbMtSpendOsiYdy),
  afImps: () => formatNumber(afImps),
  afImpsYesterday: () => formatNumber(afImpsYesterday),
  afClicks: () => formatNumber(afClicks),
  afClicksYesterday: () => formatNumber(afClicksYesterday),
  afCpc: () => afCpc,
  afSpend: () => afSpend,
  afSpendYesterday: () => afSpendYesterday,
  afSpendOsi: () => isPresent(afSpendOsi) && formatPercentage(afSpendOsi),
  afSpendOsiYdy: () =>
    isPresent(afSpendOsiYdy) && formatPercentage(afSpendOsiYdy),
  ttdImps: () => formatNumber(ttdImps),
  ttdImpsYesterday: () => formatNumber(ttdImpsYesterday),
  ttdClicks: () => formatNumber(ttdClicks),
  ttdClicksYesterday: () => formatNumber(ttdClicksYesterday),
  ttdCpc: () => ttdCpc,
  ttdSpend: () => ttdSpend,
  ttdSpendYesterday: () => ttdSpendYesterday,
  ttdSpendOsi: () => isPresent(ttdSpendOsi) && formatPercentage(ttdSpendOsi),
  ttdSpendOsiYdy: () =>
    isPresent(ttdSpendOsiYdy) && formatPercentage(ttdSpendOsiYdy),
  ttd3pdImps: () => formatNumber(ttd3pdImps),
  ttd3pdImpsYesterday: () => formatNumber(ttd3pdImpsYesterday),
  ttd3pdClicks: () => formatNumber(ttd3pdClicks),
  ttd3pdClicksYesterday: () => formatNumber(ttd3pdClicksYesterday),
  ttd3pdCpc: () => ttd3pdCpc,
  ttd3pdSpend: () => ttd3pdSpend,
  ttd3pdSpendYesterday: () => ttd3pdSpendYesterday,
  ttd3pdSpendOsi: () =>
    isPresent(ttd3pdSpendOsi) && formatPercentage(ttd3pdSpendOsi),
  ttd3pdSpendOsiYdy: () =>
    isPresent(ttd3pdSpendOsiYdy) && formatPercentage(ttd3pdSpendOsiYdy),
  yhCpcImpressions: () =>
    isPresent(yhCpcImpressions) && formatNumber(yhCpcImpressions),
  yhCpcImpressionsYdy: () =>
    isPresent(yhCpcImpressionsYdy) && formatNumber(yhCpcImpressionsYdy),
  yhCpcClicks: () => formatNumber(yhCpcClicks),
  yhCpcClicksYdy: () => formatNumber(yhCpcClicksYdy),
  yhCpcCpc: () => yhCpcCpc,
  yhCpcSpend: () => yhCpcSpend,
  yhCpcSpendYdy: () => yhCpcSpendYdy,
  yhCpcSpendOsi: () =>
    isPresent(yhCpcSpendOsi) && formatPercentage(yhCpcSpendOsi),
  yhCpcSpendOsiYdy: () =>
    isPresent(yhCpcSpendOsiYdy) && formatPercentage(yhCpcSpendOsiYdy),
  yhCpmImpressions: () =>
    isPresent(yhCpmImpressions) && formatNumber(yhCpmImpressions),
  yhCpmImpressionsYdy: () =>
    isPresent(yhCpmImpressionsYdy) && formatNumber(yhCpmImpressionsYdy),
  yhCpmClicks: () => formatNumber(yhCpmClicks),
  yhCpmClicksYdy: () => formatNumber(yhCpmClicksYdy),
  yhCpmCpc: () => yhCpmCpc,
  yhCpmSpend: () => yhCpmSpend,
  yhCpmSpendYdy: () => yhCpmSpendYdy,
  yhCpmSpendOsi: () =>
    isPresent(yhCpmSpendOsi) && formatPercentage(yhCpmSpendOsi),
  yhCpmSpendOsiYdy: () =>
    isPresent(yhCpmSpendOsiYdy) && formatPercentage(yhCpmSpendOsiYdy),
  tbImps: () => formatNumber(tbImps),
  tbImpsYesterday: () => formatNumber(tbImpsYesterday),
  tbClicks: () => formatNumber(tbClicks),
  tbClicksYesterday: () => formatNumber(tbClicksYesterday),
  tbCpc: () => tbCpc,
  tbSpend: () => tbSpend,
  tbSpendYesterday: () => tbSpendYesterday,
  tbSpendOsi: () => isPresent(tbSpendOsi) && formatPercentage(tbSpendOsi),
  tbSpendOsiYdy: () =>
    isPresent(tbSpendOsiYdy) && formatPercentage(tbSpendOsiYdy),
  fbCampaignId: () =>
    fbCampaignId && (
      <ExternalLink
        href={`https://business.facebook.com/ads/manager/campaign/adsets/?ids=${fbCampaignId}`}
      >
        FB
      </ExternalLink>
    ),
  afCampaignId: () =>
    afCampaignId && (
      <ExternalLink
        href={`https://flow.adform.com/campaign/${afCampaignId}/overview`}
      >
        AF
      </ExternalLink>
    ),
  ttdCampaignId: () =>
    ttdCampaignId && (
      <ExternalLink
        href={`https://desk.thetradedesk.com/app/advertiser/zg0e0su/buy/campaign/${ttdCampaignId}/details`}
      >
        TTD
      </ExternalLink>
    ),
  yhCampaignId: () =>
    yhCampaignId && (
      <ExternalLink
        href={`https://sso.admanagerplus.yahoo.com/app/campaigns/${yhCampaignId}/lines`}
      >
        YH
      </ExternalLink>
    ),
  tbCampaignGroupId: () =>
    tbCampaignGroupId && (
      <ExternalLink
        href={`https://sso.admanagerplus.yahoo.com/app/advertisers/42900/taboola?taboolaUrl=https://ads-yahoo.taboola.com/campaigns?campaignId=${tbCampaignGroupId}`}
      >
        TB
      </ExternalLink>
    ),
  notes: () => notes,
});

const freezeSettingHeader: Partial<Record<BookingTrackerColumnName, string>> = {
  impressions: `
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  `,
  listingId: `
  position: sticky;
  top: 0;
  left: 6.594rem;
  z-index: 1;
  `,
};

const freezeSettingBody: Partial<Record<BookingTrackerColumnName, string>> = {
  impressions: `
  position: sticky;
  left: 0;
  `,
  listingId: `
  position: sticky;
  left: 6.594rem;
  `,
};

export const createDateFourDaysAgo = (): string => {
  const dateNow = new Date();
  dateNow.setDate(dateNow.getDate() - 4);
  return formatAustralianDate(dateNow.toISOString());
};

const defaultFilters: Record<string, string> = {
  status: 'Active',
  startDate: `<=${createDateFourDaysAgo()}`,
  totalImpressions: '>0',
  impressionOsi: '<100',
  impressionOsiYdy: '<100',
};

const updateBookingTrackerNotes = ({
  bookingId,
  notes,
}: UpdateBookingTrackerNotesParams): Promise<BookingTracker> => {
  return fetchJson<BookingTracker>(`/booking-trackers/${bookingId}`, {
    method: 'PUT',
    body: JSON.stringify({ notes }),
  });
};

export interface BookingTrackerColumnsPreset {
  id: number;
  name: string;
  displayedColumns: BookingTrackerColumnName[];
  filters: BookingTrackerFilters | null;
}

interface BookingTrackerColumnsPresetsResponse {
  columnsPresets: BookingTrackerColumnsPreset[];
}

const Tracker = ({ pageSize = 100 }: TrackerProps): React.JSX.Element => {
  const {
    pageNumber,
    onPreviousPageButtonClick,
    onNextPageButtonClick,
    resetPagination,
  } = usePagination();

  const scrollToTop = (): void => {
    window.scrollTo(0, 0);
  };

  const displayedColumnsLS = useMemo(() => {
    const value = window.localStorage.getItem('displayedColumns');

    return isPresent(value)
      ? (value
          .split(',')
          .filter((name) => name !== '') as BookingTrackerColumnName[])
      : undefined;
  }, []);

  useEffect(() => {
    if (
      isPresent(displayedColumnsLS) &&
      displayedColumnsLS.toString() !== orderedColumnNames.toString()
    ) {
      window.localStorage.setItem(
        'displayedColumns',
        orderedColumnNames.join(),
      );
    }
  }, [displayedColumnsLS]);

  const [uiFilters, setUiFilters] =
    useState<BookingTrackerFilters>(defaultFilters);
  const [filters, setFilters] = useState<BookingTrackerFilters>(defaultFilters);
  const [sortBy, setSortBy] = useState<BookingTrackerColumnName>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const [displayedColumns, setDisplayedColumns] = useState<
    BookingTrackerColumnName[]
  >(isPresent(displayedColumnsLS) ? displayedColumnsLS : orderedColumnNames);
  const [selectedOptions, setSelectedOptions] = useState(
    displayedColumns.map((name) => ({
      value: name,
      label: columnNamesToLabels[name],
    })) as SelectOption[],
  );
  const [selectedPreset, setSelectedPreset] = useState<SelectOption | null>(
    null,
  );
  const [isSavePresetModalOpen, setIsSavePresetModalOpen] = useState(false);

  const handlePresetChange = (
    option: SelectOption | null | undefined,
  ): void => {
    setSelectedPreset(option ?? null);
    const selectedPreset = presetsData?.columnsPresets.find(
      ({ name }) => name === option?.value,
    );
    if (!isPresent(selectedPreset)) {
      return;
    }
    setDisplayedColumns(selectedPreset.displayedColumns);
    setSelectedOptions(
      selectedPreset.displayedColumns.map((name) => ({
        value: name,
        label: columnNamesToLabels[name],
      })) as SelectOption[],
    );

    setUiFilters(selectedPreset.filters ?? {});
    setFilters(selectedPreset.filters ?? {});
  };

  const {
    isFetching,
    isError,
    error,
    data: bookingTrackersData,
  } = useQuery({
    queryKey: [
      'bookingTrackersData',
      filters,
      sortBy,
      sortDirection,
      pageNumber,
    ],
    queryFn: () =>
      getBookingTrackersData(
        pageSize,
        pageNumber,
        filters,
        sortBy,
        sortDirection,
      ),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  const setFiltersDebounced = useMemo(
    () =>
      debounce((updatedFilters: BookingTrackerFilters): void => {
        setFilters(updatedFilters);
        resetPagination();
      }, 500),
    [resetPagination],
  );

  const [editNotesBookingTrackerId, setEditNotesBookingTrackerId] =
    useState<number>();
  const [noteUpdateError, setNoteUpdateError] = useState<unknown>();

  const queryClient = useQueryClient();

  const handleEditClick = (id: number): void => {
    setEditNotesBookingTrackerId(id);
  };

  const updateBookingTrackerNotesMutation = useMutation({
    mutationFn: updateBookingTrackerNotes,
    onSuccess: (data) => {
      setNoteUpdateError(undefined);
      queryClient.setQueryData<BookingTrackersResponse>(
        ['bookingTrackersData', filters, sortBy, sortDirection, pageNumber],
        (oldBookingTrackersData) =>
          isPresent(oldBookingTrackersData)
            ? {
                ...oldBookingTrackersData,
                bookingTrackers: oldBookingTrackersData.bookingTrackers.map(
                  (item) => (item.bookingId === data.bookingId ? data : item),
                ),
              }
            : oldBookingTrackersData,
      );
    },
    onError: (error) => {
      setNoteUpdateError(error);
    },
  });

  const persistNotes = (id: string, noteText: string): void => {
    setEditNotesBookingTrackerId(undefined);
    updateBookingTrackerNotesMutation.mutate({
      bookingId: id,
      notes: noteText,
    });
  };

  const { data: presetsData, isFetching: isPresetsFetching } = useQuery({
    queryKey: ['bookingTrackerColumnsPresets'],
    queryFn: () =>
      fetchJson<BookingTrackerColumnsPresetsResponse>(
        '/booking-trackers/columns-presets',
      ),
    refetchOnWindowFocus: false,
  });

  const presetOptions = isPresent(presetsData)
    ? presetsData.columnsPresets.map(({ name }) => ({
        value: name,
        label: name,
      }))
    : [];

  const [reportBookingId, setReportBookingId] = useState<string>();
  const [historyBookingId, setHistoryBookingId] = useState<string>();
  const [bookingTrackerStatusInfo, setBookingTrackerStatusInfo] =
    useState<BookingTrackerStatusInfo>();

  const handleFilterChange = (
    name: BookingTrackerColumnName,
    value: string,
  ): void => {
    const updatedFilters = {
      ...uiFilters,
      [name]: isPresent(value) && value !== '' ? value : undefined,
    };

    setUiFilters(updatedFilters);
    setFiltersDebounced(updatedFilters);
  };

  const handleSort = (name: BookingTrackerColumnName): void => {
    setSortBy(name);

    if (
      sortBy !== name ||
      (sortBy === name && sortDirection === SortDirection.Desc)
    ) {
      setSortDirection(SortDirection.Asc);
    } else {
      setSortDirection(SortDirection.Desc);
    }

    resetPagination();
  };

  const isCompact = true;
  const isStickyHeader = true;
  const useMinWidthColumns = ['impressions', 'notes'];

  return (
    <Stack gap="extraLarge">
      <Inline justifyContent="space-between" alignItems="flex-end" grow={false}>
        <H1>Tracker</H1>
        <Inline grow={false}>
          <Inline grow={false} gap="extraSmall">
            <StyledSelect
              options={presetOptions}
              selectedOption={selectedPreset}
              onSelectedOptionChange={handlePresetChange}
              disabled={isPresetsFetching}
              sizeVariant="medium"
              label="Presets"
              placeholder="Presets"
              hideLabel
              mapOptionToListNode={({ option, isActive, sizeVariant }) => (
                <StyledListboxOption
                  isActive={isActive}
                  sizeVariant={sizeVariant}
                >
                  {option.label}
                </StyledListboxOption>
              )}
            />
            <Button
              variant="outline"
              sizeVariant="medium"
              onClick={() => setIsSavePresetModalOpen(true)}
            >
              Save preset
            </Button>
            <MultiSelect
              icon={<EyeMd />}
              sizeVariant="medium"
              options={multiSelectOptions}
              selectedOptions={selectedOptions}
              placeholder="Displayed columns"
              label="Displayed columns"
              hideLabel
              onSelectedOptionsChange={(selectedOptions) => {
                setSelectedOptions(selectedOptions);

                const selectedOptionsKeys = selectedOptions.map(
                  ({ value }) => value,
                ) as BookingTrackerColumnName[];

                const orderedDisplayedColumns = orderedColumnNames.filter(
                  (name) => selectedOptionsKeys.includes(name),
                );

                setDisplayedColumns(orderedDisplayedColumns);

                window.localStorage.setItem(
                  'displayedColumns',
                  orderedDisplayedColumns.join(),
                );
              }}
              renderDisplayText={() => 'Displayed columns'}
            />
            <Button
              variant="outline"
              icon={<EyeMd />}
              sizeVariant="medium"
              disabled={selectedOptions.length === multiSelectOptions.length}
              onClick={() => {
                setSelectedOptions(multiSelectOptions);
                setSelectedPreset(null);
                setDisplayedColumns(orderedColumnNames);

                window.localStorage.setItem(
                  'displayedColumns',
                  orderedColumnNames.join(),
                );
              }}
            >
              Display all
            </Button>
            <Button
              variant="outline"
              icon={<EyeHideMd />}
              sizeVariant="medium"
              disabled={selectedOptions.length === 0}
              onClick={() => {
                setSelectedOptions([]);
                setSelectedPreset(null);
                setDisplayedColumns([]);

                window.localStorage.setItem('displayedColumns', '');
              }}
            >
              Hide all
            </Button>
          </Inline>
          <Button
            variant="outline"
            icon={<DeleteMd />}
            sizeVariant="medium"
            onClick={() => {
              setUiFilters({});
              setFilters({});
              setSelectedPreset(null);
            }}
          >
            Clear filters
          </Button>
        </Inline>
      </Inline>
      {isFetching && <PageLoadingSpinner />}
      {(isError || isPresent(noteUpdateError)) && (
        <ErrorAlert error={error || noteUpdateError} />
      )}
      <Stack gap="small">
        <TableContainer>
          <Table isStickyHeader={isStickyHeader}>
            <TableHeader isStickyHeader={isStickyHeader}>
              {displayedColumns
                .map((name) => ({ name, label: columnNamesToLabels[name] }))
                .map(({ name, label }) => (
                  <TableHeaderColumn
                    key={name}
                    freeze={freezeSettingHeader[name]}
                    isCompact={isCompact}
                    hasMinWidth={useMinWidthColumns.includes(name)}
                  >
                    <Stack gap="extraSmall">
                      <StyledHeaderButton
                        data-testid={name}
                        variant="link-primary"
                        onClick={() => handleSort(name)}
                        icon={
                          sortBy === name ? (
                            sortDirection === SortDirection.Asc ? (
                              <ArrowUpTwoMd />
                            ) : (
                              <ArrowDownTwoMd />
                            )
                          ) : null
                        }
                        iconPlacement="right"
                      >
                        {label}
                      </StyledHeaderButton>
                      {!columnNamesWithoutFilters.includes(name) && (
                        <StyledTextInput
                          label={label}
                          value={
                            isPresent(uiFilters[name]) ? uiFilters[name] : ''
                          }
                          hideLabel
                          sizeVariant="small"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleFilterChange(name, e.target.value)
                          }
                          dangerouslySetClassNames={{
                            scrollableArea: 'customInputWrapper',
                          }}
                        />
                      )}
                    </Stack>
                  </TableHeaderColumn>
                ))}
              <TableHeaderColumn isCompact={isCompact} />
            </TableHeader>
            {bookingTrackersData && (
              <TableBody>
                {bookingTrackersData.bookingTrackers.length === 0 ? (
                  <EmptyTableBody />
                ) : (
                  bookingTrackersData.bookingTrackers.map((bookingTracker) => {
                    // eslint-disable-next-line testing-library/render-result-naming-convention
                    const cellRenderers = createCellRenderers(bookingTracker);
                    const isActiveBookingTracker =
                      bookingTracker.status !==
                        BookingTrackerStatus.Cancelled.toString() &&
                      bookingTracker.status !==
                        BookingTrackerStatus.Ended.toString();

                    return (
                      <TableRow key={bookingTracker.id}>
                        {displayedColumns.map((name) =>
                          name === 'notes' ? (
                            <TableContentColumn
                              key={`${bookingTracker.id}-${name}`}
                              noWrap={false}
                              onColumnClick={() =>
                                handleEditClick(bookingTracker.id)
                              }
                              isCompact={isCompact}
                            >
                              {editNotesBookingTrackerId ===
                              bookingTracker.id ? (
                                <TextArea
                                  label="Edit notes"
                                  hideLabel={true}
                                  onBlur={(e) =>
                                    persistNotes(
                                      bookingTracker.bookingId,
                                      e.target.value,
                                    )
                                  }
                                  maxLength={64}
                                  defaultValue={cellRenderers[name]() as string}
                                />
                              ) : (
                                cellRenderers[name]()
                              )}
                            </TableContentColumn>
                          ) : (
                            <TableContentColumn
                              key={`${bookingTracker.id}-${name}`}
                              isCompact={isCompact}
                              freeze={freezeSettingBody[name]}
                            >
                              {cellRenderers[name]()}
                            </TableContentColumn>
                          ),
                        )}
                        <TableContentColumn isCompact={isCompact}>
                          <StyledMenu>
                            {() => (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    setReportBookingId(bookingTracker.bookingId)
                                  }
                                >
                                  Report
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    setHistoryBookingId(
                                      bookingTracker.bookingId,
                                    )
                                  }
                                >
                                  History
                                </MenuItem>
                                <>
                                  {isActiveBookingTracker && (
                                    <>
                                      {bookingTracker.status ===
                                      BookingTrackerStatus.Paused.toString() ? (
                                        <MenuItem
                                          onClick={() =>
                                            setBookingTrackerStatusInfo({
                                              bookingTrackerId:
                                                bookingTracker.bookingId,
                                              action: Action.Resume,
                                            })
                                          }
                                        >
                                          Resume
                                        </MenuItem>
                                      ) : (
                                        <MenuItem
                                          onClick={() =>
                                            setBookingTrackerStatusInfo({
                                              bookingTrackerId:
                                                bookingTracker.bookingId,
                                              action: Action.Pause,
                                            })
                                          }
                                        >
                                          Pause
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={() =>
                                          setBookingTrackerStatusInfo({
                                            bookingTrackerId:
                                              bookingTracker.bookingId,
                                            action: Action.Cancel,
                                          })
                                        }
                                      >
                                        Cancel
                                      </MenuItem>
                                    </>
                                  )}
                                </>
                              </>
                            )}
                          </StyledMenu>
                        </TableContentColumn>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {bookingTrackersData && bookingTrackersData.totalCount > 0 && (
          <Pagination
            pageSize={pageSize}
            pageNumber={pageNumber}
            totalCount={bookingTrackersData.totalCount}
            onPreviousPageButtonClick={() => {
              onPreviousPageButtonClick();
              scrollToTop();
            }}
            onNextPageButtonClick={() => {
              onNextPageButtonClick();
              scrollToTop();
            }}
          />
        )}
        <BookingTrackerReportModal
          bookingId={reportBookingId}
          opened={isPresent(reportBookingId)}
          onRequestClose={() => setReportBookingId(undefined)}
        />
        <BookingTrackerHistoryModal
          bookingId={historyBookingId}
          opened={isPresent(historyBookingId)}
          onRequestClose={() => setHistoryBookingId(undefined)}
        />
        {bookingTrackerStatusInfo && (
          <BookingTrackerUpdateModal
            bookingId={bookingTrackerStatusInfo.bookingTrackerId}
            opened={isPresent(bookingTrackerStatusInfo.bookingTrackerId)}
            onRequestClose={() => setBookingTrackerStatusInfo(undefined)}
            action={bookingTrackerStatusInfo.action}
          />
        )}
        <SavePresetModal
          isOpen={isSavePresetModalOpen}
          displayedColumns={displayedColumns}
          filters={filters}
          existingPresets={presetsData?.columnsPresets ?? []}
          onSuccess={(savedPreset) => {
            queryClient.setQueryData<BookingTrackerColumnsPresetsResponse>(
              ['bookingTrackerColumnsPresets'],
              (oldPresetsData) =>
                isPresent(oldPresetsData)
                  ? {
                      columnsPresets: uniqBy(
                        [savedPreset, ...oldPresetsData.columnsPresets],
                        'name',
                      ),
                    }
                  : { columnsPresets: [savedPreset] },
            );
            setSelectedPreset({
              value: savedPreset.name,
              label: savedPreset.name,
            });
          }}
          onCancel={() => setIsSavePresetModalOpen(false)}
          onClose={() => setIsSavePresetModalOpen(false)}
        />
      </Stack>
    </Stack>
  );
};

export default Tracker;
