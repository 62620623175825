import {
  Alert,
  Button,
  H1,
  Inline,
  Link,
  PillTab,
  PillTabList,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
} from '@rea-group/construct-kit-core';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker/src/types';
import { isPresent } from '../utils/helpers';
import RangeDatePickerWithPresets from '../components/DatePicker/RangeDatePickerWithPresets';
import ErrorAlert from '../components/ErrorAlert';
import { Job, JobsTable } from '../components/JobsTable';
import { BookingsTable } from '../components/bookings/BookingsTable';
import { Booking } from '../components/bookings/types';
import styled from 'styled-components';
import { fetchJson, putVoid } from '../API/fetch';
import { useQueryClient, useMutation, useQueries } from '@tanstack/react-query';
import { formatISO } from 'date-fns';

const ScrollableTable = styled.div`
  overflow-x: scroll;
`;

const getBooking = async (
  isValid: boolean = false,
  startDate?: string,
  endDate?: string,
): Promise<Booking[]> => {
  const urlParams = new URLSearchParams();
  urlParams.append('isValid', String(isValid));

  if (isPresent(startDate)) {
    urlParams.append('startDate', startDate);
  }
  if (isPresent(endDate)) {
    urlParams.append('endDate', endDate);
  }

  const queryStr = `${urlParams.toString()}`;
  return await fetchJson<Booking[]>(`/bookings?${queryStr}`);
};

interface JobsResponse {
  jobs: Job[];
}

const getJobs = async (
  startDate?: string,
  endDate?: string,
  status?: string,
): Promise<Job[]> => {
  const urlParams = new URLSearchParams('bookingSource=hydro');
  if (isPresent(status)) {
    urlParams.append('status', status);
  }
  if (isPresent(startDate)) {
    urlParams.append('startDate', startDate);
  }
  if (isPresent(endDate)) {
    urlParams.append('endDate', endDate);
  }

  const queryStr = `${urlParams.toString()}`;
  const response = await fetchJson<JobsResponse>(`/jobs?${queryStr}`);
  return response.jobs;
};

const JobSummary = (): React.JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const queryOptions = [
    {
      queryKey: ['allPausedJobs'],
      queryFn: () => getJobs(undefined, undefined, 'Paused'),
    },
    {
      queryKey: ['jobs'],
      queryFn: () =>
        getJobs(
          dateRange.from
            ? formatISO(dateRange.from, { representation: 'date' })
            : undefined,
          dateRange.to
            ? formatISO(dateRange.to, { representation: 'date' })
            : undefined,
        ),
    },
    {
      queryKey: ['getInvalidBookings'],
      queryFn: () =>
        getBooking(
          false,
          dateRange.from
            ? formatISO(dateRange.from, { representation: 'date' })
            : undefined,
          dateRange.to
            ? formatISO(dateRange.to, { representation: 'date' })
            : undefined,
        ),
      initialData: [],
    },
  ];

  const [allPausedJobs, jobs, bookings] = useQueries({ queries: queryOptions });

  const bookingData = bookings.data as Booking[];
  const pausedJobs = allPausedJobs.data as Job[];
  const jobsData = jobs.data as Job[];

  const queryClient = useQueryClient();
  const invalidatePausedJobsQuery = useMutation({
    mutationFn: () => putVoid('/jobs/resume'),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['jobs'] });
      await queryClient.invalidateQueries({ queryKey: ['allPausedJobs'] });
    },
  });

  useEffect(() => {
    bookings.refetch().catch((error) => {
      console.error('Error refetching bookings:', error);
    });
    jobs.refetch().catch((error) => {
      console.error('Error refetching jobs:', error);
    });
  }, [dateRange, bookings, jobs]);

  return (
    <Stack gap="threeExtraLarge">
      <H1>Job Summary</H1>
      <Tabs defaultSelectedTab={0}>
        <Inline gap="extraSmall" alignItems="start">
          <div style={{ width: '200px' }}>
            <PillTabList scrollInset="none">
              <PillTab>Bookings</PillTab>
              <PillTab>Jobs</PillTab>
            </PillTabList>
          </div>
          <div style={{ width: '200px' }}>
            <Inline gap="extraSmall" alignItems="end">
              <RangeDatePickerWithPresets
                placeHolders={{ from: 'Start date', to: 'End date' }}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
              <Button
                variant="outline"
                sizeVariant="medium"
                disabled={
                  dateRange.from === undefined && dateRange.to === undefined
                }
                onClick={() => {
                  setDateRange({ from: undefined, to: undefined });
                }}
              >
                Clear
              </Button>
            </Inline>
          </div>
        </Inline>
        <TabPanels>
          <TabPanel>
            {bookings.isLoading ? (
              <PageLoadingSpinner />
            ) : (
              <Stack gap="medium">
                {bookings.isError && <ErrorAlert error={bookings.error} />}
                <ScrollableTable>
                  <BookingsTable bookings={bookingData} />
                </ScrollableTable>
              </Stack>
            )}
          </TabPanel>
          <TabPanel>
            {jobs.isLoading ? (
              <PageLoadingSpinner />
            ) : (
              <Stack gap="medium">
                {allPausedJobs.isError && (
                  <ErrorAlert error={allPausedJobs.error} />
                )}
                {pausedJobs && (
                  <Inline justifyContent="flex-end" grow={false}>
                    <Alert>
                      There are currently {pausedJobs.length} paused jobs
                      {pausedJobs.length > 0 && (
                        <>
                          , to add them back to the queue for reprocessing,{' '}
                          <Link
                            onClick={() => {
                              invalidatePausedJobsQuery.mutate();
                            }}
                          >
                            click here
                          </Link>
                        </>
                      )}
                    </Alert>
                  </Inline>
                )}
                {jobs.isError && <ErrorAlert error={jobs.error} />}
                {jobs && (
                  <ScrollableTable>
                    <JobsTable jobs={jobsData} showActions={true} />
                  </ScrollableTable>
                )}
              </Stack>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default JobSummary;
