import { Button, Checkbox, Link, Stack } from '@rea-group/construct-kit-core';
import ImagesLink, { ImagesJobLink, JobError } from './ImagesLink';
import Status from './Status';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  EmptyTableBody,
  TableRow,
  TableContentColumn,
} from './Table/Table';
import { ReactNode, useState } from 'react';
import ExternalLink from './ExternalLink';
import { isDspEnabled, isPresent } from '../utils/helpers';
import ErrorLinkMessage from './ErrorLinkMessage';
import { ResumeButton } from './ResumeButton';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { putVoid } from '../API/fetch';
import { formatDate, formatDateTime } from '../utils/formatDate';
import AlertTooltip from './AlertTooltip';

interface ValidationWarnings {
  warnings: string[];
}

export interface Job {
  id: number;
  bookingId: string;
  listingId: number;
  status: string;
  imagesLink: ImagesJobLink;
  facebookLink: JobLink;
  yahooLink: JobLink;
  yahooNativeLink: JobLink;
  adformLink: JobLink;
  tradedeskLink: JobLink;
  createdAt: string;
  startDate?: string;
  processDate?: string;
  warnings?: ValidationWarnings | null;
  areWarningsResolved?: boolean | null;
}

type JobLink = string | JobError | null;

interface JobLinkProps {
  link: JobLink;
  children: ReactNode;
}

export const JobLink = ({
  link,
  children,
}: JobLinkProps): React.JSX.Element | null => {
  if (!isPresent(link)) {
    return null;
  } else if (typeof link === 'string') {
    return <ExternalLink href={link}>{children}</ExternalLink>;
  }

  return <ErrorLinkMessage messageText={link.message} />;
};

interface JobsTableProps {
  jobs: Job[];
  showActions?: boolean;
  showWarnings?: boolean;
  showStartDate?: boolean;
  showProcessDate?: boolean;
  onResolveButtonClick?: (bookingId: string) => void;
}

export const JobsTable = ({
  jobs,
  showActions,
  showWarnings,
  showStartDate,
  showProcessDate = false,
  onResolveButtonClick,
}: JobsTableProps): React.JSX.Element => {
  const [resumingJobs, setResumingJobs] = useState<number[]>([]);
  const [showWarningsOnly, setShowWarningsOnly] = useState(false);

  const resumeJob = async (jobId: number): Promise<void> => {
    setResumingJobs((id) => [...id, jobId]);
    await putVoid(`/jobs/resume/${jobId}`);
    setResumingJobs((prev) => prev.filter((id) => id !== jobId));
  };

  const queryClient = useQueryClient();
  const resumeJobMutation = useMutation({
    mutationFn: (jobId: number) => resumeJob(jobId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['jobs'] });
      await queryClient.invalidateQueries({ queryKey: ['allPausedJobs'] });
    },
  });

  const filteredJobs = showWarningsOnly
    ? jobs.filter(
        (job) => isPresent(job.warnings) && job.warnings.warnings.length > 0,
      )
    : jobs;

  return (
    <Stack gap="medium">
      {showWarnings && (
        <Checkbox
          label="Show warnings only"
          checked={showWarningsOnly}
          onChange={() => {
            setShowWarningsOnly(!showWarningsOnly);
          }}
        />
      )}
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Status</TableHeaderColumn>
          <TableHeaderColumn>Images</TableHeaderColumn>
          <TableHeaderColumn>Facebook</TableHeaderColumn>
          {isDspEnabled('yahoo') && (
            <TableHeaderColumn>Yahoo Display</TableHeaderColumn>
          )}
          {isDspEnabled('taboola') && (
            <TableHeaderColumn>Yahoo Native</TableHeaderColumn>
          )}
          <TableHeaderColumn>Adform</TableHeaderColumn>
          <TableHeaderColumn>Tradedesk</TableHeaderColumn>
          <TableHeaderColumn>Created At</TableHeaderColumn>
          {showStartDate && <TableHeaderColumn>Start Date</TableHeaderColumn>}
          {showProcessDate && (
            <TableHeaderColumn>Process Date</TableHeaderColumn>
          )}
          {showActions && <TableHeaderColumn>Actions</TableHeaderColumn>}
          {showWarnings && (
            <>
              <TableHeaderColumn>Warnings</TableHeaderColumn>
              <TableHeaderColumn />
            </>
          )}
        </TableHeader>
        <TableBody>
          {filteredJobs.length === 0 ? (
            <EmptyTableBody />
          ) : (
            filteredJobs.map(
              ({
                id,
                bookingId,
                listingId,
                status,
                imagesLink,
                facebookLink,
                yahooLink,
                yahooNativeLink,
                adformLink,
                tradedeskLink,
                createdAt,
                startDate,
                processDate,
                warnings,
                areWarningsResolved,
              }) => (
                <TableRow key={id}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>
                    <Status status={status} />
                  </TableContentColumn>
                  <TableContentColumn>
                    <ImagesLink link={imagesLink} listingId={listingId}>
                      Images link
                    </ImagesLink>
                  </TableContentColumn>
                  <TableContentColumn>
                    {facebookLink === 'N/A' ? (
                      facebookLink
                    ) : (
                      <JobLink link={facebookLink}>Facebook link</JobLink>
                    )}
                  </TableContentColumn>
                  {isDspEnabled('yahoo') && (
                    <TableContentColumn>
                      {yahooLink === 'N/A' ? (
                        yahooLink
                      ) : (
                        <JobLink link={yahooLink}>Yahoo Display link</JobLink>
                      )}
                    </TableContentColumn>
                  )}
                  {isDspEnabled('taboola') && (
                    <TableContentColumn>
                      {yahooNativeLink === 'N/A' ? (
                        yahooNativeLink
                      ) : (
                        <JobLink link={yahooNativeLink}>
                          Yahoo Native link
                        </JobLink>
                      )}
                    </TableContentColumn>
                  )}
                  <TableContentColumn>
                    {adformLink === 'N/A' ? (
                      adformLink
                    ) : (
                      <JobLink link={adformLink}>Adform link</JobLink>
                    )}
                  </TableContentColumn>
                  <TableContentColumn>
                    {tradedeskLink === 'N/A' ? (
                      tradedeskLink
                    ) : (
                      <JobLink link={tradedeskLink}>Tradedesk link</JobLink>
                    )}
                  </TableContentColumn>
                  <TableContentColumn>
                    {formatDateTime(createdAt)}
                  </TableContentColumn>
                  {showStartDate && (
                    <TableContentColumn>
                      {isPresent(startDate)
                        ? formatDate(new Date(startDate).toISOString())
                        : ''}
                    </TableContentColumn>
                  )}
                  {showProcessDate && (
                    <TableContentColumn>
                      {isPresent(processDate)
                        ? formatDateTime(processDate)
                        : ''}
                    </TableContentColumn>
                  )}
                  {showActions && (
                    <TableContentColumn>
                      {status.toLowerCase() === 'paused' && (
                        <ResumeButton
                          variant="secondary"
                          onClick={() => {
                            resumeJobMutation.mutate(id);
                          }}
                          isSpinning={resumingJobs.includes(id)}
                        >
                          Resume
                        </ResumeButton>
                      )}
                    </TableContentColumn>
                  )}
                  {showWarnings && (
                    <>
                      <TableContentColumn>
                        {isPresent(warnings?.warnings) &&
                          warnings.warnings.length > 0 && (
                            <AlertTooltip
                              title={
                                areWarningsResolved ? 'Resolved' : 'Warnings'
                              }
                              messages={warnings.warnings}
                              type={areWarningsResolved ? 'success' : 'warning'}
                            />
                          )}
                      </TableContentColumn>
                      <TableContentColumn>
                        {isPresent(warnings?.warnings) &&
                          warnings.warnings.length > 0 && (
                            <Button
                              variant="link-primary"
                              disabled={!!areWarningsResolved}
                              onClick={() =>
                                isPresent(onResolveButtonClick) &&
                                onResolveButtonClick(bookingId)
                              }
                            >
                              Resolve
                            </Button>
                          )}
                      </TableContentColumn>
                    </>
                  )}
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </Stack>
  );
};
