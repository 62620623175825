import Status from '../Status';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableContentColumn,
  EmptyTableBody,
} from '../Table/Table';
import Panel from './Panel';
import { H4, Inline, Stack } from '@rea-group/construct-kit-core';
import { formatDateTime } from '../../utils/formatDate';
import { isPresent } from '../../utils/helpers';

export interface DeletedTasksData {
  key: number;
  status: string | null;
  lastUpdated: string | null;
}

export interface DeletedTasksPanelProps {
  deletedFinishedTasksData: DeletedTasksData[];
  deletedInvalidTasksData: DeletedTasksData[];
}

const CampaignTable = ({
  heading,
  data,
}: {
  heading: string;
  data: DeletedTasksData[];
}): React.JSX.Element => {
  return (
    <Stack gap="medium">
      <H4>{heading}</H4>
      <Table>
        <TableHeader>
          <TableHeaderColumn>Status</TableHeaderColumn>
          <TableHeaderColumn>Last updated</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {data.length === 0 ? (
            <EmptyTableBody />
          ) : (
            data.map(({ key, status, lastUpdated }) => (
              <TableRow key={key}>
                <TableContentColumn>
                  {isPresent(status) && <Status status={status} />}
                </TableContentColumn>
                <TableContentColumn>
                  {formatDateTime(lastUpdated)}
                </TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Stack>
  );
};

const DeletedTasksPanel = ({
  deletedFinishedTasksData,
  deletedInvalidTasksData,
}: DeletedTasksPanelProps): React.JSX.Element => {
  return (
    <Panel heading="Deleted tasks for today">
      <Inline gap="large">
        <CampaignTable
          heading="Finished Campaigns"
          data={deletedFinishedTasksData}
        />
        <CampaignTable
          heading="Invalid Campaigns"
          data={deletedInvalidTasksData}
        />
      </Inline>
    </Panel>
  );
};

export default DeletedTasksPanel;
