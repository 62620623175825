import { H1, Stack } from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import Panel from '../components/HomePage/Panel';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../components/Table/Table';
import { PageLoadingSpinner } from '../components/LoadingSpinner';

interface FacebookAdCount {
  accountId: string;
  accountName: string;
  adCount: number;
}

interface FacebookAdCountsData {
  developerAccountsTotalAdCount: number;
  developerAccounts: FacebookAdCount[];
  resiAccountsTotalAdCount: number;
  resiAccounts: FacebookAdCount[];
}

interface FaceBookAccountCountsPanelProps {
  accountType: string;
  accountTotalAdCount: string;
  accounts: FacebookAdCount[];
}

const FacebookAccountCountsPanel = ({
  accountType,
  accountTotalAdCount,
  accounts,
}: FaceBookAccountCountsPanelProps): React.JSX.Element => {
  return (
    <Panel
      heading={accountType}
      subHeading={`Total ad count is: ${accountTotalAdCount}`}
    >
      <Table>
        <TableHeader>
          <TableHeaderColumn>Account ID</TableHeaderColumn>
          <TableHeaderColumn>Ad Count</TableHeaderColumn>
          <TableHeaderColumn>Account Name</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {accounts.length === 0 ? (
            <EmptyTableBody />
          ) : (
            accounts.map(({ accountId, adCount, accountName }) => (
              <TableRow key={accountId}>
                <TableContentColumn>{accountId}</TableContentColumn>
                <TableContentColumn>
                  {adCount.toLocaleString()}
                </TableContentColumn>
                <TableContentColumn>{accountName}</TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Panel>
  );
};

export const getFacebookAdCountsData =
  async (): Promise<FacebookAdCountsData> =>
    fetchJson<FacebookAdCountsData>('/facebook-ad-counts');

const FBAdCount = (): React.JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: facebookAdCountsData,
  } = useQuery({
    queryKey: ['facebookAdCountsData'],
    queryFn: getFacebookAdCountsData,
  });

  return (
    <Stack gap="medium">
      <H1>Facebook Ad Count</H1>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {facebookAdCountsData && (
        <>
          <FacebookAccountCountsPanel
            accountType="Developer"
            accountTotalAdCount={facebookAdCountsData.developerAccountsTotalAdCount?.toLocaleString()}
            accounts={facebookAdCountsData.developerAccounts}
          />
          <FacebookAccountCountsPanel
            accountType="RESI"
            accountTotalAdCount={facebookAdCountsData.resiAccountsTotalAdCount?.toLocaleString()}
            accounts={facebookAdCountsData.resiAccounts}
          />
        </>
      )}
    </Stack>
  );
};

export default FBAdCount;
