import { formatAustralianDate } from '../../utils/formatDate';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface MediamathReportingCampaignData {
  id: number;
  campaignId: number;
  campaignName: string;
  reportDate: string;
  impressions: number | null;
  clicks: number | null;
  totalSpend: number | null;
  budget: number | null;
}

export interface BookingDataMmDailyCampaignReportingPanelProps {
  data: MediamathReportingCampaignData[];
}

const BookingDataMmDailyCampaignReportingPanel = ({
  data,
}: BookingDataMmDailyCampaignReportingPanelProps): React.JSX.Element => (
  <BookingDataPanel heading="MM Daily Campaign Reporting">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Campaign ID</TableHeaderColumn>
        <TableHeaderColumn>Campaign name</TableHeaderColumn>
        <TableHeaderColumn>Report date</TableHeaderColumn>
        <TableHeaderColumn>Impressions</TableHeaderColumn>
        <TableHeaderColumn>Clicks</TableHeaderColumn>
        <TableHeaderColumn>Total spend</TableHeaderColumn>
        <TableHeaderColumn>Budget</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({
              id,
              campaignId,
              campaignName,
              reportDate,
              impressions,
              clicks,
              totalSpend,
              budget,
            }) => (
              <TableRow key={id}>
                <TableContentColumn>{campaignId}</TableContentColumn>
                <TableContentColumn>{campaignName}</TableContentColumn>
                <TableContentColumn>
                  {formatAustralianDate(reportDate)}
                </TableContentColumn>
                <TableContentColumn>{impressions}</TableContentColumn>
                <TableContentColumn>{clicks}</TableContentColumn>
                <TableContentColumn>{totalSpend}</TableContentColumn>
                <TableContentColumn>{budget}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataMmDailyCampaignReportingPanel;
